import React from "react";

import InfoStack from "_components/molecules/containers/common/InfoStack";
import { HoverSpan, LinkSpan } from "_components/atoms/inline/SpanElements";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

const KeyInformation = () => {
  return (
    <InfoStack $title="Key Information" $col="var(--contrast-one)">
      <div>
        <h3>Game Selecting</h3>
        <p>
          When you select a game, you are choosing to gain the benefits from
          that game, for completely free and with minimal effort. To learn more
          about the benefits received, see '
          <LinkSpan $col="var(--contrast-one)" to="/benefits/game">
            Game Benefits
          </LinkSpan>
          '.
        </p>
        <p>
          Selecting a game is instant and immediately adds you the list of
          youtubers involved in the game. To see when exactly those benefits are
          actually handed out, see '
          <LinkSpan $col="var(--contrast-one)" to="/benefits/game">
            Game Benefits
          </LinkSpan>
          '.
        </p>
      </div>
      <div>
        <h3>Game Unselecting</h3>
        <p>
          Unselecting a game doesn't happen instantly, and is only finalized
          after 30days from your last
          <HoverSpan $col="var(--contrast-one)">selection renewal</HoverSpan> .
          You can cancel the de-selection before the 30days are up, keeping you
          involved in the game for the next month and so on.
        </p>
      </div>
      <div>
        <h3>Multi-Game Selection</h3>
        <p>
          You can select multiple games to be involved in at once, but the
          amount of games depends on your personal{" "}
          <StyledHighlightedSpan $col="var(--contrast-one)">
            game selection cap
          </StyledHighlightedSpan>
          .
        </p>
        <p>
          Your{" "}
          <StyledHighlightedSpan $col="var(--contrast-one)">
            game selection cap
          </StyledHighlightedSpan>{" "}
          is calculated based on your subscriber count on your YouTube channel.
          The higher subscriber count you have the more games you can be
          involved in at once.
        </p>
      </div>
    </InfoStack>
  );
};

export default KeyInformation;

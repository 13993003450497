import { ReactSession } from "react-client-session";

const login = (params) => {
  ReactSession.set("iseditor", params.get("iseditor"));
  ReactSession.set("isartist", params.get("isartist"));
  ReactSession.set("edit_speed", params.get("edit_speed"));
  ReactSession.set("thumbnail_speed", params.get("thumbnail_speed"));
};

const logout = async () => {
  ReactSession.remove("iseditor");
  ReactSession.remove("isartist");
  ReactSession.remove("edit_speed");
  ReactSession.remove("thumbnail_speed");
};

export default {
  login,
  logout,
};

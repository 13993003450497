import React, { useEffect, useState } from "react";
import axios from "axios";

import TabbedScroller, {
  StyledTabbedScroller,
} from "components/objects/common/TabbedScroller";
import GameSelectOption from "components/objects/game/GameSelectOption";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledSelectedGamesIndicator } from "components/styles/objects/coupled/GameSelectionElements.styled";
import { StyledContentDisplay } from "components/styles/objects/containers/ScrollContainer.styled";

import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";

import { GalleryContext } from "components/contexts/GalleryContext";

import gameDatas from "js/data/gameDatas";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const SelectedGamesIndicator = ({ numSelected, numTotal }) => {
  return (
    <StyledSelectedGamesIndicator>
      Games Selected:{" "}
      <span
        style={{
          color: "var(--action-primary)",
          fontWeight: "bold",
        }}
      >
        {numSelected}/{numTotal}
      </span>
    </StyledSelectedGamesIndicator>
  );
};

const GameSelectionSystem = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedIndicatorData, setSelectedIndicatorData] = useState([]);

  const loadGameDatas = async () => {
    axios
      .get(`${ENDPOINT}/api/game/list-user`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const { selectedGames, numSelectable } = res.data;
        setSelectedIndicatorData([selectedGames.length, numSelectable]);
        setSelectedGames(selectedGames);
      })
      .catch(console.error);
  };

  useEffect(() => {
    loadGameDatas();
  }, [activeCardIndex]);

  return (
    <StyledDualXStrip>
      {/* Can't use drop shadow as causes overlay to be trapped within it */}
      <StyledSbs
        $gap="20px"
        $align="center"
        $justify="start"
        $centered="1000px"
        $column
      >
        <StyledSectionH2>Game Selection</StyledSectionH2>
        <StyledTabbedScroller $height="auto">
          <SelectedGamesIndicator
            numSelected={selectedIndicatorData[0]}
            numTotal={selectedIndicatorData[1]}
          />
          <TabbedScroller $tab1="Unselected" $tab2="Selected">
            <StyledContentDisplay $align="center">
              {gameDatas.map((data, index) => {
                if (selectedGames.find((id) => id == data.id)) {
                  return;
                }
                // Import status from user datastore
                return (
                  <GalleryContext.Provider key={index} value={data.preview}>
                    <GameSelectOption
                      data={data}
                      index={index}
                      activeCardIndex={activeCardIndex}
                      setActiveCardIndex={setActiveCardIndex}
                      status="Unselected"
                    />
                  </GalleryContext.Provider>
                );
              })}
            </StyledContentDisplay>
            <StyledContentDisplay $align="center">
              {gameDatas.map((data, index) => {
                if (!selectedGames.find((id) => id == data.id)) {
                  return;
                }
                // Import status from user datastore
                return (
                  <GalleryContext.Provider key={index} value={data.preview}>
                    <GameSelectOption
                      data={data}
                      index={index}
                      activeCardIndex={activeCardIndex}
                      setActiveCardIndex={setActiveCardIndex}
                      status="Selected"
                    />
                  </GalleryContext.Provider>
                );
              })}
            </StyledContentDisplay>
          </TabbedScroller>
        </StyledTabbedScroller>
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default GameSelectionSystem;

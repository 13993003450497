import React from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import KeyInformation from "_components/features/docs/game/blocks/KeyInformation";
import FreeAdvertising from "_components/features/docs/game/blocks/FreeAdvertising";
import GameProfit from "_components/features/docs/game/blocks/GameProfit";
import Faq from "_components/features/docs/game/blocks/Faq";

const GameBenefits = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Game Benefits</h1>
        <p>Everything about the benefits you get from our games</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <KeyInformation />

      <StyledStandardSpacer $paddingTop="400px" />

      <FreeAdvertising />
      <StyledStandardSpacer />
      <GameProfit />

      <Faq />
    </StyledStandardEndSection>
  );
};

export default GameBenefits;

import styled, { css } from "styled-components";

export const StyledRoadmapContainer = styled.div`
  padding: 0 5%;

  width: 100%;
  justify-content: center;
  position: relative;

  display: grid;
  gap: 100px;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
`;

export const StyledRoadmapBlock = styled.div`
  border: solid 1px var(--contrast-one);
  border-radius: ${(props) => (props.$infoActive ? "10px 0 0 10px" : "10px")};
  padding: 25px;

  width: 100%;
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.$info &&
    css`
      border-radius: 0 10px 10px 0;
      border: unset;
      background-color: var(--main-background);
    `}

  & > h3 {
    margin: 0;
    color: var(--contrast-one);
  }

  & > h2 {
    margin: 0;
    color: var(--contrast-one);
  }

  & > p {
    margin: 0;
    color: white;
  }

  ${(props) =>
    props.$disabled &&
    css`
      border: solid 1px var(--sub-info-alt);

      & > h3,
      & > h2 {
        color: var(--sub-info-alt);
      }

      &::after {
        content: ${(props) => `'${props.$disabled}'`};
        display: block;
        position: absolute;
        background-color: var(--main-secondary-alt);

        top: 0;
        right: 50%;
        transform: translate(50%, -70%);

        padding: 7px 25px;
        border-radius: 5px;
        text-align: center;
      }
    `}
`;

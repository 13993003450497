import { styled, css } from "styled-components";

export const StyledOverlay = styled.section`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-image: ${({ $from, $to }) =>
      `linear-gradient(to bottom, ${$from}, ${$to})`};
    z-index: 0;
    top: 0;
    left: 0;
    clip-path: ${(props) => props.$clipPath};
    opacity: ${(props) => props.$opacity};

    transform: ${(props) => `translateY(calc(-1 * ${props.$yOffset}))`};
    padding-bottom: ${(props) => props.$yOffset};
  }
`;

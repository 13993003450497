import { useState, useEffect } from "react";
import axios from "axios";
import { ReactSession } from "react-client-session";

import {
  StyledSelectP,
  StyledSelectFlexbox,
} from "_components/molecules/containers/overlays/styles/SelectScreen.styled";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";
import { LinkSpan } from "_components/atoms/inline/SpanElements";
import IconOpenViewBtn from "_components/atoms/buttons/IconOpenViewBtn";

import MessageBox from "_components/features/form/MessageBox";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const ManageRequestBtn = ({
  type,
  requestId,
  staffEmail,
  managementData = {},
}) => {
  const [requestData, setRequestData] = useState(managementData);

  const profession = type === "Edit" ? "video editor" : "thumbnail artist";
  const isData = requestData.staff != undefined;

  const screenInfo = {
    title: "Manage Video Status",
    description: (
      <StyledSelectFlexbox>
        <StyledSelectP $centered $width="450px">
          Your {profession} '
          <LinkSpan to={`/chat?user=${requestData.staff}`}>
            {requestData.staff}
          </LinkSpan>
          ' has requested{" "}
          <StyledHighlightedSpan>{requestData.extension}</StyledHighlightedSpan>{" "}
          more day{parseInt(requestData.extension) > 1 && "s"} to fulfill your{" "}
          <StyledHighlightedSpan>
            {type.slice(0, 1).toLowerCase() + type.slice(1)} request
          </StyledHighlightedSpan>
          .
        </StyledSelectP>
        <MessageBox
          $label={`Message from ${profession}: @${requestData.staff}`}
          value={requestData.reason}
          style={{ margin: "40px", width: "70%" }}
        />
      </StyledSelectFlexbox>
    ),
  };

  const noDataScreenInfo = {
    title: "Manage Video Status",
    description: (
      <StyledSelectFlexbox $column>
        <StyledSelectP $centered $width="450px">
          <StyledHighlightedSpan>Nothing to manage!</StyledHighlightedSpan>
        </StyledSelectP>
        <StyledSelectP $last $centered $width="450px">
          Any requests the editor makes regarding the video request will appear
          here for you to make final decisions in reply to.
        </StyledSelectP>
      </StyledSelectFlexbox>
    ),
  };

  const fetchRequestData = async () => {
    axios
      .get(`${ENDPOINT}/api/payment/fulfillment/extension?id=${requestId}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) =>
        setRequestData(res.data.state === "Pending" ? res.data : {})
      )
      .catch(console.error);
  };

  const onAccept = async () => {
    axios
      .patch(
        `${ENDPOINT}/api/payment/fulfillment/extension?id=${requestId}`,
        {
          state: "Accepted",
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then(() => {
        // Refresh data after state change
        fetchRequestData();
      })
      .catch(console.error);
  };

  const onDecline = async () => {
    axios
      .patch(
        `${ENDPOINT}/api/payment/fulfillment/extension?id=${requestId}`,
        {
          state: "Declined",
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then(() => {
        // Refresh data after state change
        fetchRequestData();
      })
      .catch(console.error);
  };

  return (
    <IconOpenViewBtn
      $width="large"
      $flagWidth="140px"
      $important={isData}
      info={isData ? screenInfo : noDataScreenInfo}
      enabled
    >
      <div className="hover-flag">Manage Status</div>
      <img src="/images/buttons/message-icon.png" />
      {/* Don't use fragments as it messes with 'children' prop */}
      {isData && (
        <ThinBtn
          onClick={onAccept}
          $primary="var(--action-primary)"
          name="button"
        >
          Accept
        </ThinBtn>
      )}
      {isData && (
        <ThinBtn
          onClick={onDecline}
          $primary="var(--main-primary)"
          name="button"
        >
          Decline
        </ThinBtn>
      )}
    </IconOpenViewBtn>
  );
};

export default ManageRequestBtn;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  StyledBookmarkDisplay,
  StyledBookmarkElement,
} from "_components/molecules/displays/styles/BookmarkDisplay.styled";
import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";

import fetchClientTransactions from "js/functions/fetchClientTransactions";

const UnusedContent = () => {
  const navigate = useNavigate();

  const [edits, setEdits] = useState("Loading..");
  const [thumbnails, setThumbnails] = useState("Loading..");

  useEffect(() => {
    fetchClientTransactions().then(({ edits, thumbnails }) => {
      setEdits(edits);
      setThumbnails(thumbnails);
    });
  }, []);

  return (
    <StyledBookmarkDisplay>
      <StyledBookmarkElement $hasInfoHover>
        <StyledInfoHover
          $inline="start"
          $length="x"
          $text="Click to find where to request these edits you're entitled to"
          onClick={() =>
            navigate("/file?tab_outgoing=0&form=true&form_default=edits")
          }
        />
        Unused Edits:{" "}
        <span style={{ color: "var(--action-primary)" }}>{edits}</span>
      </StyledBookmarkElement>
      <StyledBookmarkElement $hasInfoHover>
        <StyledInfoHover
          $inline="start"
          $length="x"
          $text="Click to find where to request these thumbnails you're entitled to"
          onClick={() =>
            navigate("/file?tab_outgoing=0&form=true&form_default=thumbnails")
          }
        />
        Unused Thumbnails:{" "}
        <span style={{ color: "var(--action-primary)" }}>{thumbnails}</span>
      </StyledBookmarkElement>
    </StyledBookmarkDisplay>
  );
};

export default UnusedContent;

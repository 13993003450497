import styled from "styled-components";

import { DashedUnderlineStyle } from "_components/atoms/inline/styles/Decorations.styled";

export const StyledGameSelectOption = styled.div`
  --padding: 25px;

  position: relative;

  padding: var(--padding);
  width: min(700px, 90%);
  background-color: var(--main-secondary);
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 10px;

  h2 {
    color: white;
    margin: 0;
    line-height: 1.3rem;
  }

  p {
    color: var(--sub-info-light);
    margin: 0;
    line-height: 1.3rem;
  }

  .info-stack {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 30px;
  }
`;

export const StyledSelectedGamesIndicator = styled.p`
  --offset: 30px;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  color: white;
  border-radius: 5px;
  margin: 40px 60px 0 0;

  &::after {
    ${DashedUnderlineStyle};
    --invis-col: #28283b;
    --main-col: white;
    opacity: 1;
    background-size: 8px 1px;
    bottom: -3px;
    width: calc(100% + var(--offset));
    left: calc(var(--offset) / -2);
  }
`;

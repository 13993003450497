import React, { useEffect, useState } from "react";
import axios from "axios";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import DirectionalBtn from "_components/atoms/buttons/DirectionalBtn";

import PagedData, {
  ArrowNavigation,
} from "_components/molecules/displays/PagedData";
import ManyTab from "_components/atoms/navigation/ManyTab";

import { StyledStaticIconBtn } from "_components/features/file/styles/Buttons.styled";
import PendingFile from "_components/features/file/download/PendingFile";

import getAccessToken from "js/features/auth/getAccessToken";

import { ENDPOINT } from "js/data/constants";

const resultsPerPage = 8;

const SelectBtn = ({ onClickHandler }) => {
  return (
    <StyledStaticIconBtn $flagWidth="100px" onClick={onClickHandler}>
      <div className="hover-flag">Inspect</div>
      <img src="/images/buttons/pass-off-icon.png" />
    </StyledStaticIconBtn>
  );
};

const VideoRequestElement = ({ onClickHandler, data }) => {
  // Wraps component to give it the onClickHandler we already have in this scope to save us passing it via PendingFile
  const Button = (props) => (
    <SelectBtn {...props} onClickHandler={() => onClickHandler(data)} />
  );
  return (
    <PendingFile
      data={data}
      Button={Button}
      style={{
        border: "2px dashed var(--sub-info-alt)",
      }}
    />
  );
};

const RequestLog = ({
  username,
  requestType,
  setSelectedRequest,
  goBackHandler,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [requests, setRequests] = useState([]);

  const loadData = async () => {
    async function fetchRequests(type) {
      const response = await axios.get(
        `${ENDPOINT}/api/payment/fulfillment/requests`,
        {
          params: {
            type: type,
            only: requestType,
            uid: username,
            info: "staff",
            page: pageIndex,
            count: resultsPerPage,
          },
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      );
      return response.data;
    }

    setRequests([]);
    setIsLoading(true);

    try {
      const nextRequests = await fetchRequests(
        currentTab === 0 ? "fulfilled" : "unfulfilled"
      );
      setRequests(nextRequests);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [pageIndex, currentTab, requestType]);

  useEffect(() => {
    setPageIndex(1);
  }, [requestType, currentTab]);

  return (
    <>
      <ManyTab
        panels={["Fulfilled", "Unfulfilled"]}
        currentTab={currentTab}
        setCurrentTab={(value) => {
          // Prevent tab change if data is already loading as otherwise wrong data could appear in tab
          if (isLoading) return;
          setCurrentTab(value);
        }}
      />
      <DirectionalBtn text="Return to Product" onClick={goBackHandler} />

      <StyledStandardSpacer $paddingTop="50px" />
      <PagedData
        $colWidth={"500px"}
        data={requests}
        isLoading={isLoading}
        onClickHandler={setSelectedRequest}
        Element={VideoRequestElement}
      />

      <StyledStandardSpacer $paddingTop="50px" />
      <ArrowNavigation
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        canIncrease={requests.length > 0}
      />
    </>
  );
};

export default RequestLog;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ReactSession } from "react-client-session";

import {
  StyledUploadSystem,
  StyledUploadOptions,
  StyledOtherOptionsContainer,
  StyledUploadContainer,
} from "_components/features/file/styles/FileStruct.styled";
import { StyledOtherOption } from "_components/features/file/styles/FileElements.styled";

import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";

import UploadMenu from "./UploadMenu";
import ProductionForm from "_components/features/form/main/ProductionForm";

const OtherOption = ({ children: description, setCurrentForm }) => {
  return (
    <StyledOtherOption>
      <div className="top-container">
        <img src="/images/file-upload-icon.png" />
        {/* <UploadBtn
          directory="sent/editing-assets"
          acceptTypes={"image/*, video/*"}
        /> */}
        <ThinBtn
          onClick={() => setCurrentForm("assets")}
          style={{ backgroundColor: "var(--action-primary)" }}
        >
          Continue
        </ThinBtn>
      </div>
      <p>{description}</p>
    </StyledOtherOption>
  );
};

const FileUpload = () => {
  const { search } = useLocation();
  const userId = ReactSession.get("userid");

  const [currentForm, setCurrentForm] = useState("");

  const formEnableObserver = () => {
    const queryParams = new URLSearchParams(search);
    const form = queryParams.get("form");
    if (form) {
      const formEnabled = form === "true" ? true : false;
      setCurrentForm(formEnabled ? "main" : "");
    }
  };

  useEffect(formEnableObserver, [search]);

  return (
    <StyledUploadSystem>
      {currentForm == "" && (
        <StyledUploadOptions>
          <StyledUploadContainer>
            <div>
              <img src="/images/file-upload-icon.png" />
              <p style={{ textAlign: "center" }}>
                Instruct your production team to produce a thumbnail or video
                edit for your Youtube Channel
              </p>
              <ThinBtn
                $primary="var(--action-primary)"
                onClick={() => setCurrentForm("main")}
              >
                Continue
              </ThinBtn>
            </div>
          </StyledUploadContainer>
          {/* //* Temporarily disabled until functionality is fully tested & fix */}
          {/* <StyledOtherOptionsContainer>
            <h2>Other Options</h2>
            <OtherOption setCurrentForm={setCurrentForm}>
              Upload editing assets to help your editing team edit in your style
            </OtherOption>
          </StyledOtherOptionsContainer> */}
        </StyledUploadOptions>
      )}

      {currentForm === "main" && (
        <ProductionForm setFormVisible={() => setCurrentForm("")} />
      )}
      {currentForm === "assets" && (
        <UploadMenu
          setVisible={() => setCurrentForm("")}
          title="Upload Editing Assets"
          message="Uploading the editing assets you've personally used to your editing team can help them match your style of editing."
          directory={`${userId}/sent/editing-assets`}
          acceptTypes="image/*, video/*"
        />
      )}
    </StyledUploadSystem>
  );
};

export default FileUpload;

import React, { useContext, useRef, useEffect } from "react";
import { styled } from "styled-components";

import { GalleryContext } from "../../contexts/GalleryContext";
import { QueryContext } from "../../contexts/QueryContext";

const tramItemsGap = 50;

const StyledTramScroller = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  overflow: hidden;

  // Helps center initial state of TramList. Compensates for 'gap'
  /* padding: 20% 0; */

  @media screen and (max-width: 1370px) {
    width: 100%;
    justify-content: start;
    flex-direction: row;
  }
`;

const StyledTramList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  gap: ${tramItemsGap + "px"};

  transition: 0.2s transform ease-in-out;

  @media screen and (max-width: 1370px) {
    flex-direction: row;
    height: 100%;
    width: auto;
  }
`;

const StyledTramListImg = styled.img`
  width: 100%;
  border-radius: 15px;
  transition: 0.2s filter ease-in-out, 0.2s opacity ease-in-out,
    0.2s transform ease-in-out;

  filter: ${(props) => (props.$active ? "brightness(1.2)" : "brightness(1)")};
  opacity: ${(props) => (props.$active ? 1 : 0.5)};
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    filter: brightness(1.2);
  }

  @media screen and (max-width: 1370px) {
    height: 100%;
    width: auto;
  }
`;

const StyledScrollerBtn = styled.button`
  width: 30%;
  background: none;
  border: none;
  z-index: 2;

  cursor: pointer;

  & > img {
    width: 100%;
    height: auto;

    transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: transform 0.05s linear;
  }

  &:hover > img {
    filter: hue-rotate(3200deg);
    transform: scale(1.05);
  }

  &:active > img {
    transform: scale(0.9);
    transition: transform 0.05s linear;
  }

  @media screen and (max-width: 1370px) {
    height: 100%;
    width: auto;

    rotate: -90deg;
  }
`;

const getTramChildren = (items, currentIndex, setCurrentIndex, imgRef) => {
  const imgs = [];
  for (let i = 0; i < items.length; i++) {
    let isActive = currentIndex === i;
    let refActive = i === 0;
    const itemInfo = items[i];

    imgs.push(
      <StyledTramListImg
        ref={refActive ? imgRef : null}
        $active={isActive}
        key={i}
        src={itemInfo.thumbnail}
        onClick={() => setCurrentIndex(i)}
      />
    );
  }
  return imgs;
};

export const TramScroller = ({ currentIndex, setCurrentIndex }) => {
  const { queryWrap } = useContext(QueryContext);
  const items = useContext(GalleryContext);
  const imgRef = useRef(null);
  const scrollRef = useRef(null);
  const children = getTramChildren(
    items,
    currentIndex,
    setCurrentIndex,
    imgRef
  );

  // Visual positioning logic
  const setTransform = (index) => {
    const axis = [];

    // Decide which direction to move based on screen size (vertical or horizontal)
    if (queryWrap.matches) {
      // axis[0] = `
      // calc(50% - calc(${(imgRef.current.width + tramItemsGap) * index}px + ${
      //   imgRef.current.width / 2
      // }px))
      // `;
      axis[0] = `
      calc(-${(imgRef.current.width + tramItemsGap) * index}px + ${
        imgRef.current.width / 2
      }px)
      `;
      axis[1] = "0px";
    } else {
      axis[0] = "0px";
      axis[1] = `
      calc(50% - calc(${(imgRef.current.height + tramItemsGap) * index}px + ${
        imgRef.current.height / 2
      }px))
      `;
    }

    scrollRef.current.style.transform = `translate(${axis[0]}, ${axis[1]})`;
  };

  useEffect(() => {
    // Give delay as it doesn't seem to calculate properly otherwise
    setTimeout(() => {
      switch (currentIndex) {
        // Top alignment takes priority in empty scroller
        case 0:
          if (queryWrap.matches) {
            // align-items is 'start' instead of 'center' here so use different method
            setTransform(0);
            break;
          }
          setTransform(currentIndex + 1);
          break;
        case items.length - 1:
          setTransform(currentIndex - 1);
          break;
        default:
          setTransform(currentIndex);
      }
    }, 100);
  }, [currentIndex]);

  return (
    <StyledTramScroller>
      <StyledTramList ref={scrollRef}>{children}</StyledTramList>
    </StyledTramScroller>
  );
};

export const ScrollerBtn = ({
  src,
  currentIndex,
  setCurrentIndex,
  increment,
  maxIndex,
}) => {
  return (
    <StyledScrollerBtn
      onClick={() =>
        setCurrentIndex(
          (((currentIndex + increment) % maxIndex) + maxIndex) % maxIndex
        )
      }
    >
      <img src={src} />
    </StyledScrollerBtn>
  );
};

import React, { useRef } from "react";

import { StyledFormSelect } from "../styles/InputElements.styled";

const FormSelect = ({ optionsList, setSelection, placeholder, ...props }) => {
  const formSelectRef = useRef(null);

  return (
    <StyledFormSelect
      ref={formSelectRef}
      onChange={() => setSelection && setSelection(formSelectRef.current.value)}
      {...props}
    >
      <option value="">{placeholder}</option>

      {optionsList.map((value, index) => (
        <option key={index} value={value}>
          {value}
        </option>
      ))}
    </StyledFormSelect>
  );
};

export default FormSelect;

import styled from "styled-components";

export const StyledBasicCard = styled.div`
  position: relative;
  width: min(600px, 90%);

  background-color: var(--main-background);
  border-radius: 10px;
  padding: 25px;
`;

import React from "react";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";

import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";

import {
  StyledProfileCard,
  StyledProfileItemsContainer,
} from "../styles/ProfileCardElements.styled";

import ProfileItem from "./ProfileItem";
import ProfileHeader from "./ProfileHeader";

import PartnerProfileItems from "./collections/PartnerProfileItems";
import AffiliateProfileItems from "./collections/AffiliateProfileItems";
import ProductionProfileItems from "./collections/ProductionProfileItems";

const ratingsNames = ["Standard", "Expert", "Elite"];

const ProfileCard = () => {
  const userAccountType = ReactSession.get("useraccounttype");
  const navigate = useNavigate();

  return (
    <StyledProfileCard>
      <ProfileHeader username={ReactSession.get("useruid")}></ProfileHeader>
      <StyledProfileItemsContainer>
        {/* <ProfileItem
          title="Rating"
          value={ratingsNames[parseInt(ReactSession.get("userrating"))]}
        >
          <StyledInfoHover
            $text="View Documentation for more info"
            onClick={() => navigate("/docs/terms-of-service?tab=rating")}
          />
        </ProfileItem> */}

        {userAccountType === "Partner" && <PartnerProfileItems />}
        {userAccountType === "Affiliate" && <AffiliateProfileItems />}
        {userAccountType === "Production" && <ProductionProfileItems />}
      </StyledProfileItemsContainer>
    </StyledProfileCard>
  );
};

export default ProfileCard;

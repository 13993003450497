import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  StyledContentDisplay,
  StyledScrollContainer,
} from "components/styles/objects/containers/ScrollContainer.styled";
import { StyledTabbedScroller } from "components/objects/common/TabbedScroller";

import { YourWorkItem } from "./elements/WorkItem";
import LoadingStatus from "_components/atoms/info/LoadingStatus";

import getAccessToken from "js/features/auth/getAccessToken";
import { ENDPOINT } from "js/data/constants";

const YourWork = () => {
  const [assignedWork, setAssignedWork] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/payment/fulfillment/work?type=assigned`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setAssignedWork(res.data.filter((data) => data.FulfillmentId == null));
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <StyledTabbedScroller
      style={{
        borderRadius: "10px 10px 0 0",
      }}
    >
      <StyledScrollContainer>
        <StyledContentDisplay $align="center">
          {/* TODO: Filter loaded data */}
          {assignedWork.map((data) => (
            <YourWorkItem data={data} />
          ))}
          <LoadingStatus status={isLoading ? 0 : assignedWork.length > 0} />
        </StyledContentDisplay>
      </StyledScrollContainer>
    </StyledTabbedScroller>
  );
};

export default YourWork;

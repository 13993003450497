import React from "react";
import styled, { keyframes, css } from "styled-components";
import { NavLink as Link, useNavigate } from "react-router-dom";

const flowGradientAnim = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 300% 0;
  }
`;

const glimmerAnim = keyframes`
  0%, 100% {
    box-shadow: 0 0 0 0 #00b1ff;
  }

  50% {
    box-shadow: 0 0 10px 0 #00b1ff;
  }
`;

export const CtaStyle = css`
  --height: 55px;
  --width: 185px;

  display: block;
  position: relative;
  height: var(--height);
  width: var(--width);
  cursor: pointer;
  user-select: none;
  transition: transform 0.09s;
  text-align: center;

  // Remove styling from <Link>
  text-decoration: none;

  .txt {
    cursor: pointer;
    height: 100%;
    width: 100%;

    color: white;
    font-size: 1.1rem;
    font-weight: bold;

    display: flex;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
  }

  .box {
    cursor: pointer;
    display: block;
    position: absolute;
    border-radius: 25px;

    top: 0;
    height: 100%;
    width: 100%;

    border: solid 0px #00b1ff;
    transition: all 0.1s;

    overflow: hidden;
    /* animation: 2.5s ease-in-out infinite ${glimmerAnim}; */
  }

  .box::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-size: 300%;
    background-image: linear-gradient(to right, #00a2ff, #00ccff, #00a2ff);

    animation: 5s linear infinite ${flowGradientAnim};

    opacity: 1;
    transition: all 0.3s;
  }

  .box::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-size: 300%;
    background-color: white;

    transform: translateX(-110%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .box {
      animation: unset;
    }

    .box::after {
      transform: translateX(0);
      opacity: 1;
    }

    .txt {
      color: black;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  @media screen and (max-width: 700px) {
    height: calc(var(--height) * 0.9);
    width: calc(var(--width) * 0.9);

    .txt {
      font-size: 0.95rem;
    }
  }
`;

export const CtaElement = ({ children: text, ...props }) => {
  return (
    <Link {...props}>
      <div className="txt">{text}</div>
      <div className="box"></div>
    </Link>
  );
};

export const StyledCta = styled.div`
  ${CtaStyle};
`;

export const Cta = styled(CtaElement)`
  ${CtaStyle};
`;

export const StickyCta = styled(Cta)`
  top: 50px;
  right: 50px;
  position: fixed;
  z-index: 10;

  transition: 0.2s transform ease-in-out;

  transform: ${({ $isEnabled }) =>
    $isEnabled ? "translateY(0)" : "translateY(-300%)"};
`;

export const CtaAlt = styled(Cta)`
  .box {
    border: solid 2px #00b1ff;
    animation: unset;
  }

  .box::before {
    opacity: 0;
  }

  &:hover .box {
    border: solid 0px #00b1ff;
  }

  &:hover .box::before {
    opacity: 1;
  }
`;

export const SquareCta = styled(Cta)`
  height: calc(var(--height) - 15px);

  .txt {
    font-size: 1rem;
  }

  .box {
    border-radius: 5px;
    animation: unset;
  }

  .box::after {
    border-radius: 5px;
  }

  .box::before {
    border-radius: 5px;
    animation: ${(props) => !props.$animated && "unset"};
  }
`;

const StyledCtaInlineText = styled.p`
  color: var(--action-primary);
  position: relative;

  cursor: pointer;
  user-select: none;
  font-weight: bold;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(1.1);

    &::after {
      --x-offset: 30%;
    }
  }

  &::after {
    --x-offset: 0px;

    content: "";
    display: inline-block;
    position: relative;

    transform: translate(var(--x-offset), 15%) rotate(90deg);
    right: -0.7rem;

    width: 0.7rem;
    height: 0.7rem;
    background-color: var(--action-primary);

    clip-path: polygon(0 100%, 50% 0, 100% 100%, 90% 100%, 50% 50%, 10% 100%);

    transition: all 0.2s ease-in-out;
  }
`;

export const CtaInlineText = ({ to, children: text }) => {
  const navigate = useNavigate();

  return (
    <StyledCtaInlineText onClick={() => navigate(to)}>
      {text}
    </StyledCtaInlineText>
  );
};

import { styled, keyframes, css } from "styled-components";

const glowAnim = keyframes`
  0%, 80% {
    color: var(--first-color);
    filter: brightness(1);
  }

  30%, 50% {
    color: var(--second-color);
    filter: brightness(1.1);
  }
`;

const GlowStyle = css`
  --first-color: ${(props) => props.$firstColor};
  --second-color: ${(props) => props.$secondColor};

  color: ${(props) => props.$firstColor};
  animation: 4s ease-in-out infinite ${glowAnim};
`;

export const StyledGlowH1 = styled.h1`
  ${GlowStyle};
`;

export const StyledGlowP = styled.p`
  ${GlowStyle};
`;

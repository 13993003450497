import React, { useEffect, useState } from "react";
import axios from "axios";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import PagedData, {
  ArrowNavigation,
} from "_components/molecules/displays/PagedData";

import SearchBox from "_components/atoms/navigation/SearchBox";

import { centeredComponent } from "_components/function/modifiers/areaPositions";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const RESULTS_PER_PAGE = 9;

const CenteredSearchBox = centeredComponent(SearchBox);

const UserSelection = ({ setSelectedUser, blacklists, whitelists }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [pageIndex, setPageIndex] = useState(1);

  const [users, setUsers] = useState([]);

  const fetchByQuery = async (username) => {
    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/user/data`, {
        params: {
          method: "search",
          db: "users",
          column: "Username",
          value: username,
          page: pageIndex,
          count: RESULTS_PER_PAGE,
          blacklists: JSON.stringify(blacklists),
          whitelists: JSON.stringify(whitelists),
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setUsers(res.data.map((user) => user.Username));
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    fetchByQuery("");
  }, []);

  return (
    <>
      <CenteredSearchBox
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        template="username"
        searchHandler={fetchByQuery}
        isLive={true}
      />

      <StyledStandardSpacer $paddingTop="50px" />
      <PagedData
        data={users}
        isLoading={isLoading}
        onClickHandler={setSelectedUser}
      />

      <StyledStandardSpacer $paddingTop="50px" />
      <ArrowNavigation
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        canIncrease={users.length > 0}
      />
    </>
  );
};

export default UserSelection;

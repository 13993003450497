import { StyledDocSection } from "_components/features/docs/main/Documentation.styled";

const Partner = () => {
  return (
    <>
      <StyledDocSection>
        <p className="title">Refunds</p>
        <p>
          No refunds of money to your payment account are possible. Instead,
          upon termination/cancellation of video request, your spent
          edit/thumbnail will be refund to your account to be used again at any
          time.
        </p>
      </StyledDocSection>
    </>
  );
};

export default Partner;

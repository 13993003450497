import React from "react";
import styled from "styled-components";

import DirectionalBtn from "_components/atoms/buttons/DirectionalBtn";
import UserWithIcon from "_components/atoms/user/UserWithIcon";

import {
  StyledFlexbox,
  StyledPairDisplay,
} from "components/styles/SideBySide.styled";

const StyledDataOverview = styled.div`
  padding: 50px;
  border-radius: 10px;
  background-color: var(--main-background);

  .header {
    margin-bottom: 40px;

    & > h2 {
      color: white;
      margin: 0;
      margin-bottom: 20px;
    }
  }
`;

const DataOverview = ({ username, goBackHandler, data }) => {
  return (
    <div style={{ minHeight: "500px", width: "70%", maxWidth: "1000px" }}>
      <DirectionalBtn text="Return to search" onClick={goBackHandler} />
      <StyledDataOverview>
        <div className="header">
          <h2>Account Overview</h2>
          <UserWithIcon username={username} />
        </div>

        <StyledFlexbox $gap="50px">
          {data.map((item) => {
            return (
              <div>
                <h3>{item.heading}</h3>
                <div style={{ display: "flex", gap: "35px", flexWrap: "wrap" }}>
                  {item.entries.map((entryData) => (
                    <StyledPairDisplay
                      $primary="var(--sub-info-alt)"
                      $secondary="white"
                      $width="auto"
                    >
                      <p>{entryData.title}</p>
                      <p>{entryData.value}</p>
                    </StyledPairDisplay>
                  ))}
                </div>
              </div>
            );
          })}
        </StyledFlexbox>
      </StyledDataOverview>
    </div>
  );
};

export default DataOverview;

export const gamePreviews = {
  astral: [
    {
      original: "/content/games/astral/stairs-scene.jpg",
      thumbnail: "/content/games/astral/stairs-scene.jpg",
      type: "thumbnail",
    },
  ],

  listen: [
    {
      original: "/content/games/listen/duo.jpg",
      thumbnail: "/content/games/listen/duo.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/listen/soldiers-monster.jpg",
      thumbnail: "/content/games/listen/soldiers-monster.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/listen/gameplay-4.jpg",
      thumbnail: "/content/games/listen/gameplay-4.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/listen/fighting.jpg",
      thumbnail: "/content/games/listen/fighting.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/listen/duo-salute.jpg",
      thumbnail: "/content/games/listen/duo-salute.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/listen/gameplay-5.jpg",
      thumbnail: "/content/games/listen/gameplay-5.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/listen/gameplay-2.jpg",
      thumbnail: "/content/games/listen/gameplay-2.jpg",
      type: "thumbnail",
    },
  ],

  betterCallTim: [
    {
      original: "/content/games/betterCallTim/forest-scene.jpg",
      thumbnail: "/content/games/betterCallTim/forest-scene.jpg",
      type: "thumbnail",
    },
  ],

  labyrinth: [
    {
      original: "/content/games/labyrinth/boss-fight.jpg",
      thumbnail: "/content/games/labyrinth/boss-fight.jpg",
      type: "thumbnail",
    },
  ],

  barRumble: [
    {
      original: "/content/games/barRumble/3-chill.jpg",
      thumbnail: "/content/games/barRumble/3-chill.jpg",
      type: "thumbnail",
    },
    {
      original: "/content/games/barRumble/4-meme.jpg",
      thumbnail: "/content/games/barRumble/4-meme.jpg",
      type: "thumbnail",
    },
  ],

  insection: [
    {
      original: "/content/games/insection/game-wip.jpg",
      thumbnail: "/content/games/insection/game-wip.jpg",
      type: "thumbnail",
    },
  ],
};

const gameDatas = [
  {
    id: 1,
    game: "Astral",
    studio: "Red Wall Productions",
    preview: gamePreviews.astral,
    description:
      "You were targeted by evil spirits in your sleep, now your spirit has been pulled \
      into and trapped in another world with them, the 'Astral Plane'. With help from a friendly \
      spirit, you and your fellow trapped players must evade and beat 3 evil spirits while gathering \
      the resources to escape the realm.\n\nIf you fail to gather the resources in time or get caught, \
      the spirit will steal your physical body in the real world and you will be left in the Astral Plane permanently.",
    stats: {
      "Total Percentage Pool": "20%",
      "No. Game Developers": "4",
    },
    testimonials: {
      PUSHXPULL: [
        "CEO",
        `An idea by the greatest friend and business partner I knew. Who built this company with me. I
        will personally ensure this game sees the Roblox front-page in honour of him, who will never get to see it's
        glory.`,
      ],
    },
  },

  {
    id: 2,
    game: "Listen",
    studio: "Horror Corp",
    preview: gamePreviews.listen,
    description: `Enter different realms and hunt the monster of the land with your team. \
    Fight other sqauds and be the team to kill and capture the creature who rules \
    the realm. Be a group of assassins hunting a massive ground worm in a desert city. \
    \n\nBe a team of special ops soldiers hunting an unknown monster who lurks in the deep jungle.\n\nShould I say anymore?`,
    stats: {
      "Total Percentage Pool": "20%",
      "No. Game Developers": "4",
    },
    testimonials: {
      sp_3dy: [
        "Project Lead & Programmer",
        `This game was the beginning for PUSHXPULL. I brought it to the owner of PUSHXPULL's attention and
        that's when the company came to be what it is today, partnering Youtuber's with Roblox Games. This game
        will be something special and will do the company proud!`,
      ],
    },
  },

  {
    id: 3,
    game: "Better Call Tim",
    studio: "Freshly Crafted",
    preview: gamePreviews.betterCallTim,
    description:
      "Your name is Tim. You take the role of the greatest known detective to ever exist. \
      You are given jobs by people in need and you can take on these jobs with varying difficulties and different \
      requirements such as a requiring a partner or 2 to help you uncover the case and return the client with what they want.\
      \n\nTheir pets could be missing, they could have heard stranges noises from the forest near their home or maybe they \
      claim they saw a 10ft dark creature who doesn't appear in the light, only dark.",
    stats: {
      "Total Percentage Pool": "20%",
      "No. Game Developers": "4",
    },
    testimonials: {},
  },

  {
    id: 4,
    game: "Labyrinth",
    studio: "Stealth Studios",
    preview: gamePreviews.labyrinth,
    description: `Traverse through a massive dungeon collecting and discovering loot, fighting bosses & enemies, \
      completing quests, interacting with Npcs. Fight other players after declaring stakes, you beat them, \
      you get the items or xp they put up as a stake.\n\nYou and your friends can teamup and try to find your way \
      into the next level dungeon, but the dungeon constantly changes.\n\nDon't get lost in the 'Labyrinth'.`,
    stats: {
      "Total Percentage Pool": "20%",
      "No. Game Developers": "4",
    },
    testimonials: {},
  },

  {
    id: 5,
    game: "Bar Rumble",
    studio: "Irish Endeavors",
    preview: gamePreviews.barRumble,
    description:
      "Drink, level up, fight eachother, fight the bartendor, progress through pubs.",
    stats: {
      "Total Percentage Pool": "20%",
      "No. Game Developers": "4",
    },
    testimonials: {},
  },

  {
    id: 6,
    game: "Insection",
    studio: "Recursive Ideas",
    preview: gamePreviews.insection,
    description:
      "You take the role of a bug and fight your way through critter bosses located in different kingdoms and slums. \
      Collect the heads of your enemies and trade your winnings to create a kingdom of your own and lead a nation of insects \
      and animals to battle other player's kingdoms.",
    stats: {
      "Total Percentage Pool": "20%",
      "No. Game Developers": "4",
    },
    testimonials: {},
  },
];

export default gameDatas;

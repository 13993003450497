import styled from "styled-components";

export const StyledTestimonial = styled.div`
  position: relative;
  width: 350px;
  min-height: 300px;
  padding: 30px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  font-style: italic;
  overflow: hidden;

  background-color: var(--main-secondary);
  cursor: pointer;

  &::before {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    content: "Visit User Profile";
    font-weight: bold;
    color: white;

    top: 0;

    background-color: var(--main-primary);
    height: 25%;
    width: 100%;
    opacity: 0;
    position: absolute;
    border-radius: 10px 10px 0 0;
    transition: all 0.2s ease-in-out;
    z-index: 3;
  }

  &:hover::before {
    opacity: 1;
  }

  & > h2 {
    margin: 0;
    margin-top: 14px;
  }

  & > img {
    height: 70px;
  }

  .type {
    color: var(--main-primary);
    margin: 0;
  }

  & > p {
    color: white;
    margin: 0;
    line-height: 1.5rem;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

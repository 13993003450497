import React from "react";

import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";
import { StyledArrowIndentP } from "_components/atoms/inline/styles/Decorations.styled";
import { StyledXStripHeader } from "_components/themes/brand/XStrip.styled";

import FloatingInfoBlock from "_components/molecules/containers/common/FloatingInfoBlock";

const FreeAdvertising = () => {
  return (
    <>
      <StyledXStripHeader />

      <FloatingInfoBlock
        $width="min(80%, 850px)"
        $justify="start"
        $gap="20px"
        $col="var(--action-primary)"
      >
        <h3>
          <StyledHighlightedSpan>Benefit #1 : </StyledHighlightedSpan>Free
          Advertising
        </h3>
        <p>
          With the hundreds and thousands of players who will be playing or are
          already playing and following all of our games across all of our
          studios, we will, through creative marketing techniques, advertise you
          and your channel in-game and out-of-game. Directing hundreds to
          thousands of Robloxians to your Youtube channel constantly.
        </p>
        <div className="no-flex">
          <p>
            Our 'marketing techniques' which we constantly develop and create
            can be applied in-game and out-of-game. Here are some{" "}
            <StyledHighlightedSpan>examples</StyledHighlightedSpan>..
          </p>
          <p>
            <StyledHighlightedSpan>In-game Examples</StyledHighlightedSpan>
          </p>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            Player has to go to your channel and find a hidden code in one of
            your videos to use in-game for rewards.
          </StyledArrowIndentP>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            Custom skin, weapon, item, storyline, enemy, etc.. dedicated to you
            in-game.
          </StyledArrowIndentP>

          <p>
            <StyledHighlightedSpan>Out-of-game Examples</StyledHighlightedSpan>
          </p>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            Shout your channel out on our Game Studio's social media accounts or
            Roblox group.
          </StyledArrowIndentP>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            Displaying your channel in the description, thumbnail, logo of our
            game(s).
          </StyledArrowIndentP>

          <p>
            You don't need to know all of the marketing techniques, as they are
            always changing anyways. You just need to know that they work and
            they work well. With us, you have nothing to lose, this is a free
            gift from us after all. No strings attached.
          </p>
        </div>
      </FloatingInfoBlock>
    </>
  );
};

export default FreeAdvertising;

import { styled, keyframes, css } from "styled-components";

import { ReversedStyle } from "./css/ReversedStyle";

const hueShiftAnim = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 300% 0;
  }
`;

const InvertedStyle = css`
  background-image: linear-gradient(to right, #4700c2, #bf00ff, #4700c2);
  background-size: 300%;
  animation: 20s linear infinite ${hueShiftAnim};
`;

const VertedStyle = css`
  background-color: black;
`;

export const StyledBlocks = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  gap: ${(props) => props.$gap || "1%"};

  padding: ${(props) => props.$padding || "1%"};

  width: 100%;

  ${(props) => {
    if (props.$inverted) {
      return VertedStyle;
    } else {
      return InvertedStyle;
    }
  }};

  @media screen and (max-width: 1370px) {
    /* Inverted tend to be the opposite order which don't need 'reversing' for heading to be on top */
    ${(props) => !props.$inverted && ReversedStyle}

    flex-direction: column;
    gap: 15px;
  }
`;

export const StyledBlock = styled.div`
  display: block;
  position: relative;

  flex-grow: 1;
  height: ${(props) => (props.$short ? "600px" : "750px")};
  width: 100%;

  background-color: ${(props) => (props.$inverted ? "#4700c2" : "black")};
  ${(props) => {
    if (props.$inverted) {
      return InvertedStyle;
    } else {
      return VertedStyle;
    }
  }};

  max-width: ${(props) => props.$maxWidth};
  padding: ${(props) => props.$padding};

  @media screen and (max-width: 1370px) {
    max-width: 100%;
    height: auto;
  }
`;

export const StyledBlockImg = styled.img`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 800px;
  padding: ${(props) => props.$padding};
  object-fit: contain;
`;

import production from "./collections/productionSession";
import client from "./collections/clientSession";

const sessionConfig = {
  ["Partner"]: client,
  ["Affiliate"]: client,
  ["Production"]: production,
};

export default sessionConfig;

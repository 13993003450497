import React, { useContext } from "react";

import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledAspectRatioContainer,
  StyledAspectRatioWrapper,
} from "components/styles/AspectRatioContainer.styled";

import RotCircle from "components/objects/RotCircle";

import { QueryContext } from "components/contexts/QueryContext";
import { productionInfoList } from "js/data/arrays/rotCircleInfoLists";

const ProductionRotCircle = () => {
  const { queryWrap } = useContext(QueryContext);

  return (
    <StyledSbs>
      <StyledAspectRatioWrapper
        $centered
        $maxHeight="600px"
        $maxWidth="600px"
        $maintainChildPos
      >
        <StyledAspectRatioContainer
          $aspectRatio={queryWrap.matches ? "1:1" : null}
          // Usual radius of StyledRotCircle which we recreate with this parent container
          $height="450px"
          $width={queryWrap.matches ? "100%" : "450px"}
          $childScale={queryWrap.matches ? "80%" : "100%"}
          $childCentered
        >
          <RotCircle className="ratio-child" infoList={productionInfoList} />
        </StyledAspectRatioContainer>
      </StyledAspectRatioWrapper>
    </StyledSbs>
  );
};

export default ProductionRotCircle;

import styled, { css } from "styled-components";

export const StyledInfoHover = styled.div`
  --info-x-offset: var(--icon-size);
  --icon-size: 25px;

  position: absolute;
  z-index: 2;

  ${(props) =>
    props.$inline === "start"
      ? css`
          --info-x-offset: var(--icon-size);
          transform: ${(props) =>
            props.$translate ||
            `translate(calc(-100% - 5px), calc(50% - var(--icon-size) / 2))`};
          left: 0;
          top: 0;
          bottom: 0;

          &::before {
            transform: translate(0%, -50%);
          }
        `
      : css`
          --info-x-offset: calc(75% - 100% + var(--icon-size));
          transform: translate(100%, 0);
          right: 0;
          top: 0;
          bottom: 0;

          &::before {
            transform: translate(-75%, -25%);
          }
        `};

  ${(props) =>
    (props.$inline === "end" || props.$inline != undefined) &&
    css`
      --info-x-offset: var(--icon-size);
      &::before {
        transform: translate(0%, 0%);
      }
    `}

  &::before {
    content: "";
    cursor: ${(props) => (props.$noPointer ? "unset" : "pointer")};
    height: var(--icon-size);
    width: var(--icon-size);
    background-image: url("/images/buttons/info-icon.png");
    opacity: ${(props) => (props.$noPointer ? 0.65 : 1)};
    background-size: contain;
    display: block;
    position: relative;

    transition: 0.3s filter ease-in-out;
  }

  &::after {
    --info-translate: translate(
      calc(var(--info-x-offset) + 10px),
      calc(var(--icon-size) - 10px)
    );

    content: ${(props) => `"${props.$text}"`};
    transform: scale(0, 0) var(--info-translate);
    background-color: var(--sub-info-alt);
    display: block;
    position: absolute;

    border-radius: 5px;
    font-weight: 500;

    left: 0;
    top: 0;
    padding: 5px;
    height: auto;

    transition: all 0.2s ease-in-out;

    color: black !important;
    line-height: 1.05rem !important;
    transform-origin: calc(var(--icon-size) / 2) calc(var(--icon-size) / 2);

    width: ${(props) => (props.$length === "x" ? "200px" : "100px")};
    font-size: 0.8rem;
    opacity: 1;
  }

  &:hover::after {
    transform: scale(1, 1) var(--info-translate);
  }

  &:hover::before {
    filter: ${(props) => !props.$noPointer && "brightness(1.3)"};
  }
`;

import React from "react";
import { ReactSession } from "react-client-session";

import {
  NavLogo,
  StyledNav,
  StyledNavMenu,
} from "components/styles/NavbarElements.styled";
import { NavExpand } from "components/objects/navigation/NavbarElements";
import { NavProfile } from "_components/features/profile/NavProfile";

import {
  exploreContents,
  learnContents,
  getProfileContents,
} from "js/navContents";

const Navbar = () => {
  const userAccountType = ReactSession.get("useraccounttype");

  const profileContents = getProfileContents(userAccountType);

  return (
    <StyledNav>
      <NavLogo to="/">PUSHXPULL</NavLogo>
      <StyledNavMenu>
        <NavExpand originAt="20%" contents={exploreContents}>
          Explore
        </NavExpand>
        <NavExpand originAt="0" contents={learnContents}>
          Learn
        </NavExpand>
        <NavProfile originAt="-40%" contents={profileContents}>
          Login
        </NavProfile>
      </StyledNavMenu>
    </StyledNav>
  );
};

export default Navbar;

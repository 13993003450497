import React, { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { CenteredHeader } from "components/styles/common/Headings.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";

import UserSelection from "_components/organisms/UserSelection";
import { withTab } from "_components/atoms/navigation/ManyTab";

import RequestLog from "_components/features/admin/elements/RequestLog";
import ProductTracker from "_components/features/admin/elements/ProductTracker";

const RequestLogWithTab = withTab(
  RequestLog,
  ["video", "revision", "all"], // Keep lowercase to match what server expects in query
  "requestType",
  false
);

const ProductFulfillment = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);

  function getWhiteLists() {
    const partnerWhitelist = {
      column: "AccountType",
      values: ["'Partner'"],
    };
    const whitelists = [partnerWhitelist];
    return whitelists;
  }

  let currentView;

  if (selectedUser == "") {
    currentView = (
      <>
        <CenteredHeader title="Product">
          View and track all product information of video requests made per
          'Partner'
        </CenteredHeader>
        <UserSelection
          whitelists={getWhiteLists()}
          setSelectedUser={setSelectedUser}
        />
      </>
    );
  } else if (selectedRequest == null) {
    currentView = (
      <>
        <CenteredHeader title="Request Log">
          List of all requests user has made, containing the tracking
          information of each
        </CenteredHeader>
        <RequestLogWithTab
          username={selectedUser}
          setSelectedRequest={setSelectedRequest}
          goBackHandler={() => setSelectedUser("")}
        />
      </>
    );
  } else {
    currentView = (
      <ProductTracker
        data={selectedRequest}
        goBackHandler={() => setSelectedRequest(null)}
      />
    );
  }

  return (
    <>
      <StyledStandardSpacer $paddingTop="100px" />
      <StyledDualXStrip>
        <StyledStandardSpacer $paddingTop="50px" />
        <StyledSbs
          $align="center"
          $justify="start"
          $column
          // Helps make conditional rendering of children smoother when transitioning between their different heights
          style={{ minHeight: "700px" }}
        >
          {currentView}
        </StyledSbs>
      </StyledDualXStrip>

      <StyledStandardSpacer $paddingTop="200px" />
    </>
  );
};

export default ProductFulfillment;

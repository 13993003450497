import React, { useRef, useState, useContext, useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormP,
  StyledGroupWrapper,
} from "../styles/FormStructs.styled";

import FormCta from "../FormCta";
import FormSelect from "../elements/FormSelect";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import ActiveUploads from "_components/features/file/upload/ActiveUploads";

import { UploadContext } from "components/contexts/UploadContext";
import { FriendsContext } from "_components/function/contexts/FriendsContext";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { ENDPOINT } from "js/data/constants";
import getUploadID from "js/getUploadID";
import fetchUnfulfilledVideos, {
  getFormattedVideoNames,
} from "js/features/file/read/fetchUnfilfilledVideos";
import getAccessToken from "js/features/auth/getAccessToken";

const FulfillmentForm = () => {
  const friends = useContext(FriendsContext);
  const [
    [progressUris, setProgressUris],
    [fileDatas, setFileDatas],
    [completedUploads, setCompletedUploads],
  ] = useContext(UploadContext);
  const [canSendForm, setCanSendForm] = useState(true);

  const [unfulfilledVideoDatas, setUnfulfilledVideoDatas] = useState([]);
  const [unfulfilledVideoNames, setUnfulfilledVideoNames] = useState([]);
  const [clientUsernames, setClientUsernames] = useState([]);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [formattedSelectedVideo, setFormattedSelectedVideo] = useState("");

  const [acceptTypes, setAcceptTypes] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  const getSelectedVideoData = () => {
    return unfulfilledVideoDatas[unfulfilledVideoNames.indexOf(selectedVideo)];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const username = ReactSession.get("useruid");

    if (!canSendForm) {
      return;
    }

    const fileInfo = Object.values(fileDatas).map((file) => {
      let fileUri;
      for (const [uri, file_] of Object.entries(fileDatas)) {
        if (file_.name === file.name) {
          fileUri = uri;
        }
      }
      return {
        author: username,
        uri: getUploadID(fileUri),
      };
    });

    setCanSendForm(false);

    axios
      .post(
        `${ENDPOINT}/api/comm/send-fulfillment-form?uid=${selectedUser}`,
        {
          videoData: getSelectedVideoData(),
          file: fileInfo,
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then(() => {
        setUnfulfilledVideoDatas([]);
        setUnfulfilledVideoNames([]);
        setSelectedVideo("");

        setProgressUris([]);
        setFileDatas({});
        setCompletedUploads([]);

        setCanSendForm(true);
      })
      .catch((err) => {
        console.error("Failed to send fulfillment form.", err);
        setCanSendForm(true);
      });
  };

  const getClientUsernames = () => {
    const newClientUsernames = friends
      .filter((user) => user.accountType === "Partner")
      .map((user) => user.name);
    setClientUsernames(newClientUsernames);
  };

  const observeAcceptTypes = () => {
    const videoData = getSelectedVideoData();

    if (!videoData) {
      return;
    }

    if (selectedVideo) setFormattedSelectedVideo(videoData.title);

    const condition =
      videoData.type === "Edit"
        ? "video/*"
        : videoData.type === "Thumbnail"
        ? "image/*"
        : "";
    setAcceptTypes(condition);
  };

  useEffect(getClientUsernames, []);
  useEffect(() => {
    setUnfulfilledVideoDatas([]);
    setUnfulfilledVideoNames([]);
    setSelectedVideo("");

    setIsLoading(true);
    fetchUnfulfilledVideos(selectedUser).then(({ videoDatas }) => {
      setIsLoading(false);
      // Only list videos assigned to the staff user
      videoDatas = videoDatas.filter(
        (data) => data.staffName === ReactSession.get("useruid")
      );
      const videoNames = getFormattedVideoNames(videoDatas);
      setUnfulfilledVideoDatas(videoDatas);
      setUnfulfilledVideoNames(videoNames);
    });
  }, [selectedUser]);
  useEffect(observeAcceptTypes, [unfulfilledVideoDatas, selectedVideo]);

  const videoType = getSelectedVideoData()?.type?.toLowerCase();

  return (
    <StyledCenteredArea
      style={{
        backgroundColor: "var(--main-secondary)",
        width: "95%",
        borderRadius: "5px",
      }}
    >
      <StyledFormContainer
        ref={formRef}
        onSubmit={handleSubmit}
        style={{ width: "70%", borderRadius: "5px", minWidth: "500px" }}
      >
        <StyledFormTitle $bottom="10px">
          Client Fulfillment Form
        </StyledFormTitle>

        <StyledGroupWrapper>
          <StyledFormP>
            Select the client who you are fulfilling a request for
          </StyledFormP>

          <FormSelect
            setSelection={setSelectedUser}
            optionsList={clientUsernames}
            placeholder="-- SELECT A USER --"
          />
        </StyledGroupWrapper>

        <StyledGroupWrapper>
          <StyledFormP>
            Select the video name requested by the selected client, which you
            are fulfilling
          </StyledFormP>

          <LoadingStatus
            status={isLoading ? 0 : unfulfilledVideoNames.length > 0}
            style={{ textAlign: "center" }}
          />

          <FormSelect
            setSelection={setSelectedVideo}
            optionsList={unfulfilledVideoNames}
            placeholder="-- SELECT A VIDEO --"
          />
        </StyledGroupWrapper>

        {selectedUser && selectedVideo && (
          <StyledGroupWrapper>
            <StyledFormP>
              <span
                style={{
                  color: "var(--action-primary)",
                  fontWeight: "bold",
                }}
              >
                [Required]
              </span>{" "}
              Upload the content
            </StyledFormP>
            {videoType && (
              <ActiveUploads
                directory={`${selectedUser}/received/${videoType}s/${formattedSelectedVideo}`}
                acceptTypes={acceptTypes}
              />
            )}
          </StyledGroupWrapper>
        )}

        {completedUploads.length > 0 && <FormCta>Confirm & Send</FormCta>}
      </StyledFormContainer>
    </StyledCenteredArea>
  );
};

export default FulfillmentForm;

import React, { useContext, useEffect } from "react";

import { StyledContentDisplay } from "components/styles/objects/containers/ScrollContainer.styled";

import UploadBtn from "../buttons/UploadBtn";
import UploadingItem from "./UploadingItem";

import { UploadContext } from "components/contexts/UploadContext";

const ActiveUploads = ({
  acceptTypes,
  directory,
  canUpload = true,
  setCanUpload,
  method,
}) => {
  const [[progressUris], [], [completedUploads, setCompletedUploads]] =
    useContext(UploadContext);

  useEffect(() => {
    setCompletedUploads(
      completedUploads.filter(
        (uri) =>
          progressUris.find((targetUri) => targetUri === uri) != undefined
      )
    );
  }, [progressUris]);

  return (
    <StyledContentDisplay
      $height="auto"
      $align="center"
      $justify="start"
      $gap="close"
      style={{
        backgroundColor: "var(--main-background)",
      }}
    >
      {progressUris.map((progressUri) => (
        <UploadingItem
          key={progressUri}
          progressUri={progressUri}
          canUpload={canUpload}
          setCanUpload={setCanUpload}
        />
      ))}
      {canUpload && (
        <UploadBtn
          directory={directory}
          acceptTypes={acceptTypes || "image/*, video/*"}
          setCanUpload={setCanUpload}
          method={method}
        />
      )}
    </StyledContentDisplay>
  );
};

export default ActiveUploads;

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import FormPage from "components/canvas/FormPage";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormOptions,
  StyledGroupWrapper,
} from "_components/features/form/styles/FormStructs.styled";

import FormErrorMessage from "_components/features/form/elements/FormErrorMessage";
import FormOption from "_components/features/form/FormOption";
import FormItem from "_components/features/form/FormItem";
import FormCta from "_components/features/form/FormCta";

import { ENDPOINT } from "js/data/constants";
import formErrorMessages from "js/data/formErrorMessages";

function Join() {
  const [selectedOption, setSelectedOption] = useState("Partner");
  const [errorMessages, setErrorMessages] = useState([]);

  const navigate = useNavigate();
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);

    const obj = {
      name: formRef.current.username.value,
      email: formRef.current.email.value,
      username: formRef.current.username.value,
      password: formRef.current.password.value,
      rblxHandle: formRef.current.rblxHandle.value,
      // accountType: formRef.current.accountType.value,
      accountType: "Partner",
      target: formRef.current.target.value,
    };

    axios
      .post(`${ENDPOINT}/api/auth/user/signup`, obj)
      .then((res) => {
        const error = new URLSearchParams(res.data.params).get("error");
        if (!error) {
          const { hash, email } = JSON.parse(res.data.params);
          if (!hash || !email) {
            throw new Error(
              "Hash or Email missing from signup response on parsing!"
            );
          }
          console.log("successful client signup!");
          // TODO: Change back to /join/verify when auth is added back for clients
          navigate(`/login`);
        } else {
          const errMessage = formErrorMessages[error];
          setErrorMessages([errMessage]);
        }
      })
      .catch((error) => {
        // setCanSendForm(false);
        console.error(error);
      });
  };

  return (
    <>
      <FormPage>
        <StyledFormContainer
          $shadowed
          ref={formRef}
          onSubmit={(e) => handleSubmit(e)}
        >
          <StyledFormTitle $bottom="10px">Join as a Youtuber</StyledFormTitle>
          <FormItem
            $label="Email"
            placeholder="example@gmail.com"
            type="email"
            name="email"
            maxLength={320}
            required
            autoFocus
          />
          <FormItem
            $label="Username"
            placeholder="username"
            name="username"
            maxLength={26}
            required
          />
          <FormItem
            $label="Password"
            placeholder="password"
            type="password"
            name="password"
            maxLength={50}
            required
          />

          <FormItem
            $label="Roblox Username"
            style={{
              gridColumn: selectedOption === "Partner" ? "1 / 2" : null,
            }}
            placeholder="@username"
            name="rblxHandle"
            maxLength={20}
            required
          />
          {selectedOption === "Partner" && (
            <FormItem
              $label="Target No. Videos (weekly)"
              style={{ gridColumn: "2 / 3" }}
              placeholder="3-14 videos per week"
              type="number"
              name="target"
              max={14}
              min={3}
              required
            />
          )}
          {/* //* Temporarily disabled until affiliate support is fully implemented! */}
          {/* <StyledFormOptions>
            <FormOption
              value="Partner"
              name="accountType"
              defaultChecked
              setSelectedOption={setSelectedOption}
            >
              Join as a Partner
            </FormOption>
            <FormOption
              value="Affiliate"
              name="accountType"
              setSelectedOption={setSelectedOption}
            >
              Join as an Affiliate
            </FormOption>
          </StyledFormOptions> */}

          {errorMessages.length > 0 && (
            <StyledGroupWrapper>
              {errorMessages.map((message, index) => (
                <FormErrorMessage message={message} key={index} />
              ))}
            </StyledGroupWrapper>
          )}

          {/* Provide spacing by activating gap of grid */}
          <div></div>
          <FormCta note="Certain info can be changed after signing up, via settings">
            Create Account
          </FormCta>
        </StyledFormContainer>
      </FormPage>
    </>
  );
}

export default Join;

import React from "react";

import {
  StyledTab,
  StyledIconTab,
} from "components/styles/objects/common/TabElements.styled";

export const IconTab = ({
  children: text,
  setCurrentTab,
  $index,
  ...props
}) => {
  return (
    <StyledIconTab
      $index={$index}
      type="radio"
      onClick={() => setCurrentTab($index)}
      {...props}
    >
      {text}
    </StyledIconTab>
  );
};

export const Tab = ({ children: text, setCurrentTab, $index, ...props }) => {
  return (
    <StyledTab
      $index={$index}
      type="radio"
      onClick={() => setCurrentTab($index)}
      {...props}
    >
      {text}
    </StyledTab>
  );
};

const getUploadID = (uri) => {
  const uploadID = uri.split("upload_id=")[1];
  if (!uploadID) {
    console.warn("No uploadID found in URI");
    return;
  }
  return uploadID;
};

export default getUploadID;

import React from "react";

import { StyledIncomingFile } from "../../styles/FileElements.styled";

import OpenFileViewerBtn from "../../buttons/OpenFileViewerBtn";
import DownloadBtn from "../../buttons/DownloadBtn";

const ProductionFileViewerFile = ({ $title, fileID }) => {
  return (
    <StyledIncomingFile $light>
      <div>
        <p>
          <span>File Name: </span>
          {$title}
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "13px" }}>
        <DownloadBtn fileId={fileID} title={$title} />
      </div>
    </StyledIncomingFile>
  );
};

export default ProductionFileViewerFile;

import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import { ReactSession } from "react-client-session";

import { StyledHoverFlagBtn } from "_components/atoms/buttons/styles/IconButtons.styled";
import { StyledThinBtn } from "_components/atoms/buttons/styles/ThinButtons.styled";

import DirectionalBtn from "_components/atoms/buttons/DirectionalBtn";
import UserWithIcon from "_components/atoms/user/UserWithIcon";
import UserSelection from "_components/organisms/UserSelection";

import { centeredComponent } from "_components/function/modifiers/areaPositions";

import { hexToRgba } from "js/util/converters";
import getAccessToken from "js/features/auth/getAccessToken";
import { ENDPOINT } from "js/data/constants";

const accountTypes = ["Editor", "Artist"];

const StyledTeamBuilder = styled.div`
  padding: 50px;
  border-radius: 10px;
  background-color: var(--main-background);

  .header {
    margin-bottom: 40px;

    & > h2 {
      color: white;
      margin: 0;
      margin-bottom: 20px;
    }
  }
`;

const StyledGroup = styled.div`
  --offset: 20px;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  position: relative;
  margin-left: 20px;

  /* Row distinction with every second slight color change */
  .user:nth-child(2n) {
    background-color: ${() => hexToRgba("--main-secondary", 0.5)};
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    background-color: var(--sub-info-alt);
    height: calc(100% + var(--offset) * 2);
    width: 1px;

    transform: translate(calc(var(--offset) * -1), calc(var(--offset) * -1));
  }

  &::after {
    content: ${(props) => `'${props.$title}'`};
    color: var(--sub-info-alt);
    display: block;
    position: absolute;
    transform: translateX(calc(-100% - var(--offset))) rotate(-90deg);
  }
`;

const StyledUserElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--main-secondary);
  width: 100%;
  position: relative;

  padding: 10px 20px;

  & > p {
    margin: 0;
  }

  ${(props) =>
    props.$isNew &&
    css`
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: green;
      }
    `}
`;

function addToPendingChanges(setPendingChanges, changeType, name, accountType) {
  setPendingChanges((prevState) => ({
    ...prevState,
    [changeType]: [
      ...prevState[changeType],
      {
        name: name,
        accountType: accountType,
        // Identifies for visual contrast to prev friends
        new: true,
      },
    ],
  }));
}

function removeFromPendingChanges(
  setPendingChanges,
  changeType,
  name,
  accountType
) {
  setPendingChanges((prevState) => ({
    ...prevState,
    [changeType]: prevState[changeType].filter(
      (user) => user.name !== name || user.accountType !== accountType
    ),
  }));
}

const AddUserBtn = ({ onClick }) => {
  return (
    <DirectionalBtn
      text="Add new user"
      onClick={onClick}
      src="/images/buttons/plus-icon.png"
      style={{ marginTop: "20px" }}
    />
  );
};

const RemoveBtn = ({ setPendingChanges, currentFriends, user }) => {
  const removeUser = () => {
    const isCurrentFriend = currentFriends.find(
      (friendData) =>
        friendData.name == user.name &&
        friendData.accountType == user.accountType
    );

    // Only current users can be 'removed' and only next (non-current) users can be 'added'
    if (isCurrentFriend) {
      addToPendingChanges(
        setPendingChanges,
        "removing",
        user.name,
        user.accountType
      );
      return;
    }

    removeFromPendingChanges(
      setPendingChanges,
      "adding",
      user.name,
      user.accountType
    );
  };

  return (
    <StyledHoverFlagBtn $enabled onClick={removeUser}>
      <div className="hover-flag">Remove</div>
      <img src="/images/buttons/bin-icon.png" />
    </StyledHoverFlagBtn>
  );
};

const ViewBtn = ({ username }) => {
  const viewUser = () => {
    // TODO: Open DataView of user (navigate to 'analytics' page with queries)
  };

  return (
    <StyledHoverFlagBtn $enabled onClick={viewUser}>
      <div className="hover-flag">View</div>
      <img src="/images/buttons/pass-off-icon.png" />
    </StyledHoverFlagBtn>
  );
};

const SubmitReset = ({ username, pendingChanges, setPendingChanges }) => {
  // Combines all duplicates of same users into one data entry which contains both of their individual 'relationship's combined
  function combineDuplicatePendings(pendingUsers) {
    const newUsers = [];
    for (const user of pendingUsers) {
      const existingUser = newUsers.find(
        (user) => user.name == user.name && user.accountType != user.accountType
      );
      if (existingUser != undefined) {
        existingUser.relationship.push(user.accountType);
        continue;
      }

      newUsers.push({
        ...user,
        relationship: [user.accountType],
      });
    }
    return newUsers;
  }

  async function handleSubmit() {
    const accessToken = await getAccessToken();

    const addingUsers = combineDuplicatePendings(pendingChanges.adding);
    const removingUsers = combineDuplicatePendings(pendingChanges.removing);

    for (const addingUser of addingUsers) {
      try {
        await axios.put(
          `${ENDPOINT}/api/comm/team`,
          {
            uid1: username,
            uid2: addingUser.name,
            relationship: JSON.stringify(addingUser.relationship),
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    }

    for (const removingUser of removingUsers) {
      try {
        await axios.delete(`${ENDPOINT}/api/comm/team`, {
          params: {
            uid1: username,
            uid2: removingUser.name,
            relationship: JSON.stringify(removingUser.relationship),
          },
          headers: {
            Authorization: accessToken,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }

    handleReset();
  }

  // Only way to remove an item from 'removing' (currentFriends)
  function handleReset() {
    setPendingChanges({
      adding: [],
      removing: [],
    });
  }

  return (
    <>
      <StyledThinBtn
        $primary="var(--action-primary)"
        style={{ marginTop: "40px" }}
        onClick={handleSubmit}
      >
        Submit
      </StyledThinBtn>

      <StyledThinBtn
        $primary="var(--main-secondary)"
        style={{ marginTop: "40px" }}
        onClick={handleReset}
      >
        Reset
      </StyledThinBtn>
    </>
  );
};

const CenteredSubmitReset = centeredComponent(SubmitReset);

const UserElement = ({ $isNew, setPendingChanges, currentFriends, user }) => {
  return (
    <StyledUserElement $isNew={$isNew} className="user">
      <p>{user.name}</p>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        {/* <ViewBtn username={user.name} /> */}
        <RemoveBtn
          currentFriends={currentFriends}
          setPendingChanges={setPendingChanges}
          user={user}
        />
      </div>
    </StyledUserElement>
  );
};

/*
  References of 'accountType' and 'relationship' are closely related. The user's actual
  account type is split up into branch off 'relationships' that the user can can have with this
  friend, allowing specific selection of each user's role toward another user. Expanding from the
  previous system of simply just having users be 'friends', giving them access to all features and communication
  with the user. This time, it's filtered and fine-ly tuned/selected.
*/
const TeamBuilder = ({ username, goBackHandler }) => {
  const [currentFriends, setCurrentFriends] = useState([]);
  const [nextFriends, setNextFriends] = useState([]);

  const [typeBeingAdded, setTypeBeingAdded] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [pendingChanges, setPendingChanges] = useState({
    adding: [],
    removing: [],
  });

  async function loadCurrentFriends() {
    function newUser(name, accountType) {
      return {
        name: name,
        accountType: accountType,
        new: false,
      };
    }

    try {
      const accessToken = await getAccessToken();
      const response = await axios.get(
        `${ENDPOINT}/api/comm/friendships?uid=${username}`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );

      const friendsList = response.data.filter(
        (user) => user.accountType !== "Group"
      );

      const nextCurrentFriends = [];
      for (const user of friendsList) {
        // Exclude non-staff relations as the Team Builder is for production teams as of now
        if (user.relationship[0] === "Default") continue;

        // Split production staff by profession for exact assigning of roles regarding partner
        if (user.accountType === "Production") {
          user.relationship.forEach((relation) =>
            nextCurrentFriends.push(newUser(user.name, relation))
          );
          continue;
        }
        nextCurrentFriends.push(newUser(user.name, user.accountType));
      }

      setCurrentFriends(nextCurrentFriends);
    } catch (error) {
      console.error(error);
    }
  }

  function getBlacklists() {
    function formatFriends(list) {
      return list
        .filter((friendData) => friendData.accountType == typeBeingAdded)
        .map((friend) => `'${friend.name}'`);
    }

    function removeDuplicates(names) {
      const newNames = [];

      for (const name of names) {
        if (newNames.find((existingName) => existingName == name) != undefined)
          continue;
        newNames.push(name);
      }

      return newNames;
    }

    // Must map to explicitly quoted for server's sql search being in a type 'varchar' column
    const current = formatFriends(currentFriends);
    const next = formatFriends(nextFriends);
    const username = ReactSession.get("useruid");

    const usernameBlacklist = {
      column: "Username",
      values: removeDuplicates([...current, ...next, `'${username}'`]),
    };

    const blacklists = [usernameBlacklist];
    return blacklists;
  }

  function getWhitelists() {
    const whitelists = [];

    const editorWhitelist = {
      column: "IsEditor",
      db: "productioninformation",
      values: [1],
    };

    const artistWhitelist = {
      column: "IsArtist",
      db: "productioninformation",
      values: [1],
    };

    // We don't explicitly include a 'false'/'0' value on the type it's not, as users can have multiple professions
    if (typeBeingAdded === "Editor") whitelists.push(editorWhitelist);
    if (typeBeingAdded === "Artist") whitelists.push(artistWhitelist);

    return whitelists;
  }

  useEffect(() => {
    if (selectedUser == "") return;
    addToPendingChanges(
      setPendingChanges,
      "adding",
      selectedUser,
      typeBeingAdded
    );

    setSelectedUser("");
    setTypeBeingAdded("");
  }, [selectedUser]);

  useEffect(() => {
    // Allows removal per-group without affecting other groups with the same user. Giving individual group/relation control
    function userNotRemoving(user) {
      return (
        pendingChanges.removing.find(
          (removingUser) =>
            removingUser.name == user.name &&
            removingUser.accountType == user.accountType
        ) == undefined
      );
    }

    const filteredCurrentFriends = currentFriends.filter(userNotRemoving);
    const newNextFriends = [
      ...filteredCurrentFriends,
      ...pendingChanges.adding,
    ];

    setNextFriends(newNextFriends);
  }, [currentFriends, pendingChanges]);

  useEffect(() => {
    if (
      pendingChanges.adding.length == 0 &&
      pendingChanges.removing.length == 0
    )
      loadCurrentFriends();
  }, [pendingChanges]);

  if (typeBeingAdded != "" && selectedUser == "") {
    return (
      <>
        <DirectionalBtn
          text="Return to Team Builder"
          onClick={() => setTypeBeingAdded("")}
        />
        <UserSelection
          setSelectedUser={setSelectedUser}
          blacklists={getBlacklists()}
          whitelists={getWhitelists()}
        />
      </>
    );
  }

  return (
    <div style={{ minHeight: "500px", width: "70%", maxWidth: "800px" }}>
      <DirectionalBtn text="Return to search" onClick={goBackHandler} />
      <StyledTeamBuilder>
        <div className="header">
          <h2>Team Builder</h2>
          <UserWithIcon username={username} />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "70px" }}>
          {accountTypes.map((accountType) => (
            <StyledGroup $title={accountType}>
              {nextFriends
                .filter((user) => user.accountType == accountType)
                .map((user) => (
                  <UserElement
                    $isNew={user.new}
                    setPendingChanges={setPendingChanges}
                    currentFriends={currentFriends}
                    user={user}
                  />
                ))}
              <AddUserBtn onClick={() => setTypeBeingAdded(accountType)} />
            </StyledGroup>
          ))}
        </div>

        {(pendingChanges.adding.length > 0 ||
          pendingChanges.removing.length > 0) && (
          <CenteredSubmitReset
            username={username}
            pendingChanges={pendingChanges}
            setPendingChanges={setPendingChanges}
          />
        )}
      </StyledTeamBuilder>
    </div>
  );
};

export default TeamBuilder;

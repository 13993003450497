import React from "react";
import { styled } from "styled-components";

import { ScrollElement } from "_components/atoms/inline/SpanElements";

const StyledStickyBackToTop = styled(ScrollElement)`
  position: fixed;
  bottom: 50px;
  right: 50px;

  z-index: 10;
  cursor: pointer;

  height: 50px;
  width: 50px;
  border-radius: 50%;

  box-shadow: 0 0 5px 0 #00b1ff;
  border: solid 0px #00b1ff;

  transition: transform 0.05s ease-in-out, box-shadow 0.3s ease-in-out;

  transform: ${({ $isEnabled }) =>
    $isEnabled ? "translateY(0)" : "translateX(200px)"};

  &:active {
    transform: scale(0.95);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: contain;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #00b1ff;

    opacity: 1;
    transition: all 0.2s;
  }

  &:hover {
    box-shadow: 0 0 10px 1px #00b1ff;
    &::before {
      filter: brightness(1.2);
    }
  }

  &:active {
    transform: scale(0.95);
  }
`;

function StickyBackToTop({ $isEnabled, to }) {
  return (
    <StyledStickyBackToTop
      $isEnabled={$isEnabled}
      behavior="instant"
      to={to}
      src="/images/back-to-top.png"
    ></StyledStickyBackToTop>
  );
}

export default StickyBackToTop;

import React, { useState } from "react";

import { StyledSpacedTabsContainer } from "components/styles/objects/common/TabElements.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import { Tab } from "components/objects/common/Tab";
import { StyledSbs } from "components/styles/SideBySide.styled";

/*
  Gives a component tabs above it, and automatically passes the current tab
  to the component via the provided 'key' as the prop name.

  If 'useIndex=false', the tab is set the the panel name instead of it's index
*/
export function withTab(
  Component,
  panels = [],
  key,
  useIndex = true,
  canSet = (nextTab) => {
    return true;
  }
) {
  const WithTabComponent = ({ children, ...props }) => {
    const [currentTab, setCurrentTab] = useState(panels[0]);

    function setCurrentTabWrapper(nextTab) {
      if (canSet(nextTab) === false) return;
      setCurrentTab(nextTab);
    }

    return (
      <>
        <ManyTab
          panels={panels}
          currentTab={useIndex ? currentTab : panels.indexOf(currentTab)}
          setCurrentTab={
            useIndex
              ? setCurrentTabWrapper
              : (index) => setCurrentTabWrapper(panels[index])
          }
        />
        <Component {...{ [key]: currentTab }} {...props}>
          {children}
        </Component>
      </>
    );
  };

  const componentName = Component.displayName || Component.name || "Component";
  WithTabComponent.displayName = `withTab(${componentName})`;
  return WithTabComponent;
}

const ManyTab = ({ panels, currentTab, setCurrentTab }) => {
  return (
    <>
      <StyledSbs $shadowed>
        <StyledSpacedTabsContainer>
          {panels.map((name, index) => (
            <Tab
              $index={index}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              {name}
            </Tab>
          ))}
        </StyledSpacedTabsContainer>
      </StyledSbs>

      <StyledStandardSpacer $paddingTop="50px" />
    </>
  );
};

export default ManyTab;

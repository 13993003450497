import React from "react";

import SplitCard from "_components/molecules/containers/common/SplitCard";
import { StyledHoverFlagBtn } from "_components/atoms/buttons/styles/IconButtons.styled";

import { withChildrenComponent } from "_components/function/modifiers/withChildren";

/*
  Funtionality Overview:
  1. Original component initializes this inside itself, passing the emailjs etc. state we need.
  2. Card returned intercepts the 'data' and applies it to buttons in the next step that needs it.
  3. Buttons are added as children to 'SplitCard', and have the necessary 'data' to handle their
    'onConfirm' because of the wrapper.
*/
function reviewCard(requests, setRequests, emailJs, onConfirm) {
  const ReviewCard = ({ data, ...props }) => {
    function onClickHandler(response) {
      setRequests(
        requests.filter((request) => request.RequestId !== data.RequestId)
      );
      onConfirm(response, data, emailJs);
    }

    // Adds approve/decline buttons to split card for review
    const WithButtonsSplitCard = withChildrenComponent(
      SplitCard,
      <>
        <StyledHoverFlagBtn $enabled onClick={() => onClickHandler(0)}>
          <div className="hover-flag">Reject</div>
          {/* Must scale up as the image is smaller than the select btn and 'buttons' standard in general */}
          <img src="/images/close-btn.png" style={{ scale: "2" }} />
        </StyledHoverFlagBtn>
        <StyledHoverFlagBtn $enabled onClick={() => onClickHandler(1)}>
          <div className="hover-flag">Approve</div>
          <img src="/images/buttons/select-icon.png" />
        </StyledHoverFlagBtn>
      </>
    );

    return <WithButtonsSplitCard data={data} {...props} />;
  };

  return ReviewCard;
}

export default reviewCard;

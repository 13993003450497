import React from "react";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import { StyledFeatureOverviewBlock } from "_components/molecules/displays/styles/FeatureOverview.styled.js";

const SystemsOverview = () => {
  return (
    <StyledDualXStrip $col="var(--action-primary)">
      <StyledCenteredHeader>
        <h1>Systems Overview</h1>
        <p style={{ maxWidth: "580px" }}>
          Get a glimpse of the many features our website supports, allowing you
          to handle your every possible need, straight from our site
        </p>
      </StyledCenteredHeader>

      <StyledSbs
        $gap="50px"
        $yGap="50px"
        $align="center"
        $justify="center"
        $defaultWrap
        $shadowed
        style={{
          height: "100%",
          width: "100%",
          padding: "50px 0",
        }}
      >
        <StyledFeatureOverviewBlock>
          <div>
            <h2>Game Manager</h2>
            <p>
              View, select and monitor the progress of all of our upcoming games
            </p>
          </div>
          <img src="/images/buttons/controller-icon.png" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>Live Chat</h2>
            <p>
              Message admins, your production team, and any contact from our
              site
            </p>
          </div>
          <img src="/images/buttons/live-chat-icon.png" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>File Manager</h2>
            <p>
              Send, receive and manage files between you and your team quickly
            </p>
          </div>
          <img src="/images/buttons/file-manager-icon.png" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>Ownership Booster</h2>
            <p>
              Quickly boost your ownership percentage of games by filling out a
              simple form
            </p>
          </div>
          <img src="/images/buttons/chart-icon.png" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>In-built Checkout</h2>
            <p>
              Quickly and easily purchase as many edits and thumbnails in
              advance as you wish
            </p>
          </div>
          <img src="/images/buttons/cart-icon.png" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>Staff Scheduler</h2>
            <p>
              Your production team can efficiently co-ordinate their work using
              our scheduler
            </p>
          </div>
          <img src="/images/buttons/calendar-icon.png" />
        </StyledFeatureOverviewBlock>
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default SystemsOverview;

import React from "react";
import { useNavigate } from "react-router-dom";

import {
  StyledLinkSpan,
  StyledHoverSpan,
  StyledInlineBlockSpan,
} from "./styles/SpanButtons.styled";
import { StyledInfoHover } from "../buttons/styles/InfoHover.styled";

export const LinkSpan = ({ children: text, to, ...props }) => {
  const navigate = useNavigate();
  return (
    <StyledLinkSpan {...props} onClick={() => navigate(to)}>
      {text}
    </StyledLinkSpan>
  );
};

export const HoverSpan = ({ children: text, $text, ...props }) => {
  return (
    <StyledInlineBlockSpan>
      <StyledHoverSpan {...props}>
        {text}
        <StyledInfoHover $noPointer $inline="end" $length="x" $text={$text} />
      </StyledHoverSpan>
    </StyledInlineBlockSpan>
  );
};

export const ScrollElement = ({
  to,
  children: text,
  inline,
  behavior = "smooth",
  ...props
}) => {
  function onScroll(event) {
    event.preventDefault();

    var section = document.querySelector(`[name=${to}]`);
    section.scrollIntoView({ behavior: behavior });
  }

  return (
    <>
      {!inline ? (
        <div onClick={onScroll} {...props}>
          {text}
        </div>
      ) : (
        <StyledLinkSpan onClick={onScroll} {...props}>
          {text}
        </StyledLinkSpan>
      )}
    </>
  );
};

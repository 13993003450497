import styled from "styled-components";

export const StyledBlendContainer = styled.div`
  width: 100%;
  display: block;
  position: relative;
  height: ${(props) => props.$height};
`;

export const StyledBlendImg = styled.img`
  z-index: ${({ $layer }) => $layer || 0};
  position: ${({ $position }) => $position || "relative"};
  width: 100%;
  /* height: 300px; */
  height: 100%;
  top: 0;
  transform: translateY(${(props) => props.$yOffset});
`;

export const StyledImgCover = styled.div`
  background-size: ${(props) => props.$bgSize || "100% 100%"};
  background-repeat: repeat-x;
  background-image: url(${(props) => props.src});
  z-index: -1;

  bottom: ${(props) => props.$bottom};

  max-height: ${(props) => props.$maxHeight};

  position: absolute;
  width: 100%;
  /* height: 500px; */
  height: ${(props) => props.$height || "100%"};
  transform: translateY(${(props) => props.$yOffset || "0"});
`;

export const StyledCoveredImg = styled.div`
  z-index: -2;
  display: block;
  position: absolute;
  /* height: 700px; */
  height: ${(props) => props.$height || "100%"};
  width: 100%;
  /* transform: translateY(${(props) => props.$yOffset || "-250px"}); */
  transform: translateY(${(props) => props.$yOffset});

  opacity: ${(props) => props.$opacity || 0.4};

  background-size: ${(props) => props.$bgSize};
  background-position: ${(props) =>
    props.$pos ? "50% " + props.$pos : "50% 0"};
  background-repeat: repeat-x;
  background-image: url(${(props) => props.src});

  @media screen and (max-width: 1370px) {
    background-size: cover;
  }
`;

import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { LargeX } from "components/styles/brand/XElements.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledInfoStrip } from "_components/molecules/containers/common/styles/InfoStrip.styled";

import { ENDPOINT, SITE_URL } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const JoinReturn = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);
  const hash = params.get("hash");

  useEffect(() => {
    // Given what you have to pass here via url, it can used to get the rest of user data on server
    const uidValue = params.get("email") || params.get("username");
    const uidName = params.get("email") ? "Email" : "Username";
    if (hash && uidValue) {
      getAccessToken().then((token) => {
        axios.put(
          `${ENDPOINT}/api/auth/verify/hash`,
          {
            uidName: uidName,
            uidValue: `'${uidValue}'`,
            // Last param is just to keep hash ending in characters like '.' from being excluded from the hyperlink (bug)
            link: `${SITE_URL}/join/verify?hash=${hash}&verify=true`,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
    }
  }, []);

  return (
    <section
      style={{
        marginBottom: "300px",
        height: "1000px",
        width: "100%",
        position: "relative",
      }}
    >
      <LargeX $height="220%" src="/images/brand/large-x.png" />
      <StyledCenteredArea>
        <StyledInfoStrip $maxWidth="600px" $align="center">
          <h1>Verification Email Sent</h1>
          <p>
            Check your email for a verification link we{" "}
            {hash ? "just sent you" : "sent you upon signing up"}.
          </p>
          <p>
            Clicking that link will activate your account, allowing you to then
            login.
          </p>
          <button onClick={() => navigate("/login")}>Ok</button>
        </StyledInfoStrip>
      </StyledCenteredArea>
    </section>
  );
};

export default JoinReturn;

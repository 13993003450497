import { styled } from "styled-components";

export const StyledIndexHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;

  // Lines up with header below it
  padding-left: 10%;
  width: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    width: max(30%, 200px);
    background-image: linear-gradient(to right, #000, var(--main-background));
    z-index: -1;
  }
`;

export const StyledHeadingH2 = styled.h2`
  background-color: #000;
  padding: 40px 0 40px 10%;
`;

export const StyledQuestion = styled.p`
  color: white;
  max-width: 500px;
`;

export const StyledBorderedP = styled.p`
  display: block;
  position: relative;

  box-sizing: border-box;
  padding: 20px;

  border: 2px solid #3a00a7;
  border-radius: 20px;

  color: white;
  max-width: min(500px, 80%);
`;

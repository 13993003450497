import React, { useState } from "react";

import { StyledCalculator } from "_components/themes/calculator/Calculator.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import { SquareCta } from "components/CtaElements";

const maxInputNumber = 29900000;
const minimumEntryBracket = 0.5;

const Calculator = ({
  title,
  placeholder,
  getLowerBracket = (subCount) => {
    return Math.floor(subCount / 100000);
  },
  getHigherBracket = (lowerBracket) => {
    const isMillion = lowerBracket >= 10;
    const hundredThousand = isMillion ? 0.1 : 1;
    return (isMillion ? lowerBracket / 10 : lowerBracket) + hundredThousand;
  },
  setLowerBracket,
  setBracketSubCount,
  setPriceBracket,
  children: calculatorResults,
}) => {
  const [subCount, setSubCount] = useState("");
  const [showResults, setShowResults] = useState({ opacity: 0 });

  const calculatePriceBracket = () => {
    let lowerBracket = getLowerBracket(subCount);

    const isMillion = lowerBracket >= 10;
    const suffix = isMillion ? "M" : "K";
    const scaleUpMultiplier = isMillion ? 1 : 100;

    let higherBracket = getHigherBracket(lowerBracket);

    if (isMillion) {
      lowerBracket = parseFloat(lowerBracket / 10);
    }

    if (subCount / 100000 < minimumEntryBracket) {
      return false;
    } else if (lowerBracket === 0) {
      lowerBracket = minimumEntryBracket;
    }

    setPriceBracket(
      `${lowerBracket * scaleUpMultiplier}-${
        higherBracket * scaleUpMultiplier
      }${suffix}`
    );

    setLowerBracket(lowerBracket);
    setBracketSubCount && setBracketSubCount(subCount);
    return true;
  };

  const handleSubmit = () => {
    setShowResults({ opacity: 0, transition: "opacity 0.2s ease-in-out" });

    setTimeout(() => {
      const isApplicable = calculatePriceBracket();
      if (isApplicable == true) {
        setShowResults({ opacity: 1, transition: "opacity 1s ease-in-out" });
      } else {
        // todo: tell user they can't join
      }
    }, 300);

    setSubCount("");
  };

  return (
    <StyledDualXStrip $only="pull">
      <StyledCenteredHeader>
        <StyledSectionH2>Calculator</StyledSectionH2>
        <p style={{ maxWidth: "500px" }}>{title}</p>
      </StyledCenteredHeader>

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledCenteredArea>
        <StyledCalculator>
          <div className="calculator">
            <input
              placeholder={placeholder}
              type="number"
              max={maxInputNumber}
              value={subCount}
              onChange={(e) => {
                if (e.target.value > maxInputNumber) {
                  return;
                }
                setSubCount(e.target.value);
              }}
            />
            <SquareCta onClick={handleSubmit}>Calculate</SquareCta>
            <p
              style={{
                fontWeight: "350",
                fontStyle: "italic",
                margin: 0,
                marginTop: "15px",
              }}
            >
              Minimum Entry Bracket:{" "}
              <b>{minimumEntryBracket * 100}k subscribers</b>
            </p>
          </div>
          <div className="results" style={showResults}>
            {calculatorResults}
          </div>
        </StyledCalculator>
      </StyledCenteredArea>
    </StyledDualXStrip>
  );
};

export default Calculator;

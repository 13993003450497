import React from "react";
import { useNavigate } from "react-router-dom";

import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";

import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";

const RedirectBlockSection = ({
  $primary,
  $maxWidth,
  heading,
  subHeading,
  to,
  ctaText,
}) => {
  const navigate = useNavigate();
  return (
    <StyledDualXStrip $only="pull" $centered>
      <StyledSbs $gap="0" $column>
        <StyledSectionH2>{heading}</StyledSectionH2>
        <p style={{ textAlign: "center", maxWidth: $maxWidth }}>{subHeading}</p>
        <ThinBtn $primary={$primary} onClick={() => navigate(to)}>
          {ctaText}
        </ThinBtn>
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default RedirectBlockSection;

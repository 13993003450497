import React from "react";

import { LargeX } from "components/styles/brand/XElements.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

const InfoPage = ({ $xStyle, children }) => {
  return (
    <section
      style={{
        marginBottom: "300px",
        height: "1000px",
        width: "100%",
        position: "relative",
      }}
    >
      <LargeX
        $height="220%"
        src={
          $xStyle === "alt"
            ? "/images/brand/large-x-alt.png"
            : "/images/brand/large-x.png"
        }
      />
      <StyledCenteredArea>{children}</StyledCenteredArea>
    </section>
  );
};

export default InfoPage;

import React, { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import { StyledStatBlock, StyledStat } from "../styles/Stats.styled";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import gameDatas from "js/data/gameDatas";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const GameStats = ({ gameStats, gameTitle }) => {
  const [loadedStats, setLoadedStats] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const loadGameDatas = async () => {
    const gameId = gameDatas.find((data) => data.game === gameTitle)?.id;
    if (!gameId) {
      console.error(`No game id found for game title: ${gameTitle}`);
      return;
    }

    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/game/list/${gameId}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        setLoadedStats({ ...loadedStats, youtubers: res.data.length });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    loadGameDatas();
  }, []);

  return (
    <StyledStatBlock>
      <h1 style={{ color: "var(--main-primary)" }}>Game Stats</h1>

      <LoadingStatus
        status={isLoading ? 0 : Object.entries(gameStats).length > 0}
        style={{ textAlign: "center" }}
      />

      {gameStats.map(({ name, value }) => (
        <StyledStat $typeIndex={0}>
          <p className="heading">{name}</p>
          <p className="info">{value}</p>
        </StyledStat>
      ))}

      {Object.entries(loadedStats).length > 0 && (
        <StyledStat $typeIndex={0}>
          <p className="heading">No. Youtubers Involved</p>
          <p className="info">{loadedStats.youtubers}</p>
        </StyledStat>
      )}
    </StyledStatBlock>
  );
};

export default GameStats;

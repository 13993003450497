import styled, { createGlobalStyle } from "styled-components";

export const StyledOverlayBackground = styled.div`
  position: ${(props) => props.$position || "fixed"};
  display: flex;
  width: 100%;
  height: ${(props) => (props.$padded ? "80%" : "100%")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => !props.$invisible && "rgba(0, 0, 0, 0.5)"};
  z-index: 10;

  align-items: ${(props) => (props.$centered ? "center" : "start")};
  justify-content: center;

  overflow-y: ${(props) => props.$overflow};
`;

export const GlobalStyleOverride = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

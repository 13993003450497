import React, { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";

import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";
import ProfileItem from "../ProfileItem";

import fetchClientTransactions from "js/functions/fetchClientTransactions";

const PartnerProfileItems = () => {
  const navigate = useNavigate();

  const [edits, setEdits] = useState("Loading..");
  const [thumbnails, setThumbnails] = useState("Loading..");

  useEffect(() => {
    fetchClientTransactions().then(({ edits, thumbnails }) => {
      setEdits(edits);
      setThumbnails(thumbnails);
    });
  }, []);

  return (
    <>
      <ProfileItem
        title="Total Percentage Ownership"
        value={ReactSession.get("userownership") + "%"}
      >
        <StyledInfoHover
          $text="Increase your percentage ownership on our games"
          onClick={() => navigate("/game/booster")}
        />
      </ProfileItem>
      <ProfileItem title="Unused Edits" value={edits}>
        <StyledInfoHover
          $text="Navigate to the File Manager to use your unused Edits"
          onClick={() =>
            navigate("/file?tab_outgoing=0&form=true&form_default=edits")
          }
        />
      </ProfileItem>
      <ProfileItem title="Unused Thumbnails" value={thumbnails}>
        <StyledInfoHover
          $text="Navigate to the File Manager to use your unused Thumbnails"
          onClick={() =>
            navigate("/file?tab_outgoing=0&form=true&form_default=thumbnails")
          }
        />
      </ProfileItem>
    </>
  );
};

export default PartnerProfileItems;

import React from "react";
import styled from "styled-components";
import { ReactSession } from "react-client-session";

import {
  StyledFlexbox,
  StyledPairDisplay,
} from "components/styles/SideBySide.styled";

import { LinkSpan } from "_components/atoms/inline/SpanElements";

import ChatBtn from "./ChatBtn";

const statusCols = {
  "On Time Off": "var(--exit-main)",
  Busy: "var(--contrast-one)",
  Available: "var(--action-primary)",
};

const StyledTeamProfile = styled.div`
  border-radius: 10px;
  padding: 25px;
  background-color: var(--main-background);
  width: min(600px, 90%);

  display: flex;
  gap: 10px;

  @media only screen and (max-width: 500px) {
    & > :first-child {
      flex-wrap: wrap;
    }
    flex-wrap: wrap;
  }
`;

const TeamProfile = ({
  isEditor,
  isArtist,
  status,
  editSpeed,
  thumbnailSpeed,
  videoId,
  name,
}) => {
  const profession =
    (isEditor ? "Editor" : "") +
    (isEditor && isArtist ? " / " : "") +
    (isArtist ? "Artist" : "");
  const statusCol =
    statusCols[Object.keys(statusCols).find((status_) => status_ === status)];

  const isYou = ReactSession.get("useruid") === name;
  const accountType = ReactSession.get("useraccounttype");

  return (
    <StyledTeamProfile>
      <StyledFlexbox $row $gap="20px">
        <img
          src="/images/profile-icon.png"
          style={{
            height: "70px",
            marginRight: "10px",
          }}
        />
        <StyledFlexbox $wrap $row $gap="30px 0" className="info">
          <StyledPairDisplay $primary="var(--action-primary)">
            <p>{profession}</p>
            <p
              style={{
                fontWeight: isYou && "bold",
              }}
            >
              {isYou ? "You" : name}
            </p>
          </StyledPairDisplay>

          <StyledPairDisplay>
            <p>Edit Speed</p>
            <p>{editSpeed} Days</p>
          </StyledPairDisplay>

          <StyledPairDisplay>
            <p>Thumbnail Speed</p>
            <p>{thumbnailSpeed} Days</p>
          </StyledPairDisplay>

          <StyledPairDisplay $secondary={statusCol}>
            <p>Status</p>
            <p>
              {status}
              {accountType === "Partner" && status === "Busy" && " - "}
              {accountType === "Partner" && status === "Busy" && (
                <LinkSpan
                  $col="var(--contrast-one)"
                  to="/file?tab=1&tab_outgoing=1"
                >
                  #{videoId}
                </LinkSpan>
              )}
            </p>
          </StyledPairDisplay>
        </StyledFlexbox>
      </StyledFlexbox>

      <StyledFlexbox $row $align="end">
        {!isYou && <ChatBtn user={name} />}
      </StyledFlexbox>
    </StyledTeamProfile>
  );
};

export default TeamProfile;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ReactSession } from "react-client-session";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import ChatSystem from "_components/features/chat/ChatSystem";
import FormSelect from "_components/features/form/elements/FormSelect";

import { FriendsContext } from "_components/function/contexts/FriendsContext";
import { centeredComponent } from "_components/function/modifiers/areaPositions";

import FriendFilterer from "js/util/FriendFilterer";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const CenteredFormSelect = centeredComponent(FormSelect);

const Chat = () => {
  const friends = useContext(FriendsContext);
  let friendFilterer = new FriendFilterer(friends);

  const accountType = ReactSession.get("useraccounttype");
  const isProduction = accountType === "Production";
  const isAdmin = accountType === "Admin";
  const isTopLevel = isProduction || isAdmin;

  const [selectedClient, setSelectedClient] = useState("");
  const [clientUsernames, setClientUsernames] = useState([]);
  const [friendsByTeam, setFriendsByTeam] = useState([]);

  // Acts as client, getting their view, and then swaps themselves (admin) out for the client
  async function loadClientFriends() {
    try {
      const response = await axios.get(`${ENDPOINT}/api/comm/friendships`, {
        params: {
          uid: selectedClient,
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      });

      const username = ReactSession.get("useruid");
      friendFilterer = new FriendFilterer(response.data);

      setFriendsByTeam(friendFilterer.swapOutIn(username, selectedClient));
    } catch (error) {
      console.error(error);
    }
  }

  function loadClientUsernames() {
    if (isTopLevel)
      setClientUsernames(friendFilterer.getNamesByAccountType("Partner"));
  }

  function loadFriends() {
    if (selectedClient == "") {
      setFriendsByTeam(isTopLevel ? [] : friends);
      return;
    }
    if (isProduction) {
      // Filter's staff user's chat's to only ones that the client is also in (chatrooms of that team)
      setFriendsByTeam(friendFilterer.getUsersInRelationTo(selectedClient));
    } else if (isAdmin) {
      loadClientFriends();
    }
  }

  useEffect(loadClientUsernames, [friends]);
  useEffect(loadFriends, [selectedClient]);

  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
        <h1>Chat</h1>
        <p>Message teams and individuals across our company</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      {isTopLevel && (
        <>
          <CenteredFormSelect
            setSelection={setSelectedClient}
            optionsList={clientUsernames}
            placeholder="-- SELECT A CLIENT --"
          />
          <StyledStandardSpacer $paddingTop="50px" />
        </>
      )}
      <ChatSystem
        client={selectedClient}
        userState={[friendsByTeam, setFriendsByTeam]}
      />
    </StyledStandardEndSection>
  );
};

export default Chat;

import React, { useEffect, useState } from "react";
import axios from "axios";

import { StyledOverlayBackground } from "_components/molecules/containers/overlays/styles/Overlay.styled";
import {
  StyledOverlayCard,
  StyledOverlayHeader,
  StyledExitBtn,
} from "_components/molecules/containers/overlays/styles/OverlayCard.styled";

import { StyledContentDisplay } from "components/styles/objects/containers/ScrollContainer.styled";

import ProductionFileViewerFile from "../download/collections/ProductionFileViewerFile";
import MessageBox from "_components/features/form/MessageBox";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const FileViewer = ({ username, setVisible, title, data }) => {
  const [videoDatas, setVideoDatas] = useState([]);
  const [thumbnailDatas, setThumbnailDatas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const loadFileDatas = async () => {
    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/drive/read/file-contents/${data.id}?layers=2`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const [videos = [], thumbnails = []] = res.data.files;
        setVideoDatas(videos);
        setThumbnailDatas(thumbnails);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Failed to fetch file contents!", err);
      });
  };

  useEffect(() => {
    loadFileDatas();
  }, []);

  return (
    <StyledOverlayBackground
      $overflow="scroll"
      onClick={(e) => e.target === e.currentTarget && setVisible(false)}
    >
      <StyledOverlayCard style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <StyledExitBtn onClick={() => setVisible(false)}>
          <img src="/images/close-btn.png" />
        </StyledExitBtn>
        <StyledOverlayHeader
          style={{
            backgroundColor: "var(--main-secondary-dark)",
            padding: "15px 0",
          }}
        >
          <h1>File Viewer</h1>
          <p>Title - {title}</p>
          <p style={{ color: "var(--action-primary)" }}>
            ID #{data.isRevision ? "RE" : "OG"}-{data.requestId}
          </p>
        </StyledOverlayHeader>

        <MessageBox
          $label={`Message from youtuber: @${username}`}
          value={data.message}
          style={{ margin: "40px 0" }}
        />

        <StyledContentDisplay
          style={{
            backgroundColor: "var(--main-secondary-dark)",
            marginBottom: "30px",
          }}
        >
          <h2 style={{ margin: 0 }}>Raw Footage</h2>
          {videoDatas.map((data, index) => (
            <ProductionFileViewerFile
              $title={data.name}
              key={index}
              fileID={data.id}
            />
          ))}
          <LoadingStatus status={isLoading ? 0 : videoDatas.length > 0} />
        </StyledContentDisplay>
        <StyledContentDisplay
          style={{ backgroundColor: "var(--main-secondary-dark)" }}
        >
          <h2 style={{ margin: 0 }}>Thumbnail Guides</h2>
          {thumbnailDatas.map((data, index) => (
            <ProductionFileViewerFile
              $title={data.name}
              key={index}
              fileID={data.id}
            />
          ))}
          <LoadingStatus status={isLoading ? 0 : thumbnailDatas.length > 0} />
        </StyledContentDisplay>
      </StyledOverlayCard>
    </StyledOverlayBackground>
  );
};

export default FileViewer;

import styled, { css } from "styled-components";

import { DashedUnderlineVerticleStyle } from "../../_components/atoms/inline/styles/Decorations.styled";

export const StyledCompanyStatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  /* position: relative; */
  width: 100%;

  &::after {
    ${DashedUnderlineVerticleStyle};
    --invis-col: var(--main-background);
    top: unset;
    bottom: -5%;
    height: 81%;
  }

  @media only screen and (max-width: 500px) {
    &::after {
      z-index: -1;
    }
  }
`;

const StyledCompanyTrackedStat = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  margin-top: 100px;
  padding: 20px;
  width: 300px;

  ${(props) => {
    if (props.$order === 0) {
      return css`
        transform: scale(1.2) translateX(-45%);
        align-items: end;
        justify-content: end;
        padding-right: 30px;

        &::before {
          right: 0;
        }

        & > h1,
        & > p {
          text-align: right;
        }
      `;
    } else if (props.$order === 1) {
      return css`
        transform: scale(1.2) translateX(45%);
        align-items: start;
        justify-content: end;
        padding-left: 40px;

        &::before {
          left: 0;
        }

        & > h1,
        & > p {
          text-align: left;
        }
      `;
    }
  }};

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 65%;
    width: 80%;
    background-color: var(--main-secondary);
    z-index: -1;

    border-radius: 0 0 15px 15px;
  }

  & > h1 {
    margin: 0;
    color: var(--action-primary);
    z-index: 1;
  }

  & > p {
    margin: 0;
    color: white;
    z-index: 1;
    max-width: 80%;
  }

  @media only screen and (max-width: 500px) {
    transform: translateX(0);
  }
`;

export const CompanyTrackedStat = ({ value, desc, index }) => {
  const order = index % 2;

  return (
    <StyledCompanyTrackedStat $order={order}>
      <h1>{value}</h1>
      <p>{desc}</p>
    </StyledCompanyTrackedStat>
  );
};

import axios from "axios";
import { ReactSession } from "react-client-session";

import sessionConfig from "./sessionConfig";
import { ENDPOINT } from "js/data/constants";

export const isLoggedIn = () => {
  return ReactSession.get("userid") != null;
};

export const logoutUser = (navigate) => {
  const refreshToken = ReactSession.get("refreshToken");
  const logoutPromise = axios
    .delete(`${ENDPOINT}/api/auth/user/logout?token=${refreshToken}`)
    .then(() => {
      const userAccountType = ReactSession.get("useraccounttype");
      if (sessionConfig[userAccountType]) {
        // ReactSession.get might be async. So we ensure account type is available for removal
        sessionConfig[userAccountType].logout().then(() => {
          ReactSession.remove("useraccounttype");
        });
      } else {
        ReactSession.remove("useraccounttype");
      }

      ReactSession.remove("userid");
      ReactSession.remove("useremail");
      ReactSession.remove("useruid");
      ReactSession.remove("userpwd");
      ReactSession.remove("userrating");

      ReactSession.remove("accessToken");
      ReactSession.remove("refreshToken");

      navigate("/");
      return true;
    })
    .catch(console.error);

  return logoutPromise;
};

export const loginUser = (formRef, navigate) => {
  const obj = {
    username: formRef.current.username.value,
    password: formRef.current.password.value,
  };

  const loginPromise = axios
    .post(`${ENDPOINT}/api/auth/user/login`, obj)
    .then((res) => {
      const params = new URLSearchParams(res.data);
      const error = params.get("error");
      if (error == "none") {
        ReactSession.setStoreType("localStorage");

        ReactSession.set("userid", params.get("userid"));
        ReactSession.set("useremail", params.get("useremail"));
        ReactSession.set("useruid", params.get("useruid"));
        ReactSession.set("userpwd", params.get("userpwd"));
        ReactSession.set("useraccounttype", params.get("useraccounttype"));
        ReactSession.set("userrating", params.get("userrating"));

        ReactSession.set("accessToken", params.get("accessToken"));
        ReactSession.set("refreshToken", params.get("refreshToken"));

        const userAccountType = params.get("useraccounttype");
        sessionConfig[userAccountType]?.login(params);

        navigate("/profile");
      }
      return error;
    });

  return loginPromise;
};

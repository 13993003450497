import React from "react";
import styled, { css } from "styled-components";

import { StyledGrid } from "components/styles/SideBySide.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { centeredNative } from "components/styles/common/Headings.styled";
import {
  StyledContentDisplay,
  StyledScrollContainer,
} from "components/styles/objects/containers/ScrollContainer.styled";

import {
  DashedUnderlineStyle,
  DashedUnderlineVerticleStyle,
} from "_components/atoms/inline/styles/Decorations.styled";

// Pushes top line down to allow title an underline and pushing rest of elements down to match
const yOffset = "2.5rem";

export const StyledSplitSection = styled(StyledGrid)`
  position: relative;
  height: ${(props) => props.$height};

  ${(props) =>
    !props.$noX &&
    css`
      &::before {
        ${DashedUnderlineStyle};
        --invis-col: var(--main-background);
        top: ${(props) => (props.$yOffset ? props.$yOffset : "0")};
        left: 0;
        right: 0;
        opacity: 0.15; // Subtle is better
      }
    `}

  ${(props) =>
    !props.$noY &&
    css`
      &::after {
        ${DashedUnderlineVerticleStyle};
        --invis-col: var(--main-background);
        top: 0;
        bottom: 0;
        left: ${(props) =>
          props.$xOffset ? `calc(50% + ${props.$xOffset})` : "50%"};
        opacity: 0.15;
      }
    `}
`;

const StyledCenteredH3 = centeredNative("h3");

const SplitPage = ({
  alias = "Item",
  idKey = "",
  data: dataByColumn = [],
  ...props
}) => {
  return (
    <StyledSplitSection
      $height="1000px"
      $cols={2 || dataByColumn.length}
      $yOffset={yOffset}
      {...props}
    >
      {dataByColumn.map((data) => (
        <div>
          <StyledCenteredH3 style={{ color: "var(--sub-info-light)" }}>
            {data.heading}
          </StyledCenteredH3>

          <StyledStandardSpacer $paddingTop={yOffset} />
          {data.children}
          <StyledScrollContainer>
            <StyledContentDisplay>
              {data.items.map((itemData) => {
                const Item = data.Item;
                const id = idKey != undefined ? ` #${itemData[idKey]}` : "";
                const displayName = alias + id;

                return <Item title={displayName} data={itemData} />;
              })}
            </StyledContentDisplay>
          </StyledScrollContainer>
        </div>
      ))}
    </StyledSplitSection>
  );
};

export default SplitPage;

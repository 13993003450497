import styled from "styled-components";

export const StyledSidebarredBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  p {
    color: white;
    margin: 0;
  }
`;

export const StyledDisplayArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$centered ? "center" : "start")};
  justify-content: ${(props) => (props.$centered ? "center" : "start")};
  gap: 17px;
  background-color: ${(props) =>
    props.$light
      ? "var(--main-secondary-alt)"
      : props.$dark
      ? "var(--main-secondary-dark)"
      : "var(--main-background)"};
  height: 100%;
  flex-grow: ${(props) => props.$grow};
  padding: 20px;
  border-radius: ${(props) =>
    props.$top === "left"
      ? "5px 0 0 5px"
      : props.$top === "right"
      ? "0 5px 5px 0"
      : "5px"};
`;

import React from "react";

import { StyledSettingBlock } from "../styles/Settings.styled";

const SettingBlock = ({ children, title, $flex, ...props }) => {
  return (
    <StyledSettingBlock $flex={$flex} {...props}>
      <h2>{title}</h2>
      <div>{children}</div>
    </StyledSettingBlock>
  );
};

export default SettingBlock;

import React from "react";
import { useNavigate } from "react-router-dom";

import { StyledDottedLinkText } from "./styles/TextButtons.styled";

export const DottedLinkText = ({ children: text, to, ...props }) => {
  const navigate = useNavigate();
  return (
    <StyledDottedLinkText {...props} onClick={() => navigate(to)}>
      {text}
    </StyledDottedLinkText>
  );
};

import React from "react";

import { Cta } from "components/CtaElements";
import { StyledXStrip } from "_components/themes/brand/XStrip.styled";
import { StyledCtaBorder } from "components/styles/elements/CtaElements.styled";

const xStripStyleOverride = {
  height: "280px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const LoneCtaSection = ({ to, children: text }) => {
  return (
    <section>
      <StyledXStrip $push={4}>
        <StyledXStrip $pull={4} style={xStripStyleOverride}>
          <Cta to={to}>
            <StyledCtaBorder />
            {text}
          </Cta>
        </StyledXStrip>
      </StyledXStrip>
    </section>
  );
};

export default LoneCtaSection;

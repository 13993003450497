import styled from "styled-components";

export const StyledOverlayCard = styled.div`
  margin: 5% 0 5% 0;
  height: auto;
  width: 80%;
  position: relative;
  background-image: linear-gradient(to bottom right, #161727, #12121d);
  padding: 4% 0;
  /* border-radius: 20px 20px 0 0; */
  border-radius: 5px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledOverlayHeader = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: 60px;

  h1 {
    margin: 0;
  }

  p {
    margin: 0;
    color: ${(props) =>
      props.$importantSub ? "var(--action-primary)" : "var(--sub-info-light)"};
    font-weight: bold;
  }
`;

export const StyledExitBtn = styled.button`
  position: absolute;

  height: 50px;
  width: 50px;

  top: 0;
  right: 0;

  margin: 20px 20px 0 0;

  background: none;
  border: none;

  cursor: pointer;

  z-index: 2;

  & > img {
    width: 100%;
    height: auto;

    transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: transform 0.05s linear;
  }

  &:hover > img {
    filter: brightness(1.2);
    transform: scale(1.05);
  }

  &:active > img {
    transform: scale(0.9);
    transition: transform 0.05s linear;
  }

  @media screen and (max-width: 600px) {
    margin: 10px 10px 0 0;
    height: 50px;
    width: 50px;
  }
`;

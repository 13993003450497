import { styled, css } from "styled-components";

import { StyledStandardSpacer } from "./structure/StandardSpacer.styled";

const centeredStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  margin: 0;
`;

export const StyledCenteredH1 = styled.h1`
  ${centeredStyle}

  height: 100%;
  width: 100%;
`;

// TODO: Update these to use new method down below `centeredNative` everywhere
export const StyledCenteredHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 20%;
  text-align: center;
  position: ${(props) => props.$position || "relative"};
  bottom: ${(props) => props.$bottom};
  padding: 20px 0;

  & > h1 {
    margin: 0;
  }
`;

// TODO: Move this module and make it possibly separate .jsx (this), .js (styles), /modifiers (centeredNative)
export const CenteredHeader = ({ title, children: text }) => {
  return (
    <>
      <StyledCenteredHeader>
        <h1>{title}</h1>
        <p style={{ maxWidth: "580px" }}>{text}</p>
      </StyledCenteredHeader>
      <StyledStandardSpacer $paddingTop="50px" />
    </>
  );
};

// Returns a centred native (non-react/non-custom) component like 'h1', 'div' etc.
export function centeredNative(name) {
  return styled[name](centeredStyle);
}

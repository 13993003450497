import React, { useContext } from "react";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormP,
} from "_components/features/form/styles/FormStructs.styled";

import FormCta from "_components/features/form/FormCta";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import ActiveUploads from "_components/features/file/upload/ActiveUploads";

import { UploadContext } from "components/contexts/UploadContext";

const UploadMenu = ({
  setVisible,
  message,
  title = "Upload Menu",
  directory,
  acceptTypes,
}) => {
  const [
    [progressUris, setProgressUris],
    [fileDatas, setFileDatas],
    [completedUploads, setCompletedUploads],
    folderName,
  ] = useContext(UploadContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProgressUris([]);
    setFileDatas({});
    setCompletedUploads([]);
    setVisible(false);
  };

  return (
    // TODO: Make it stop searching for thumbnail guides etc
    <StyledCenteredArea
      onSubmit={handleSubmit}
      style={{
        backgroundColor: "var(--main-secondary)",
        width: "95%",
        borderRadius: "5px",
      }}
    >
      <StyledFormContainer
        style={{ width: "70%", borderRadius: "5px", minWidth: "500px" }}
      >
        <StyledFormTitle $bottom="10px">{title}</StyledFormTitle>

        {message && <StyledFormP>{message}</StyledFormP>}

        <ActiveUploads
          directory={directory}
          acceptTypes={acceptTypes}
          method="single"
        />

        <FormCta>Confirm & Send</FormCta>
      </StyledFormContainer>
    </StyledCenteredArea>
  );
};

export default UploadMenu;

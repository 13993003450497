import React, { useState } from "react";

import {
  StyledSettingItem,
  StyledSettingItemBtn,
} from "../styles/Settings.styled";

import SettingChangeScreen from "./SettingChangeScreen";

const SettingItem = ({ title, value, dbName, columnName, type, min, max }) => {
  const [newValue, setNewValue] = useState("");
  const [isChangingSetting, setIsChangingSetting] = useState(false);

  return (
    <>
      <SettingChangeScreen
        type={type}
        min={min}
        max={max}
        dbName={dbName}
        columnName={columnName}
        placeholder={`New ${title}`}
        newValue={newValue}
        setNewValue={setNewValue}
        isChangingSetting={isChangingSetting}
        setIsChangingSetting={setIsChangingSetting}
      />

      <StyledSettingItem>
        {title + ": " + value}
        {dbName != undefined && columnName != undefined && (
          <StyledSettingItemBtn
            src="/images/buttons/edit-icon.png"
            onClick={() => setIsChangingSetting(true)}
          />
        )}
      </StyledSettingItem>
    </>
  );
};

export default SettingItem;

export const clientInfoList = [
  ["/images/connections-icon.png", "Get Full Personal Production Team"],
  ["/images/grow-icon.png", "Get Free Channel Advertising"],
  ["/images/daily-icon.png", "Achieve Daily Uploads"],
  ["/images/income-icon.png", "Own % Shares of Roblox Games"],
];

export const productionInfoList = [
  ["/images/time-icon.png", "Work on your own schedule"],
  ["/images/competition-icon.png", "Zero competition"],
  ["/images/work-icon.png", "Only need to focus on one niche (Roblox)"],
  ["/images/daily-icon.png", "More work is always available"],
  ["/images/income-icon.png", "Double your income"],
  ["/images/connections-icon.png", "Build connnections with large Youtubers"],
];

import React from "react";

import { StyledIncomingFile } from "../../styles/FileElements.styled";

import OpenFileViewerBtn from "../../buttons/OpenFileViewerBtn";

import { dateFormattingOptions } from "js/data/constants";

const ProductionIncomingFile = ({
  setVisible,
  setCurrentData,
  data,
  ...props
}) => {
  const { id: fileID, title, requestId, isRevision } = data;
  const date = new Intl.DateTimeFormat("en-US", dateFormattingOptions).format(
    new Date(data.createdTime)
  );

  return (
    <StyledIncomingFile {...props}>
      <div>
        <p style={{ color: "var(--action-primary)" }}>
          ID #{isRevision ? "RE" : "OG"}-{requestId}
        </p>
        <p>
          <span className="title">Title: </span>
          {title}
        </p>
        <p>
          <span className="title">Received date: </span>
          {date}
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "13px" }}>
        <OpenFileViewerBtn
          setVisible={setVisible}
          setCurrentData={setCurrentData}
          data={data}
        />
      </div>
    </StyledIncomingFile>
  );
};

export default ProductionIncomingFile;

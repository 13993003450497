import React, { useState } from "react";
import styled from "styled-components";

import LoadingStatus from "_components/atoms/info/LoadingStatus";
import { centeredComponent } from "_components/function/modifiers/areaPositions";

const StyledArrowNavigation = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  gap: 10px;

  & > p {
    color: var(--action-primary);
  }

  & > img {
    height: 20px;
    cursor: pointer;
    transition: filter 0.3s ease-in-out;

    &:hover {
      filter: brightness(1.4);
    }

    &:active {
      transform: scale(0.9);
    }
  }
`;

const StyledDataElement = styled.div`
  background-color: var(--main-secondary-dark);
  border-radius: 10px;
  padding: 10px 20px;
  /* width: 300px; */

  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;

  overflow: hidden;
  cursor: pointer;
  transition: filter 0.3s ease-in-out;

  &::before {
    content: "";
    background-color: var(--main-primary);

    display: block;
    position: absolute;
    height: 3px;
    top: 0;
    left: 0;
    right: 0;

    transform: translateY(-3px);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    filter: brightness(1.1);

    &::before {
      transform: translateY(0px);
    }
  }

  & > img {
    height: 45px;
  }
`;

const StyledPagedData = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-height: 250px;
  width: 80%;

  position: relative;
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(
    auto-fit,
    ${(props) => props.$colWidth || "300px"}
  );
  gap: 35px;
  align-items: start;
  justify-content: center;
`;

const CenteredLoadingStatus = centeredComponent(LoadingStatus);

export const ArrowNavigation = ({ pageIndex, setPageIndex, canIncrease }) => {
  const setPage = (nextIndex) => {
    // Prevent increase page (usually) if the last data was empty (end of data reached)
    if (nextIndex < 1 || (nextIndex > pageIndex && canIncrease === false))
      return;
    setPageIndex(nextIndex);
  };

  return (
    <StyledArrowNavigation>
      <img
        src="/images/buttons/directional-arrow-icon.png"
        onClick={() => setPage(pageIndex - 1)}
      />
      <p>{pageIndex}</p>
      <img
        src="/images/buttons/directional-arrow-icon.png"
        style={{ rotate: "180deg" }}
        onClick={() => setPage(pageIndex + 1)}
      />
    </StyledArrowNavigation>
  );
};

const UserDataElement = ({
  src = "/images/profile-icon-alt.png",
  data: text,
  onClickHandler,
}) => {
  return (
    <StyledDataElement onClick={() => onClickHandler(text)}>
      {src && <img src={src} />}
      <p>{text}</p>
    </StyledDataElement>
  );
};

const PagedData = ({
  $colWidth,
  data,
  isLoading,
  onClickHandler,
  Element = UserDataElement,
}) => {
  return (
    <>
      {data.length == 0 && (
        <CenteredLoadingStatus status={isLoading ? 0 : data.length > 0} />
      )}
      <StyledPagedData $colWidth={$colWidth}>
        {data.map((item) => (
          <Element data={item} onClickHandler={onClickHandler} />
        ))}
      </StyledPagedData>
    </>
  );
};

export default PagedData;

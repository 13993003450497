import { styled } from "styled-components";

export const StyledFaqList = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledFaqItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 15px;
`;

export const StyledFaqItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 45px 1fr;

  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;

  height: 50px;
  width: 100%;
  max-width: 800px;

  border: 1px solid var(--action-primary);
  border-radius: 10px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  overflow: hidden;

  cursor: pointer;
  transition: height 0.3s;

  p {
    color: white;
  }

  .header {
    margin: 0;
    grid-area: 1 / 1 / 2 / 2;

    color: ${({ $expanded }) =>
      $expanded ? "var(--action-primary)" : "white"};
    font-weight: ${({ $expanded }) => ($expanded ? 400 : 400)};
    line-height: 1.1rem;

    user-select: none;

    transition: color 0.2s;
  }

  .icon {
    margin: 0;
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
    justify-self: center;

    height: 30px;
    width: 30px;

    font-size: 1.5rem;
    text-align: center;

    user-select: none;

    filter: invert();

    transform: ${({ $expanded }) =>
      $expanded ? "rotate(90deg)" : "rotate(0)"};

    transition: transform 0.2s;
  }

  .content {
    grid-area: 2 / 1 / 3 / 3;
    cursor: auto;
  }

  @media screen and (max-width: 700px) {
    width: 95%;
  }
`;

import React, { useState, useEffect } from "react";

import { StyledResult } from "_components/themes/calculator/Calculator.styled";

const subCountInterval = 2.5; // ratio 1 : 100,000 subs

const editIncrease = 0.1;
const editMinimum = 0.8;

const thumbnailIncrease = 0;
const thumbnailMinimum = 4;

const EarningsCalculator = ({ $noHeader, bracketSubCount }) => {
  const [editPrice, setEditPrice] = useState("");
  const [thumbnailPrice, setThumbnailPrice] = useState(0);

  const correctPriceDecimal = (price) => {
    price = price.toString();
    let ending = price.split(".")[1];

    if (ending && ending.length > 1) {
      price = price.slice(0, price.length - (ending.length - 2));
    }

    if (!ending) {
      price = price + ".00";
    } else if (ending.length === 1) {
      price = price + "0";
    }
    return price;
  };

  const observeEarnings = () => {
    // Working in tens instead of units for accurate rounding
    // Applys increase on earnings every 'interval' of subs
    const bracketSubCount_ = Math.floor(
      bracketSubCount / (100000 / 10) / (subCountInterval * 10)
    );

    let editPrice = correctPriceDecimal(
      bracketSubCount_ * editIncrease + editMinimum
    );
    let thumbnailPrice = correctPriceDecimal(
      bracketSubCount_ * thumbnailIncrease + thumbnailMinimum
    );

    setEditPrice(`${editPrice}`);
    setThumbnailPrice(`${thumbnailPrice}`);
  };

  useEffect(observeEarnings, [bracketSubCount]);

  return (
    <div className="block">
      {!$noHeader && <h3>Your Earnings</h3>}
      <StyledResult $isEdit>
        Earnings per <span className="underlined">Edit</span>
        <br />
        <span className="value">${editPrice}</span>
      </StyledResult>
      <br />
      <StyledResult>
        Earnings per <span className="underlined">Thumbnail</span>
        <br />
        <span className="value">${thumbnailPrice}</span>
      </StyledResult>
    </div>
  );
};

export default EarningsCalculator;

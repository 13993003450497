import styled, { css } from "styled-components";
import { NavLink as Link } from "react-router-dom";

import { ScrollElement } from "_components/atoms/inline/SpanElements";

const NavElement = css`
  display: flex;
  color: white;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  user-select: none;

  justify-content: center;
  font-size: 1.05rem;
  padding: 0 60px;
`;

const Hoverable = css`
  position: relative;
  transition: color 0.2s, transform 0.2s;

  &::after {
    content: "";
    width: 100%;
    height: 100%;

    display: block;
    position: absolute;

    height: 1px;
    width: 0;
    bottom: 20px;
    background-color: #9278ff;
    transition: width 0.2s;
  }

  &:hover::after {
    width: 40px;
  }

  &:hover {
    color: #9278ff;

    /* transform: translateY(-0.05rem); */
  }
`;

export const NavScroll = styled(ScrollElement)`
  ${Hoverable};
  ${NavElement};
`;

export const NavLink = styled(Link)`
  ${Hoverable};
  ${NavElement};
`;

export const NavLogo = styled(Link)`
  ${NavElement};
  font-family: sans-serif;
  font-size: 1.5rem;
  padding: 0 1rem;
  font-weight: bold;

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const StyledNavMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    & > div {
      padding: 0 30px;
    }
  }
`;

export const StyledNav = styled.nav`
  padding: 0 3%;

  height: 100px;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  /* padding: 0.2rem calc((100vw - 1000px) / 2); */
  z-index: 12;

  @media screen and (max-width: 700px) {
    justify-content: center;
    padding: 0;
  }
`;

export const StyledNavExpand = styled.div`
  ${NavElement};
  cursor: auto;
  transition: color 0.3s ease-in-out;
  position: relative;

  &::after {
    content: "";
    width: 8px;
    height: 6px;
    background-color: var(--action-primary);
    margin-left: 5px;
    margin-top: 5px;

    clip-path: polygon(50% 0, 0 100%, 100% 100%);

    transition: rotate 0.1s ease-in-out;
    rotate: 180deg;
  }

  .container {
    top: 100%;
    position: absolute;
    display: block;
    pointer-events: none;

    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  .triangle {
    top: calc(100% - 16px);
    background-color: var(--action-primary);
    position: absolute;
    display: block;
    height: 16px;
    width: 18px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
  }

  .box {
    /* Not prefixed with $, as we need to global to be accessed up multiple layers of inheritance */
    transform: ${(props) =>
      props.$originAt && `translateX(${props.$originAt})`};

    top: 100%;
    background-color: var(--action-primary);
    position: absolute;

    display: flex;
    align-items: start;
    justify-content: start;

    border-radius: 10px;
    gap: 30px;
    padding: 25px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      background-color: var(--main-secondary);
      height: calc(100% - 1px);
      width: 100%;
      bottom: -1px;
      left: 0;
      border-radius: 10px;
    }

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  &:hover {
    color: var(--action-primary);

    &::after {
      rotate: 0deg;
    }

    .container {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const StyledExpandCollection = styled.div`
  --collection-width: 180px;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  width: var(--collection-width);
  gap: 10px;
`;

export const StyledExpandHeading = styled.h2`
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  margin-bottom: 10px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: var(--action-primary);
    bottom: -7px;
    height: 1px;
    width: var(--collection-width);
  }
`;

export const StyledExpandElement = styled.div`
  cursor: pointer;
  z-index: 2;

  ${(props) =>
    props.$isActive &&
    css`
      cursor: auto;

      & > h2 {
        color: var(--action-primary);
      }
    `};

  & > h2 {
    margin: 0;
    padding-bottom: 3px;
    font-size: 1rem;
    line-height: 1rem;

    transition: all 0.15s ease-in-out;
  }

  & > p {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }

  &:hover {
    & > h2 {
      color: var(--action-primary);
    }
  }
`;

import styled from "styled-components";

export const StyledCtaBorder = styled.div`
  position: absolute;
  height: 190%;
  width: 125%;

  border: 2px var(--action-primary) dashed;
  border-radius: 25px;
`;

import React, { useContext } from "react";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledBlockImg } from "components/styles/Block.styled";
import {
  StyledLineInfoBlock,
  StyledLineInfoBlockH1,
  StyledLineInfoBlockP,
} from "_components/molecules/containers/common/styles/StyledInfoBlock.styled";
import { StyledArrowIndentSpan } from "_components/atoms/inline/styles/Decorations.styled";
import { StyledXStrip } from "../XStrip.styled";

import { QueryContext } from "components/contexts/QueryContext";

function AboutUs({ name }) {
  const { queryWrap } = useContext(QueryContext);

  return (
    <StyledXStrip $pull={1} $paddingY="400px">
      <StyledSbs $yGap="200px" $gap="5%">
        <StyledBlockImg
          src="/images/au-diagram.png"
          style={{ order: queryWrap.matches ? "2" : null }}
        />
        <StyledLineInfoBlock
          $colBack="var(--main-secondary)"
          $addedHeight="200px"
        >
          <StyledLineInfoBlockH1 $pull>
            What is PUSHXPULL?
          </StyledLineInfoBlockH1>
          <StyledLineInfoBlockP>
            PUSHXPULL is an agency which aims to help Roblox YouTubers
            specifically, to grow their channel using our many methods and
            services.
          </StyledLineInfoBlockP>

          <StyledLineInfoBlockP>
            We connect the Roblox Game Development industry with the Roblox
            Youtube Content Creation industry. This benefits both the game
            studios, and the Youtubers involved.
          </StyledLineInfoBlockP>

          <StyledLineInfoBlockP>
            <span style={{ color: "var(--main-primary)" }}>
              We create & manage..
            </span>
            <StyledArrowIndentSpan>
              Game Studios which all develop new games regularly.
            </StyledArrowIndentSpan>
            <StyledArrowIndentSpan>
              Production Teams of video editors and thumbnail artists, who work
              with you directly, through our company.
            </StyledArrowIndentSpan>
          </StyledLineInfoBlockP>

          <StyledLineInfoBlockP>
            We build new game studios and form new production teams very
            frequently to grow our company quickly which massively benefits you,
            which you can see below..
          </StyledLineInfoBlockP>
        </StyledLineInfoBlock>
      </StyledSbs>
    </StyledXStrip>
  );
}

export default AboutUs;

import styled, { css, keyframes } from "styled-components";

const gfxCircleGlimmerAnim = keyframes`
  0%, 80%, 100% {
    /* filter: drop-shadow(0 0 0 #5a01ff); */
    opacity: 1;
    filter: brightness(1);
  }

  50% {
    /* filter: drop-shadow(0 0 20px #5a01ff); */
    opacity: 0.8;
    filter: brightness(0.95);
  }
`;

const glareAnim = keyframes`
  0%, 40%, 100% {
    filter: brightness(1);
    box-shadow: 0 0 50px 20px #5604fe;
  }

  20% {
    filter: brightness(1.3);
    box-shadow: 0 0 55px 25px #6417ff;
  }
`;

export const StyledGfxCircle = styled.div`
  --center-height: 500px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: var(--center-height);
    width: var(--center-height);
    background-image: linear-gradient(to bottom, #4200ce, #a266e6);
    /* box-shadow: 0 0 50px 20px #5604fe; */
    border-radius: 50%;

    animation: 5s ease-in-out infinite ${glareAnim};
  }

  .center img {
    display: block;
    position: absolute;
    height: 123%;
    transition: opacity 0.12s ease-in-out;
  }

  ${(props) =>
    props.$autoResponsive &&
    css`
      @media screen and (max-width: 1370px) {
        /* Keep a 16:9 aspect ratio */
        .center {
          padding-top: 56.25%;
          height: auto;
          width: 60%;
        }

        .center img {
          position: absolute;
          top: 0;
        }
      }
    `}
`;

export const StyledCircle = styled.circle`
  ${(props) => css`
    animation: 3s ease-in-out ${props.$delay} infinite ${gfxCircleGlimmerAnim};
  `}
`;

import { styled, css } from "styled-components";

import { ReversedStyle } from "./css/ReversedStyle";

export const StyledFlexbox = styled.div`
  width: ${(props) => props.$width};

  display: flex;
  flex-direction: ${(props) => (props.$row ? "row" : "column")};
  align-items: ${(props) => props.$align || "start"};
  justify-content: ${(props) => props.$justify || "start"};

  flex-wrap: ${(props) => props.$wrap && "wrap"};
  gap: ${(props) => props.$gap};

  & > * {
    margin: 0;
  }
`;

export const StyledGrid = styled(StyledFlexbox)`
  width: ${(props) => props.$width || "100%"};

  display: grid;
  grid-template-columns: ${(props) =>
    props.$cols && `repeat(${props.$cols}, 1fr)`};
  grid-template-rows: ${(props) =>
    props.$rows && `repeat(${props.$rows}, 1fr)`};
`;

// Displays a 'pair' of stacked key, value 'pairs', presenting each data item individually
export const StyledPairDisplay = styled(StyledFlexbox)`
  width: ${(props) => props.$width || "170px"};
  // Wraps the <p> text children. Overrides 'Flexbox' '$wrap' as it's unneeded in this component
  overflow-wrap: ${(props) => props.$wrap && "anywhere"};

  & > *:first-child {
    color: ${(props) => props.$primary || "var(--sub-info-light)"};
  }

  & > *:nth-child(2) {
    margin: 0;
    line-height: 1.4rem;
    color: ${(props) => props.$secondary || "white"};
  }
`;

export const StyledSbs = styled.div`
  display: flex;
  position: relative;
  padding: 0 ${(props) => props.$paddingX};

  align-items: ${(props) => props.$align || "center"};
  justify-content: ${(props) => props.$justify || "center"};

  flex-direction: ${(props) => (props.$column && "column") || "row"};
  filter: ${(props) =>
    props.$shadowed === "center"
      ? "drop-shadow(0px 0px 8px #000)"
      : props.$shadowed
      ? "drop-shadow(8px 8px 8px #000)"
      : "unset"};

  gap: ${(props) => props.$gap || "15%"};

  height: 100%;
  width: ${(props) => props.$width};

  flex-wrap: ${(props) => props.$defaultWrap && "wrap"};

  ${(props) => {
    if (props.$centered) {
      return css`
        margin-left: auto;
        margin-right: auto;
        max-width: ${props.$centered};
      `;
    }
  }}

  ${(props) => {
    switch (props.$reversed) {
      case true:
        return ReversedStyle;
      default:
        return null;
    }
  }};

  ${(props) => {
    switch (props.flipped) {
      case true:
        return css`
          & > :last-child .center img {
            transform: scaleX(-1);
          }
        `;
      default:
        return null;
    }
  }};

  @media screen and (max-width: 1370px) {
    ${(props) => {
      // Use manualWrap if the the yGap overriding the gap before wrap activates is an issue
      if (props.$manualWrap) {
        return css`
          flex-direction: column;
        `;
      } else {
        return css`
          flex-wrap: wrap;
        `;
      }
    }};

    gap: ${(props) => props.$yGap || "20px"};
  }
`;

import { useEffect } from "react";

import useScript from "components/util/useScript";

const LoadEmailJs = ({ setEmailJs }) => {
  const { emailjs } = useScript(
    "https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js",
    "emailjs"
  );

  useEffect(() => {
    if (emailjs == null) return;
    emailjs.init("kNiGgVXMxqOZz4LZ-");
    setEmailJs(emailjs);
  }, [emailjs]);

  return;
};

export default LoadEmailJs;

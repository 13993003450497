import { useState, useRef, useEffect } from "react";
import axios from "axios";

import {
  StyledSelectP,
  StyledSelectFlexbox,
} from "_components/molecules/containers/overlays/styles/SelectScreen.styled";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import IconPopupBtn from "../../../atoms/buttons/IconPopupBtn";
import { EmbeddedFormItem } from "_components/features/form/FormItem";
import InputItem from "_components/features/form/InputItem";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const maxExtensions = 1; // Max number of extensions allowed per video request

const RequestTimeBtn = ({ requestId, extensionEnabled }) => {
  const formRef = useRef(null);

  const [enabled, setEnabled] = useState(extensionEnabled);
  const [extension, setExtension] = useState(1);
  const [eligible, setEligible] = useState(false);

  const confirmSelectScreenInfo = {
    confirmed: {
      title: "More Time Requested!",
      description: (
        <>
          <StyledSelectP $last>
            You will receive an email when the client responds to this request.
            If the client does not respond within{" "}
            <StyledHighlightedSpan>24 hours</StyledHighlightedSpan>, then the
            video request{" "}
            <StyledHighlightedSpan>
              remains your responsability
            </StyledHighlightedSpan>{" "}
            will be{" "}
            <StyledHighlightedSpan>
              automatically cancelled
            </StyledHighlightedSpan>{" "}
            if you do not fulfill the video request by the{" "}
            <span style={{ color: "var(--action-primary)" }}>'Due'</span> date.
          </StyledSelectP>
        </>
      ),
    },
    prompt: {
      title: "Request More Time?",
      description: (
        <StyledSelectFlexbox>
          <StyledSelectP $width="300px">
            The client can{" "}
            <StyledHighlightedSpan>decline</StyledHighlightedSpan>,{" "}
            <StyledHighlightedSpan>accept</StyledHighlightedSpan> or{" "}
            <StyledHighlightedSpan>ignore</StyledHighlightedSpan> this request.
            If they do not accept this request within{" "}
            <StyledHighlightedSpan>24 hours</StyledHighlightedSpan> then the
            original <StyledHighlightedSpan>'Due'</StyledHighlightedSpan> date
            remains and you must try to fulfill the video request by then or
            else the video request will be{" "}
            <StyledHighlightedSpan>
              automatically cancelled
            </StyledHighlightedSpan>{" "}
            and refunded to the client.
          </StyledSelectP>
          <EmbeddedFormItem
            ref={formRef}
            $width="300px"
            $label="Reason"
            name="message"
            placeholder="Reason for requesting more time (max 200 characters)"
            maxLength={200}
          />
          <div>
            <InputItem
              $label="Days Extended By"
              placeholder="1-3"
              type="number"
              name="extension"
              max={3}
              min={1}
              input={extension}
              setInput={setExtension}
            />
          </div>
        </StyledSelectFlexbox>
      ),
    },
  };

  const unavailableScreenInfo = {
    prompt: {
      title: "Time Extension Unavailable",
      description: (
        <StyledSelectP>
          There has already been a time extension granted on this video. Only{" "}
          <StyledHighlightedSpan>{maxExtensions}</StyledHighlightedSpan> time
          extension(s) can be granted per video. This means you cannot request a
          time extension for this video and must either finish and fulfill it in
          the allocated time, or face a{" "}
          <StyledHighlightedSpan>rating deduction</StyledHighlightedSpan> for
          "failure to fulfill"!
        </StyledSelectP>
      ),
    },
  };

  const onConfirmed = async () => {
    axios
      .post(
        `${ENDPOINT}/api/payment/fulfillment/extension`,
        {
          extension: extension,
          requestId: requestId,
          reason: formRef.current.message.value,
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .catch(console.error);
  };

  async function fetchEligibility() {
    try {
      const response = await axios.get(
        `${ENDPOINT}/api/payment/fulfillment/extension?id=${requestId}`,
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      );
      setEligible(Object.values(response.data).length === 0);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchEligibility();
  }, []);

  return (
    <IconPopupBtn
      $width="large"
      $flagWidth="120px"
      confirmSelectScreenInfo={
        eligible ? confirmSelectScreenInfo : unavailableScreenInfo
      }
      onConfirmed={onConfirmed}
      enabled={enabled}
      setEnabled={setEnabled}
    >
      <div className="hover-flag">
        {enabled ? "Request Time" : "Request Sent"}
      </div>
      <img src="/images/buttons/request-time-icon.png" />
    </IconPopupBtn>
  );
};

export default RequestTimeBtn;

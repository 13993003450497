import styled, { css } from "styled-components";

export const StyledDualXStrip = styled.section`
  --radius-added: 70px;
  --slant-height: 100px;
  --top-offset: ${(props) => (props.$centered ? "-1.55" : "-1.15")};

  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--radius-added) * var(--top-offset));
    height: calc(100% + var(--radius-added) * 3.15);
    width: 100%;
    z-index: -10;
  }

  ${(props) =>
    props.$only === "push"
      ? css`
          &::after {
            content: unset;
          }
        `
      : props.$only === "pull"
      ? css`
          &::before {
            content: unset;
          }
        `
      : css``}

  &::before {
    background-color: ${(props) => props.$col || "var(--main-primary)"};
    clip-path: polygon(
      0 var(--slant-height),
      100% 0,
      100% calc(100% - var(--slant-height)),
      0 100%
    );
  }

  &::after {
    background-color: var(--main-secondary);
    clip-path: polygon(
      0 0,
      100% var(--slant-height),
      100% 100%,
      0 calc(100% - var(--slant-height))
    );
  }
`;

import styled, { css } from "styled-components";

import { StyledQuotedDiv } from "components/styles/common/QyotedText.styled";
import { Ref, useRef } from "react";

type TestimonialProps = {
  data: {
    name: string;
    type: string;
    quote: string;
    url?: string;
  };
};

export const StyledTestimonial = styled.div<{ $isUrl?: boolean }>`
  position: relative;
  width: 350px;
  min-height: 300px;
  padding: 30px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  font-style: italic;
  overflow: hidden;

  background-color: var(--main-secondary);

  ${(props) =>
    props.$isUrl &&
    css`
      cursor: pointer;

      &::before {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        content: "Visit User Profile";
        font-weight: bold;
        color: white;

        top: 0;

        background-color: var(--main-primary);
        height: 25%;
        width: 100%;
        opacity: 0;
        position: absolute;
        border-radius: 10px 10px 0 0;
        transition: all 0.2s ease-in-out;
        z-index: 3;
      }

      &:hover::before {
        opacity: 1;
      }
    `}

  & > h2 {
    margin: 0;
    margin-top: 14px;
  }

  & > img {
    height: 70px;
  }

  .type {
    color: var(--main-primary);
    margin: 0;
  }

  & > p {
    color: white;
    margin: 0;
    line-height: 1.5rem;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

const Testimonial = ({
  data: { name, type, quote, url },
}: TestimonialProps) => {
  const anchorRef: React.MutableRefObject<null | HTMLAnchorElement> =
    useRef(null);

  return (
    <StyledQuotedDiv>
      <StyledTestimonial
        $isUrl={url != undefined}
        onClick={() =>
          anchorRef.current != undefined && anchorRef.current.click()
        }
      >
        <a ref={anchorRef} href={url} target="_blank" />
        <img src="/images/profile-icon-alt.png" />
        <h2>{name}</h2>
        <p className="type">{type}</p>
        <p>{quote}</p>
      </StyledTestimonial>
    </StyledQuotedDiv>
  );
};

export default Testimonial;

import React, { useState } from "react";
import { ReactSession } from "react-client-session";

import { StyledSbs } from "components/styles/SideBySide.styled";

import UploadSystem from "_components/features/file/upload/UploadSystem";
import DownloadSystem from "_components/features/file/download/DownloadSystem";
import ProductionDownloadSystem from "_components/features/file/download/collections/ProductionDownloadSystem";
import ProductionUploadSystem from "_components/features/file/upload/collections/ProductionUploadSystem";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSpacedTabsContainer } from "components/styles/objects/common/TabElements.styled";
import { Tab } from "components/objects/common/Tab";

import { UploadContext } from "components/contexts/UploadContext";
import { DownloadContext } from "components/contexts/DownloadContext";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

const FileManagerSystem = () => {
  const userAccountType = ReactSession.get("useraccounttype");

  const [currentTab, setCurrentTab] = useState(1);

  const [pendingDownloads, setPendingDownloads] = useState([]);
  const [activeDownloads, setActiveDownloads] = useState([]);

  const [progressUris, setProgressUris] = useState([]);
  const [fileDatas, setFileDatas] = useState({});
  const [completedUploads, setCompletedUploads] = useState([]);

  const [folderName, setFolderName] = useState(
    "Unconfirmed-" + new Date().getTime()
  );

  return (
    <>
      <ParamTabSet setTab={setCurrentTab} parser={(str) => parseInt(str)} />
      <StyledDualXStrip>
        <StyledSbs
          $gap="20px"
          $align="center"
          $justify="start"
          $centered="min(1200px, 90%)"
          $shadowed
          $column
        >
          <StyledSpacedTabsContainer>
            <Tab
              $index={0}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              Incoming
            </Tab>
            <Tab
              $index={1}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              Outgoing
            </Tab>
          </StyledSpacedTabsContainer>

          {currentTab === 0 && (
            <DownloadContext.Provider
              value={[
                [pendingDownloads, setPendingDownloads],
                [activeDownloads, setActiveDownloads],
              ]}
            >
              {userAccountType === "Partner" && <DownloadSystem />}
              {userAccountType === "Production" && <ProductionDownloadSystem />}
            </DownloadContext.Provider>
          )}

          {currentTab === 1 && (
            <UploadContext.Provider
              value={[
                [progressUris, setProgressUris],
                [fileDatas, setFileDatas],
                [completedUploads, setCompletedUploads],
                folderName,
              ]}
            >
              {userAccountType === "Partner" && <UploadSystem />}
              {userAccountType === "Production" && <ProductionUploadSystem />}
            </UploadContext.Provider>
          )}
        </StyledSbs>
      </StyledDualXStrip>
    </>
  );
};

export default FileManagerSystem;

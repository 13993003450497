import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ReactSession } from "react-client-session";

import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";
import ProfileItem from "../ProfileItem";

import { FriendsContext } from "_components/function/contexts/FriendsContext";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const ProductionProfileItems = () => {
  const friends = useContext(FriendsContext);
  const [status, setStatus] = useState("Loading..");

  const loadStatus = async () => {
    const client = friends
      .filter((user) => user.accountType === "Partner")
      .map((user) => user.name)[0];
    const username = ReactSession.get("useruid");

    if (!client) return;

    axios
      .get(`${ENDPOINT}/api/comm/team?uid=${client}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) =>
        setStatus(res.data.find((user) => user.name === username).status)
      )
      .catch(console.error);
  };

  useEffect(() => {
    loadStatus();
  }, [friends]);

  return (
    <>
      <ProfileItem title="Status" value={status}>
        <StyledInfoHover
          $noPointer
          $length="x"
          $text="[1] On Time Off, [2] Busy (assigned work), [3] Available (no work assigned)"
        />
      </ProfileItem>
    </>
  );
};

export default ProductionProfileItems;

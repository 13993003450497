import React from "react";
import { ReactSession } from "react-client-session";

import SettingItem from "../SettingItem";

const ClientAccountInfo = () => {
  return (
    <>
      <SettingItem
        title="Roblox Handle"
        value={ReactSession.get("userrblx")}
        dbName="clientinformation"
        columnName="RblxHandle"
        max={20}
      />
      <SettingItem
        title="Target No. Videos (weekly)"
        value={ReactSession.get("usertarget")}
        dbName="clientinformation"
        columnName="TargetUploads"
        type="number"
        min={1}
        // TODO: May need increasing in the future as we grow
        max={7}
      />
    </>
  );
};

export default ClientAccountInfo;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  const scrollPageTop = () => {
    document.documentElement.scrollTo(0, 0);
  };

  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash) {
      const section = document.querySelector(`[name=${hash}]`);
      if (!section) {
        scrollPageTop();
        return;
      }
      section.scrollIntoView();
    } else {
      scrollPageTop();
    }
  }, [location.pathname, location.hash]);

  return <></>;
};

export default ScrollToTop;

import { findAllByTestId } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledSearchBox = styled.div`
  background-color: var(--main-secondary-dark);
  border-radius: 10px;
  padding: 12px 15px;
  width: min(90%, 550px);

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > input {
    width: 100%;
    background-color: unset;
    appearance: none;
    border: unset;
  }

  & > input:focus-visible {
    outline: none;
  }

  & > img {
    height: 18px;
    cursor: pointer;
    transition: filter 0.3s ease-in-out;

    &:hover {
      filter: brightness(1.2);
    }
  }
`;

const SearchBox = ({
  pageIndex,
  setPageIndex,
  template,
  searchHandler,
  isLive = false,
}) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    searchHandler(query);
  }, [pageIndex]);

  return (
    <StyledSearchBox>
      <input
        placeholder={`Search ${template}...`}
        type="search"
        onChange={(e) => {
          setQuery(e.target.value);
          setPageIndex(1);
          if (isLive === true) searchHandler(e.target.value);
        }}
      />
      <img
        src="/images/buttons/search-icon.png"
        onClick={() => searchHandler(query)}
      />
    </StyledSearchBox>
  );
};

export default SearchBox;

import { css, styled } from "styled-components";

import { DashedUnderlineStyle } from "../../atoms/inline/styles/Decorations.styled";

export const StyledCalculator = styled.div`
  background-color: var(--main-background);
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  min-height: 311px;

  flex-direction: column;
  height: auto;
  padding: 50px 0;

  gap: 50px;

  width: 450px;

  .calculator {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 1;
  }

  .calculator input {
    background-color: var(--main-secondary);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.95rem;
    padding: 14px;
    text-align: center;

    margin-bottom: 20px;
  }

  .calculator input::placeholder {
    color: var(--sub-info-alt);
  }

  .results {
    width: 75%;
  }

  h4 {
    text-align: left;
    margin: 0;
  }

  h3 {
    padding-bottom: 10px;
    color: var(--action-primary);
    text-decoration: underline;
  }

  .heading {
    position: relative;
    margin-bottom: 30px;
    text-align: start;
    font-size: 1rem;
  }

  .heading::after {
    ${DashedUnderlineStyle}
    bottom: -16px;
  }

  .block {
    padding-bottom: 20px;
  }

  .underlined {
    text-decoration: underline;
  }
`;

export const StyledResult = styled.h4`
  position: relative;
  width: 100%;
  font-size: 1rem;

  text-align: left;
  margin: 0;

  ${(props) =>
    props.$isEdit &&
    css`
      padding-bottom: 20px;

      &::after {
        content: "(per minute of running time)";
        height: 3px;
        width: 100%;
        color: var(--sub-info-alt);
        width: 100%;
        display: block;
        position: absolute;
      }
    `}

  .value {
    color: var(--action-primary);
  }
`;

import React, { forwardRef, useEffect, useRef, useState } from "react";

import {
  StyledFormItem,
  StyledFormInput,
  StyledFormTextArea,
} from "./styles/InputElements.styled";

const FormInput = forwardRef(function (props, ref) {
  if (props.type === "text") {
    return <StyledFormTextArea ref={ref} {...props} />;
  }
  return <StyledFormInput ref={ref} {...props} />;
});

const FormItem = ({
  $label,
  $large,
  style,
  placeholder,
  initialValue,
  ...props
}) => {
  const formInputRef = useRef(null);

  const [isFocussed, setIsFocused] = useState(initialValue != undefined);
  const [isValid, setIsValid] = useState(initialValue != undefined);

  useEffect(() => {
    if (formInputRef.current && initialValue)
      formInputRef.current.value = initialValue;
  }, [formInputRef]);

  return (
    <StyledFormItem
      $focus={isFocussed}
      $valid={isValid}
      $label={$label}
      $large={$large}
      style={style}
    >
      <FormInput
        $large={$large}
        ref={formInputRef}
        placeholder={isFocussed ? placeholder : ""}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => {
          setIsValid(e.target.value != "");
        }}
        {...props}
      />
    </StyledFormItem>
  );
};

export const EmbeddedFormItem = forwardRef(function (
  { $width, style, ...props },
  ref
) {
  return (
    <form ref={ref}>
      <br />
      <FormItem
        $large={true}
        type="text"
        required
        style={{ ...{ width: $width }, ...style }}
        {...props}
      />
      <br />
    </form>
  );
});

export default FormItem;

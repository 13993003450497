import React from "react";

import { StyledFormOption } from "./styles/InputElements.styled";

const FormOption = ({
  children: text,
  value,
  name,
  inputType,
  selectedOption,
  setSelectedOption,
  ...props
}) => {
  return (
    <StyledFormOption>
      {text}
      <input
        value={value}
        onClick={(e) => setSelectedOption && setSelectedOption(value)}
        type={inputType || "radio"}
        // onChange & checked in combination mean for controlling manually. (defaultChecked alone would be automatic)
        onChange={() => setSelectedOption && setSelectedOption(value)}
        checked={selectedOption}
        name={name}
        {...props}
      />
    </StyledFormOption>
  );
};

export default FormOption;

import React from "react";
import { createGlobalStyle } from "styled-components";

import {
  StyledBackground,
  StyledTransparentBackground,
} from "../styles/Background.styled";
import { StyledCharacterBackgroundImg } from "../styles/CharacterBackgroundImg.styled";
import { StyledImgCover } from "../../_components/molecules/structure/styles/BlendImg.styled";

import { StyledOverlayBackground } from "_components/molecules/containers/overlays/styles/Overlay.styled";
import { StyledCenteredArea } from "../styles/common/CenteredArea.styled";

import { LargeX } from "components/styles/brand/XElements.styled";

const GlobalStyleOverride = createGlobalStyle`
	body {
		background-color: var(--main-primary-alt);
  }
`;

const FormPage = ({ children, $overflow }) => {
  return (
    <section
      style={{
        marginBottom: "300px",
        height: "1000px",
        width: "100%",
        position: "relative",
      }}
    >
      <GlobalStyleOverride />
      <LargeX $height="220%" src="/images/brand/large-x-alt.png" />

      <StyledCenteredArea>
        <StyledOverlayBackground
          $invisible
          $padded
          $centered
          $position="sticky"
          $overflow={$overflow}
        >
          {$overflow === "scroll" && <GlobalStyleOverride />}
          {children}
        </StyledOverlayBackground>
      </StyledCenteredArea>

      <StyledTransparentBackground
        height="200%"
        style={{ backgroundColor: "var(--main-primary-alt)", opacity: 0.65 }}
      />

      <StyledBackground
        $bgSize="fill"
        height="200%"
        src="/images/low-poly-grid-haikei.jpg"
      />

      <StyledCharacterBackgroundImg
        src="/images/tilted-left-salute-soldier.png"
        $left="-80px"
      />
      <StyledCharacterBackgroundImg
        src="/images/tilted-right-salute-soldier.png"
        $right="-80px"
      />
    </section>
  );
};

export default FormPage;

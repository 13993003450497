import React from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import FileManagerSystem from "components/sections/core/FileManagerSystem";

const FileManager = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
        <h1>File Manager</h1>
        <p>Receive and send files between your contacts</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <FileManagerSystem />
    </StyledStandardEndSection>
  );
};

export default FileManager;

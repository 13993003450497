import FaqItem from "_components/themes/faq/FaqItem";
import FaqWaveContainer from "_components/themes/faq/FaqWaveContainer";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const Faq = () => {
  return (
    <FaqWaveContainer>
      <FaqItem>
        <p className="header">
          Why can’t I just join when the games are released and reap the benefit
          instantly?
        </p>
        <div className="content">
          <p>
            <b>Competition</b>. Other partners also select our games to become a
            part-owner of them, and there are limited slots, usually less than
            you can count on one hand. That means that the games you see
            announced on our website, made available for selection to partners,
            which look the most appealing and promising to you, will also look
            appealing to other partners. And so, they will select the game
            quickly, and you will not be able to secure a spot unless someone
            cancels their involvement in the game in preference of selecting a
            different game instead.
          </p>
          {/* <p>
            It is also a feature of our website that you can only select a game
            after your account is at least 1 month old, and you paid for a
            minimum amount of edits/thumbnails in the last month. (This is not
            currently a thing, leave this paragraph out)
          </p> */}
          <p>
            To summarize, you must be <LinkSpan to="/join">registered</LinkSpan>{" "}
            and active with our company if you want to be notified for new game
            announcements, and be applicable for selecting our most anticipated
            games!
          </p>
        </div>
        <img className="icon" src="/images/faq-arrow.png" />
      </FaqItem>
    </FaqWaveContainer>
  );
};

export default Faq;

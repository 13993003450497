import React from "react";

import {
  StyledFormItem,
  StyledFormTextArea,
} from "./styles/InputElements.styled";

const MessageBox = ({ $label, value, style }) => {
  return (
    <StyledFormItem $valid={true} $label={$label} $large={true} style={style}>
      <StyledFormTextArea $large={true} value={value} disabled />
    </StyledFormItem>
  );
};

export default MessageBox;

import { LinkSpan } from "_components/atoms/inline/SpanElements";
import { StyledDocSection } from "_components/features/docs/main/Documentation.styled";

const Staff = () => {
  return (
    <>
      <StyledDocSection>
        <p className="title">Payment</p>
        <p>
          As a staff member, either <i>editor</i> or <i>thumbnail artist</i>,
          you get paid within <b>48 hours</b> after fulfilling each video
          request. We send the money directly to your <b>PayPal</b> account,
          which you can link to your account{" "}
          <LinkSpan to="/profile?tab=payment">here</LinkSpan>. If your payment
          is not received within that time, please contact us via either
          emailing 'contact@pushxpull.co' or chatting with us{" "}
          <LinkSpan to="/chat?user=admin">here</LinkSpan>. Make sure the PayPal
          account you link is correct, as we cannot get your money back if it is
          sent to the wrong account due to your mis-input!
        </p>
      </StyledDocSection>
    </>
  );
};

export default Staff;

import React, { useContext } from "react";
import axios from "axios";

import { StyledHoverFlagBtn } from "_components/atoms/buttons/styles/IconButtons.styled";

import { UploadContext } from "components/contexts/UploadContext";
import { ENDPOINT } from "js/data/constants";
import getUploadID from "js/getUploadID";
import getAccessToken from "js/features/auth/getAccessToken";

const RemoveBtn = ({ setIsUploading, setUploadProgress, progressUri }) => {
  const [[progressUris, setProgressUris], [fileDatas, setFileDatas]] =
    useContext(UploadContext);

  const finishUpload = async () => {
    setIsUploading(false);
    setUploadProgress(0);

    const updatedProgressUris = progressUris.filter(
      (uri) => uri !== progressUri
    );
    setProgressUris([...updatedProgressUris]);

    const newVideoNames = Object.fromEntries(
      Object.entries(fileDatas).filter(([uri]) => uri !== progressUri)
    );
    setFileDatas(newVideoNames);

    const uri = getUploadID(progressUri);

    axios.delete(`${ENDPOINT}/api/drive/remove/${uri}`, {
      headers: {
        Authorization: await getAccessToken(),
      },
    });
  };

  return (
    <StyledHoverFlagBtn $enabled onClick={finishUpload}>
      <div className="hover-flag">Remove</div>
      <img src="/images/buttons/bin-icon.png" />
    </StyledHoverFlagBtn>
  );
};

export default RemoveBtn;

import React, { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledSidebar,
  StyledSidebarText,
} from "_components/molecules/displays/styles/Sidebar.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

import Staff from "_components/features/docs/main/pp/Staff";
import Partner from "_components/features/docs/main/pp/Partner";

const PrivacyPolicy = () => {
  const [activeTab, setActiveTab] = useState("partner");

  return (
    <>
      <ParamTabSet $autoReset setTab={setActiveTab} />
      <StyledStandardEndSection>
        <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
          <h1>Documentation</h1>
          <p>
            <StyledHighlightedSpan>Privacy Policy</StyledHighlightedSpan>
          </p>
        </TopHeadingBanner>

        <StyledStandardSpacer $paddingTop="50px" />

        <StyledDualXStrip $only="pull">
          <StyledSbs
            $gap="50px"
            $align="start"
            $justify="start"
            style={{ padding: "50px" }}
          >
            <StyledSidebar $left $order="0" $yOrder="0" $width="300px">
              <StyledSidebarText
                $isActive={activeTab == "partner"}
                onClick={() => setActiveTab("partner")}
              >
                Partner
              </StyledSidebarText>
              <StyledSidebarText
                $isActive={activeTab == "staff"}
                onClick={() => setActiveTab("staff")}
              >
                Staff
              </StyledSidebarText>
            </StyledSidebar>
            <StyledSbs $width="100%" $align="start" $justify="start" $column>
              {activeTab == "staff" && <Staff />}
              {activeTab == "partner" && <Partner />}
            </StyledSbs>
          </StyledSbs>
        </StyledDualXStrip>
      </StyledStandardEndSection>
    </>
  );
};

export default PrivacyPolicy;

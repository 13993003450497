import React, { useEffect, useState, useRef } from "react";

import stickiesObserverHandler from "../../js/stickiesObserverHandler";

const ResponsiveStickiesHandler = ({
  windowQuery,
  setIsStickiesEnabled,
  refForObserver,
}) => {
  const [query, setQuery] = useState({});
  const disconnectObserverRef = useRef(null);

  const handleQuery = (props) => {
    if (props.matches && disconnectObserverRef.current) {
      disconnectObserverRef.current();
      disconnectObserverRef.current = null;
    } else if (!props.matches && !disconnectObserverRef.current) {
      disconnectObserverRef.current = stickiesObserverHandler(
        refForObserver,
        setIsStickiesEnabled
      );
    }

    setQuery(props);
  };

  useEffect(() => {
    handleQuery(windowQuery);
    windowQuery.addEventListener("change", (props) => {
      handleQuery(props);
    });

    return () => {
      if (disconnectObserverRef.current) {
        disconnectObserverRef.current();
        disconnectObserverRef.current = null;
      }
      windowQuery.removeEventListener("change", (props) => {
        handleQuery(props);
      });
    };
  }, []);

  useEffect(() => {
    if (disconnectObserverRef.current) return;
    setIsStickiesEnabled(!query.matches);
  }, [query]);

  return <></>;
};

export default ResponsiveStickiesHandler;

export type ErrorMessage = string | [string, any[]];

const formErrorMessages: { [messageId: string]: ErrorMessage } = {
  stmtfailed: "Internal server error.",
  nullrequest: "Missing information in request!",

  invaliduid: "Invalid username given!",
  username_forbidden: "Username not allowed! No numbers in beginning.",
  invalidemail: "Invalid email given!",
  usernametaken: "Username is taken!",
  max_accounts:
    "Maximum accounts reached on our site! Email us for a space at 'contact@pushxpull.co'",

  wronglogin: "Incorrect login details!",
  unverified_client: [
    "Your account is not verified! | Verify it here",
    [null, "/join/verify"],
  ],
  unverified_staff: [
    "Your account is not verified! | Verify it here",
    [null, "/join/return"],
  ],

  unpaid_thumbnails: [
    "You have not paid for any thumbnails in order to request one! | Manage Payments",
    [null, "/profile?tab=payment"],
  ],
  unpaid_edits: [
    "You have not paid for any edits in order to request one! | Manage Payments",
    [null, "/profile?tab=payment"],
  ],
  staff_unavailable: [
    "No staff currently available on your team to handle this request, so it failed! Try again when one of your staff members are 'available' by checking your | Team View",
    [null, "/team"],
  ],

  form_incomplete: "Please fill in the missing fields!",
};

// When information becomes available, this will be called and will inject the information into the static messages for future/instant use
export const messageInjections = {
  // Executed when an email or username becomes available, allowing identification of the user
  emailOrUsername: (emailOrUsername: string) => {
    const keyBasedOnParam = emailOrUsername.match(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
    )
      ? "email"
      : "username";

    // Predefined injection for designated location which I know it should be placed
    executeInjection(
      "unverified_staff",
      1,
      `/join/return?${keyBasedOnParam}=${emailOrUsername}`
    );
  },
};

function executeInjection(messageId: string, index: number, value: string) {
  const message = formErrorMessages[messageId];
  if (typeof message === "string") return;
  message[1][index] = value;
}

export default formErrorMessages;

import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";

import { StyledBottomNote } from "_components/features/file/styles/FileStruct.styled";

import { StyledTabbedScroller } from "components/objects/common/TabbedScroller";
import {
  StyledScrollContainer,
  StyledContentDisplay,
} from "components/styles/objects/containers/ScrollContainer.styled";

import FormSelect from "_components/features/form/elements/FormSelect";

import ProductionIncomingFile from "_components/features/file/download/collections/ProductionIncomingFile";
import FileViewer from "_components/features/file/displays/FileViewer";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { FriendsContext } from "_components/function/contexts/FriendsContext";

import fetchUnfulfilledVideos from "js/features/file/read/fetchUnfilfilledVideos";

const ProductionDownloadSystem = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const friends = useContext(FriendsContext);

  const [isLoading, setIsLoading] = useState(false);

  const [fileViewerVisible, setFileViewerVisible] = useState(false);
  const [currentData, setCurrentData] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");

  const [videoDatas, setVideoDatas] = useState([]);
  const [clientUsernames, setClientUsernames] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const getClientUsernames = () => {
    const newClientUsernames = friends
      .filter((user) => user.accountType === "Partner")
      .map((user) => user.name);
    setClientUsernames(newClientUsernames);
  };

  const autoOpenFileViewer = () => {
    const queryParams = new URLSearchParams(search);
    const fileId = queryParams.get("fileid");
    const username = queryParams.get("user");

    if (isLoading) return;

    if (!username || clientUsernames.length === 0) return;
    setSelectedUser(username);

    if (!fileId || videoDatas.length === 0) return;
    const matchData = videoDatas.find((data) => data.id === fileId);
    if (!matchData) return;
    // Open file viewer with matched file
    setCurrentData(matchData);
    setFileViewerVisible(true);
    navigate("/file");
  };

  useEffect(getClientUsernames, []);
  useEffect(autoOpenFileViewer, [isLoading, clientUsernames, videoDatas]);
  useEffect(() => {
    setVideoDatas([]);
    setIsLoading(true);

    fetchUnfulfilledVideos(selectedUser).then(({ videoDatas }) => {
      videoDatas = videoDatas.filter(
        (data) => data.staffName === ReactSession.get("useruid")
      );
      setVideoDatas(videoDatas);
      setIsLoading(false);
    });
  }, [selectedUser]);

  useEffect(() => {
    const file = videoDatas.find((data) => data.id === currentData.id);
    if (!file) return;
    const fileName = file.title;
    if (!fileName) return;
    setSelectedFileName(fileName);
  }, [currentData]);

  return (
    <>
      {fileViewerVisible && (
        <FileViewer
          username={selectedUser}
          title={selectedFileName}
          data={currentData}
          setVisible={setFileViewerVisible}
        />
      )}

      <FormSelect
        setSelection={setSelectedUser}
        optionsList={clientUsernames}
        placeholder="-- SELECT A USER --"
      />
      <StyledTabbedScroller
        style={{
          borderRadius: "10px 10px 0 0",
        }}
      >
        <StyledScrollContainer>
          <StyledContentDisplay $align="center">
            {videoDatas.map((data, index) => (
              <ProductionIncomingFile
                key={index}
                data={data}
                setVisible={setFileViewerVisible}
                setCurrentData={setCurrentData}
              />
            ))}
            <LoadingStatus status={isLoading ? 0 : videoDatas.length > 0} />
          </StyledContentDisplay>
        </StyledScrollContainer>
      </StyledTabbedScroller>

      <StyledBottomNote>
        <p style={{ color: "var(--exit-main)" }}>
          <b>Warning:</b> If you exit the <b>File Manager</b>, your downloads
          and uploads will be cancelled!
        </p>
      </StyledBottomNote>
    </>
  );
};

export default ProductionDownloadSystem;

import React from "react";

import {
  StyledOverlayBackground,
  GlobalStyleOverride,
} from "./styles/Overlay.styled";
import { StyledOverlayCard } from "_components/molecules/containers/overlays/styles/OverlayCard.styled";
import { StyledSelectScreen } from "_components/molecules/containers/overlays/styles/SelectScreen.styled";

import { StyledFormTitle } from "components/Form";
import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";

const PopupScreenView = ({
  $width,
  noScrollLock,
  setIsActive,
  info,
  children,
}) => {
  const handleExitClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) setIsActive(false);
  };

  return (
    <StyledOverlayBackground $centered onClick={handleExitClick}>
      {!noScrollLock && <GlobalStyleOverride />}
      <StyledSelectScreen $width={$width}>
        <StyledFormTitle
          $noMinHeight
          $width="70%"
          $bottom="0px"
          style={{ color: "var(--action-primary)", fontSize: "2rem" }}
        >
          {info.title}
        </StyledFormTitle>
        <StyledOverlayCard style={{ width: "100%" }}>
          {info.description}
        </StyledOverlayCard>
        {children.filter((element) => element.props?.name !== "button")}
        <div className="space-between">
          {children.map(
            (customButton) =>
              customButton.props?.name === "button" && customButton
          )}
          <ThinBtn onClick={handleExitClick} $primary="var(--main-secondary)">
            Close
          </ThinBtn>
        </div>
      </StyledSelectScreen>
    </StyledOverlayBackground>
  );
};

export default PopupScreenView;

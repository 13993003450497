import React from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import { DottedLinkText } from "_components/atoms/buttons/TextButtons";
import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

const Topics = () => {
  return (
    <StyledDualXStrip $col="var(--action-primary)" $only="pull">
      <StyledCenteredHeader>
        <h1>Admin Panel</h1>
        <p style={{ maxWidth: "580px" }}>
          Manage all systems and users at the top-level
        </p>
      </StyledCenteredHeader>

      <StyledSbs $align="center" $justify="center" $column>
        <DottedLinkText to="/admin/analytics">Analytics</DottedLinkText>
        <DottedLinkText to="/admin/manage">Manage</DottedLinkText>
        <DottedLinkText to="/admin/review">Review</DottedLinkText>
      </StyledSbs>
    </StyledDualXStrip>
  );
};

const Panel = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Documentation</h1>
        <p>
          Learn about all features, rules, guidelines, TOS and policies of our
          site
        </p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <Topics />
    </StyledStandardEndSection>
  );
};

export default Panel;

import React, { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledSidebar,
  StyledSidebarText,
} from "_components/molecules/displays/styles/Sidebar.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

import OwnershipBoostingDocs from "_components/features/docs/main/rag/OwnershipBoostingDocs";

const RulesAndGuidelines = () => {
  const [activeTab, setActiveTab] = useState("boosting");

  return (
    <>
      <ParamTabSet $autoReset setTab={setActiveTab} />
      <StyledStandardEndSection>
        <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
          <h1>Documentation</h1>
          <p>
            <StyledHighlightedSpan>Rules & Guidelines</StyledHighlightedSpan>
          </p>
        </TopHeadingBanner>

        <StyledStandardSpacer $paddingTop="50px" />

        <StyledDualXStrip $only="pull">
          <StyledSbs
            $gap="50px"
            $align="start"
            $justify="start"
            $defaultWrap
            $width="100%"
            style={{ padding: "50px" }}
          >
            <StyledSidebar $left $order="0" $yOrder="0" $minWidth="200px">
              <StyledSidebarText
                $isActive={activeTab == "boosting"}
                onClick={() => setActiveTab("boosting")}
              >
                Boosting
              </StyledSidebarText>
            </StyledSidebar>
            <StyledSbs
              $width="100%"
              $align="start"
              $justify="start"
              $column
              style={{ minWidth: "400px", flex: "1" }}
            >
              {activeTab == "boosting" && <OwnershipBoostingDocs />}
            </StyledSbs>
          </StyledSbs>
        </StyledDualXStrip>
      </StyledStandardEndSection>
    </>
  );
};

export default RulesAndGuidelines;

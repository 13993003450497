import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { StyledBottomNote } from "_components/features/file/styles/FileStruct.styled";
import {
  StyledSmushedTabContainer,
  StyledTabToolbar,
} from "components/styles/objects/common/TabElements.styled";
import { Tab, IconTab } from "components/objects/common/Tab";

import { StyledSbs } from "components/styles/SideBySide.styled";

import FileUpload from "./FileUpload";
import UploadHistory from "./UploadHistory";
import UnusedContent from "_components/features/file/displays/UnusedContent";

const UploadSystem = () => {
  const { search } = useLocation();

  const [currentTab, setCurrentTab] = useState(0);

  const tabJumpObserver = () => {
    const queryParams = new URLSearchParams(search);
    const tab = queryParams.get("tab_outgoing");
    if (tab) {
      setCurrentTab(parseInt(tab));
    }
  };

  useEffect(tabJumpObserver, [search]);

  return (
    <>
      <StyledSbs
        $gap="20px"
        $align="start"
        $justify="start"
        $column
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "var(--main-background)",
          padding: "50px",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <UnusedContent />

        <StyledTabToolbar>
          <StyledSmushedTabContainer>
            <Tab
              $index={0}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              src="/images/download-icon.png"
            >
              Upload & Request
            </Tab>
            <Tab
              $index={1}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              src="/images/download-icon.png"
            >
              Pending Requests
            </Tab>
          </StyledSmushedTabContainer>
        </StyledTabToolbar>

        {currentTab === 0 && <FileUpload />}
        {currentTab === 1 && <UploadHistory />}
      </StyledSbs>

      <StyledBottomNote>
        <p style={{ color: "var(--exit-main)" }}>
          <b>Warning:</b> If you exit the <b>File Manager</b>, your downloads
          and uploads will be cancelled!
        </p>
        <p>
          Your finished video will be returned in a set number of days based on
          your upload rate.
        </p>
        <p>
          You can find your finished videos under the <b>Incoming</b> tab above.
        </p>
      </StyledBottomNote>
    </>
  );
};

export default UploadSystem;

import axios from "axios";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const fetchClientTransactions = async () => {
  try {
    const res = await axios.get(`${ENDPOINT}/api/payment/transactions`, {
      headers: {
        Authorization: await getAccessToken(),
      },
    });
    return res.data;
  } catch (err) {
    console.error("Failed to fetch client transactions:", err);
  }
};

export default fetchClientTransactions;

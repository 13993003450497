import React from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import GameSelectionSystem from "_components/features/game/manager/GameSelectionSystem";
import KeyInformation from "_components/features/game/manager/KeyInformation";

const GameManager = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
        <h1>Game Manager</h1>
        <p>Select and manage games you are involved in</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <KeyInformation />

      <StyledStandardSpacer $paddingTop="400px" />
      <GameSelectionSystem />
    </StyledStandardEndSection>
  );
};

export default GameManager;

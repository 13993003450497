import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

import { StyledInfoStrip } from "_components/molecules/containers/common/styles/InfoStrip.styled";

import { LinkSpan } from "_components/atoms/inline/SpanElements";
import InfoPage from "_components/organisms/sections/InfoPage";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const CheckoutReturn = () => {
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();

  const getSessionStatus = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    axios
      .get(`${ENDPOINT}/api/payment/session-status?session_id=${sessionId}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        setStatus(res.data.status);
      });
  };

  useEffect(() => {
    getSessionStatus();
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <InfoPage $xStyle="alt">
        <StyledInfoStrip $align="start">
          <h1>Purchase Successful</h1>
          <p>
            You can review your number of purchased edits and thumbnails on your{" "}
            <LinkSpan to="/profile">Profile</LinkSpan>.
          </p>
          <p>
            You can activate your purchased edits and thumbnails by requesting
            their usage in the{" "}
            <LinkSpan to="/file?tab_outgoing=0&form=true">
              File Manager
            </LinkSpan>
            .
          </p>
          <p style={{ marginBottom: "30px" }}>
            If your remaining edits or thumbnails aren't updated yet, leave it
            for an hour and come back later. If it still won't show, consider
            messaging the{" "}
            {/* TODO: set user=supportteam when that user exists */}
            <LinkSpan to="/chat?user=b">Support Team</LinkSpan>.
          </p>
          <button onClick={() => navigate("/profile")}>Ok</button>
        </StyledInfoStrip>
      </InfoPage>
    );
  }

  return null;
};

export default CheckoutReturn;

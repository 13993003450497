import React from "react";

import GameCardOverlay from "_components/features/game/GameCardOverlay";

import { StyledGameSelectOption } from "components/styles/objects/coupled/GameSelectionElements.styled";
import { StyledTab } from "components/styles/objects/common/TabElements.styled";

const ViewBtn = ({ children: text, ...props }) => {
  return (
    <StyledTab type="radio" onClick={() => {}} {...props}>
      {text}
    </StyledTab>
  );
};

const GameSelectOption = ({
  status,
  activeCardIndex,
  setActiveCardIndex,
  index,
  data,
}) => {
  const isActive = activeCardIndex === index;

  return (
    <>
      {isActive ? (
        <GameCardOverlay
          setActiveCardIndex={setActiveCardIndex}
          data={data}
          status={status}
        />
      ) : null}
      <StyledGameSelectOption>
        <div className="info-stack">
          <p>Game Title</p>
          <h2>{data.game}</h2>
        </div>

        <div>
          <div className="info-stack" style={{ width: "80px" }}>
            <p>Status</p>
            <p
              style={{
                color: "white",
                fontWeight: status === "Selected" && "bold",
              }}
            >
              {status}
            </p>
          </div>
          <ViewBtn onClick={() => setActiveCardIndex(index)}>View</ViewBtn>
        </div>
      </StyledGameSelectOption>
    </>
  );
};

export default GameSelectOption;

import { styled } from "styled-components";

import { DashedUnderlineStyle } from "_components/atoms/inline/styles/Decorations.styled";

export const StyledProfileCard = styled.div`
  min-height: 500px;
  height: 100%;
  min-width: 450px;
  max-width: 450px;

  background-color: var(--main-background);

  border-radius: 20px;
  padding: 30px;
  flex-grow: 1;
  order: 1;
`;

export const StyledProfileHeader = styled.div`
  width: 100%;

  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;

  margin-bottom: 30px;

  &::after {
    ${DashedUnderlineStyle};
    --invis-col: #28283b;
    bottom: 0;
  }

  & > img {
    max-width: 120px;
  }
  & > h3 {
    color: #00a3ff;
    margin: 19px;
  }
`;

export const StyledProfileItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));

  gap: 30px;
  width: 100%;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledProfileItem = styled.div`
  width: 100%;
  background-color: var(--main-secondary);
  height: 100px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  grid-column: ${(props) => props.$fullWidth && "1 / -1"};

  border-radius: 10px;

  text-align: center;
  padding: 10px;

  & > h4 {
    margin: 0;
    font-size: 1rem;
  }

  & > h4:first-of-type {
    color: white;
  }

  & > h4:last-of-type {
    color: #00a3ff;
  }
`;

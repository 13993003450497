import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { StyledContentDisplay } from "components/styles/objects/containers/ScrollContainer.styled";

import PendingFile from "../download/PendingFile";
import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const UploadHistory = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadHistory, setUploadHistory] = useState([]);
  const isDataLoaded = useRef(null);

  const fetchSentData = async () => {
    // Only run once in application
    if (!isDataLoaded.current) {
      isDataLoaded.current = true; // Debounce
      setIsLoading(true);

      axios
        .get(
          `${ENDPOINT}/api/payment/fulfillment/requests?type=unfulfilled&management=true`,
          {
            headers: {
              Authorization: await getAccessToken(),
            },
          }
        )
        .then((res) => {
          setUploadHistory(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error while making the GET request:", error);
        });
    }
  };

  useEffect(() => {
    // Reset query
    navigate("/file");
    fetchSentData();
  }, []);

  return (
    <>
      <h2
        style={{
          color: "var(--action-primary)",
          width: "100%",
          textAlign: "center",
          margin: "0",
        }}
      >
        Production: Your Pending Content
      </h2>
      <StyledContentDisplay
        $align="center"
        $justify="start"
        style={{ minHeight: "200px" }}
      >
        {uploadHistory.map((data, index) => (
          <PendingFile key={index} data={data} />
        ))}
        <LoadingStatus status={isLoading ? 0 : uploadHistory.length > 0} />
      </StyledContentDisplay>
    </>
  );
};

export default UploadHistory;

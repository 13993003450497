import React from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import { StyledHoverFlagBtn } from "_components/atoms/buttons/styles/IconButtons.styled";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const PauseBtn = ({ setIsPaused, progressUri }) => {
  const pauseUpload = async () => {
    const uploadID = getUploadID(progressUri);
    axios
      .get(ENDPOINT + `/api/drive/pause/${uploadID}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then(() => {
        setIsPaused(true);
      })
      // Tried resume before upload started, do nothing
      .catch(console.log);
  };

  return (
    <StyledHoverFlagBtn $enabled onClick={pauseUpload}>
      <div className="hover-flag">Pause</div>
      <img src="/images/buttons/pause-icon.png" />
    </StyledHoverFlagBtn>
  );
};

export default PauseBtn;

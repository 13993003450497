import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { StyledInfoStrip } from "_components/molecules/containers/common/styles/InfoStrip.styled";

import InfoPage from "_components/organisms/sections/InfoPage";

import { ENDPOINT } from "js/data/constants";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const JoinVerify = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const receivedError = urlParams.get("error");
  const hash = urlParams.get("hash");

  useEffect(() => {
    if (hash) {
      axios
        .post(`${ENDPOINT}/api/auth/verify/hash`, { hash })
        // Redirect back to this page without token param to display 'verified' screen
        .then(() => navigate("/join/verify/return"))
        .catch(console.error);
    }
  }, []);

  if (receivedError === "not_eligible") {
    return (
      <InfoPage>
        <StyledInfoStrip $maxWidth="600px" $align="center">
          <h1>Not Eligible</h1>
          <p>
            Your youtube account doesn't meet the minimum subscriber count of{" "}
            <StyledHighlightedSpan>50,000</StyledHighlightedSpan> to join our
            company. Sorry!
          </p>
          <button onClick={() => navigate("/")}>Go Home</button>
        </StyledInfoStrip>
      </InfoPage>
    );
  }

  if (receivedError === "no_email") {
    return (
      <InfoPage>
        <StyledInfoStrip $maxWidth="600px" $align="start">
          <h1>Email Not Found!</h1>
          <p>
            No registered account in our system has an email address that
            matches the one you selected in the youtube verification process!
          </p>
          <p>
            If you did sign up but with a different email to the one your{" "}
            <StyledHighlightedSpan>preferred email</StyledHighlightedSpan>{" "}
            youtube account is linked to, then you can contact us to change your
            accounts email address to your{" "}
            <StyledHighlightedSpan>preferred email</StyledHighlightedSpan> via
            our <LinkSpan to="/contact">contact form</LinkSpan> (topic to select
            is <StyledHighlightedSpan>"Account Issue"</StyledHighlightedSpan>).
            Give us the email you want to change to and why and you will receive
            a success/failure response within the next 1-3 working days
          </p>
          <button onClick={() => navigate("/contact")}>Contact Us</button>
        </StyledInfoStrip>
      </InfoPage>
    );
  }

  /*
  if (receivedError === "wrong_email") {
    return (
      <InfoPage>
        <StyledInfoStrip $maxWidth="600px" $align="start">
          <h1>Wrong Email!</h1>
          <p>
            The email you chose to verify with links to an account which is
            already verified. This means the email is either taken by someone
            else, or you are already verified.
          </p>
          <p>
            If you try to login and it tells you to verify your account, it
            means you have setup your account with the wrong email and need it
            to be changed to the email you are trying to verify with.
          </p>
          <p>
            <b>Note:</b> The email you setup your account with <b>AND</b> the
            email you're trying to verify with <b>MUST MATCH</b>! Aka. The
            google account linked to your youtube channel is the email you
            should use for <StyledHighlightedSpan>signup</StyledHighlightedSpan>{" "}
            <b>AND</b>{" "}
            <StyledHighlightedSpan>verification</StyledHighlightedSpan>!
          </p>
          <p>
            To have your email on your account changed to the email you are
            trying to verify with, contact us via our{" "}
            <LinkSpan to="/contact">contact form</LinkSpan> (topic to select is{" "}
            <StyledHighlightedSpan>"Account Issue"</StyledHighlightedSpan>).
            Give us the email you want to change to and why and you will receive
            a success/failure response within the next 1-3 working days
          </p>
          <button onClick={() => navigate("/contact")}>Contact Us</button>
        </StyledInfoStrip>
      </InfoPage>
    );
  }
  */

  if (receivedError === "not_found") {
    return (
      <InfoPage>
        <StyledInfoStrip $maxWidth="600px" $align="start">
          <h1>Email Not Found!</h1>
          <p>
            The email you choose to verify with does not match any email on any
            account in our system. This means you did not signup with the same
            email as the email you tried to verify with! Your youtube account's
            email and PUSHXPULL email <b>MUST MATCH</b>!
          </p>
          <p>
            To have your email on your account changed to the email you are
            trying to verify with, contact us via our{" "}
            <LinkSpan to="/contact">contact form</LinkSpan> (topic to select is{" "}
            <StyledHighlightedSpan>"Account Issue"</StyledHighlightedSpan>).
            Give us the email you want to change to and why and you will receive
            a success/failure response within the next 1-3 working days
          </p>
          <button onClick={() => navigate("/contact")}>Contact Us</button>
        </StyledInfoStrip>
      </InfoPage>
    );
  }

  return (
    <InfoPage>
      <StyledInfoStrip $maxWidth="600px" $align="center">
        <h1>Account Verified!</h1>
        <p>You can now login to your account and use our services!</p>
        <button onClick={() => navigate("/login")}>Login</button>
      </StyledInfoStrip>
    </InfoPage>
  );
};

export default JoinVerify;

import React, { useState } from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ManyTab from "_components/atoms/navigation/ManyTab";

import Team from "_components/features/admin/manage/Team";

const panels = ["Team"];

const Manage = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <StyledStandardEndSection>
        <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
          <h1>Admin Panel</h1>
          <p style={{ color: "var(--action-primary)" }}>Manage</p>
        </TopHeadingBanner>

        <ManyTab
          panels={panels}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />

        {currentTab === 0 && <Team />}
      </StyledStandardEndSection>
    </>
  );
};

export default Manage;

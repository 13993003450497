import Games from "../sections/Games";

import { BlendImg } from "../BlendElements";

import { StyledBackground } from "../styles/Background.styled";
import { StyledAspectRatioWrapper } from "../styles/AspectRatioContainer.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

const ContentSections = () => {
  return (
    <section
      style={{
        position: "relative",
        // Index is set higher than all other sections so 'GameCardOverlay' is on top of everyting
        zIndex: "1",
      }}
    >
      <StyledAspectRatioWrapper $maxHeight="475px" style={{ zIndex: 1 }}>
        <BlendImg
          $aspectRatio="4:1"
          src="/images/layered-peaks-haikei-content.png"
        />
      </StyledAspectRatioWrapper>

      <StyledStandardSpacer />

      <Games />

      {/* Sizes itself same as section */}
      <StyledBackground
        $layer="0"
        src="/images/low-poly-grid-haikei.jpg"
        style={{ height: "100%", width: "100%", zIndex: -15 }}
      />
    </section>
  );
};

export default ContentSections;

import React from "react";
import styled from "styled-components";

import { StyledLineInfoBlock } from "./styles/StyledInfoBlock.styled";

const StyledFloatingInfoBlock = styled.div`
  background-color: var(--main-secondary);
  padding: min(50px, 10%);
  border: solid 1px var(--action-primary);
  border-radius: 10px;

  margin: ${(props) =>
    props.$justify === "start"
      ? "0 0 0 min(10%, 100px)"
      : props.$justify === "end"
      ? "0 min(10%, 100px) 0 0"
      : ""};
  width: ${(props) => props.$width};

  p {
    color: white;
    margin: 0;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.$justify || "center"};
  width: 100%;
`;

const FloatingInfoBlock = ({ children, $col, $gap, $justify, ...props }) => {
  return (
    <StyledWrapper $justify={$justify}>
      <StyledFloatingInfoBlock $justify={$justify} {...props}>
        <StyledLineInfoBlock
          $colBack="var(--main-secondary)"
          $col={$col}
          $maxWidth="unset"
          $gap={$gap}
        >
          {children}
        </StyledLineInfoBlock>
      </StyledFloatingInfoBlock>
    </StyledWrapper>
  );
};

export default FloatingInfoBlock;

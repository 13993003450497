export const learnContents = {
  ["Business Model"]: {
    ["Pricing"]: ["/pricing", "How our pricing system works"],
    ["Game Benefits"]: [
      "/benefits/game",
      "Everything about the benefits you get from our games",
    ],
    ["Production Benefits"]: [
      "/benefits/production",
      "Everything about the benefits from working with our production teams",
    ],
  },
  ["Specific Learning"]: {
    ["Contact Us"]: ["/contact", "Contact us about a specific issue"],
    ["Documentation"]: [
      "/docs",
      "Learn about all features, rules, guidelines, TOS and policies of our site",
    ],
  },
};

export const exploreContents = {
  ["Useful Pages"]: {
    ["Home"]: ["/", "Overview of our company"],
    ["Content"]: [
      "/content",
      "View work from skilled teams associated with our company",
    ],
  },
  ["Alternative Joining"]: {
    ["Join Production"]: [
      "/production",
      "For video editors & thumbnail artists interested in joining the production teams",
    ],
  },
};

const accountContents = {
  ["Account"]: {
    ["Profile & Settings"]: [
      "/profile",
      "View your profile stats, edit your settings, manage billing..",
    ],
    ["Logout"]: ["/logout", "Logout of your account"],
  },
};

export const getProfileContents = (accountType) => {
  const isProduction = accountType === "Production";
  const isAffiliate = accountType === "Affiliate";
  const isPartner = accountType === "Partner";
  const isAdmin = accountType === "Admin";

  if (isAdmin) {
    return {
      ["Management"]: {
        ["Admin Panel"]: [
          "/admin/panel",
          "Manage all systems and users at the top-level",
        ],
        ["Chat"]: ["/chat", "Message teams and individuals across our company"],
      },

      ...accountContents,
    };
  }

  return {
    ...(!isProduction && {
      ["Game Involvement"]: {
        ["Game Manager"]: [
          "/game/manager",
          "Select and manage games you are involved in",
        ],
        ["Ownership Booster"]: [
          "/game/booster",
          "Increase your ownership % and profile stats",
        ],
      },
    }),

    ...(!isAffiliate && {
      ["Utilities"]: {
        ["Chat"]: ["/chat", "Message teams and individuals across our company"],

        ["File Manager"]: [
          "/file",
          "Receive and send files between your contacts",
        ],

        ...(isProduction
          ? {
              ["Scheduler"]: [
                "/scheduler",
                "Co-ordinate your workflow with your teams",
              ],
            }
          : {
              ["Team View"]: ["/team", "View the status of your team members"],
            }),
      },
    }),

    ...accountContents,
  };
};

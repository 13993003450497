import React from "react";

import {
  StyledImgStrip,
  StyledTopBanner,
} from "../../styles/StripBanner.styled";
import { StyledCenteredHeader } from "../../styles/common/Headings.styled";

const TopHeadingBanner = ({ $pos, src, children }) => {
  return (
    <>
      <StyledTopBanner>
        <StyledImgStrip $pos={$pos} src={src} />
      </StyledTopBanner>

      <StyledCenteredHeader style={{ margin: "40px 0" }}>
        {children}
      </StyledCenteredHeader>
    </>
  );
};

export default TopHeadingBanner;

import { ReactSession } from "react-client-session";

const login = (params) => {
  ReactSession.set("userrblx", params.get("userrblx"));
  ReactSession.set("usertarget", params.get("usertarget"));
  ReactSession.set("userownership", params.get("userownership"));
  ReactSession.set("usereditors", params.get("usereditors"));
};

const logout = async () => {
  ReactSession.remove("userrblx");
  ReactSession.remove("usertarget");
  ReactSession.remove("userownership");
  ReactSession.remove("usereditors");
};

export default {
  login,
  logout,
};

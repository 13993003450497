import styled, { css, keyframes } from "styled-components";

import {
  btnStyle,
  btnHoverStyle,
  btnInactiveStyle,
} from "_components/atoms/buttons/styles/IconButtons.styled";
import { thinBtnStyle } from "_components/atoms/buttons/styles/ThinButtons.styled";

const loadingBarAnim = keyframes`
  0% {
    background-position: 300% 0;
  }

  100% {
    background-position: 0 0;
  }
`;

// TODO: Change to two separate btns in importer module for 'StyledDownloadBtn'. (isDownloading ? <StyledHoverFlagBtn /> : <StyledDownloadBar />)
export const StyledDownloadBtn = styled.a`
  ${btnStyle}

  ${(props) => (!props.$isDownloading ? btnHoverStyle : btnInactiveStyle)}

${(props) =>
    props.$isDownloading &&
    css`
      display: flex;
      align-items: center;
      justify-content: start;

      .progress-bar {
        display: flex;
        align-items: center;
        justify-content: start;

        content: "";
        display: block;
        position: relative;
        background-color: var(--main-secondary-alt);
        height: 5px;
        width: 100px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          background-color: var(--action-primary);
          height: 100%;
          width: ${(props) =>
            `${props.$downloadProgress !== -1 && props.$downloadProgress}%`};
        }

        ${(props) =>
          props.$downloadProgress === -1 &&
          css`
            &::after {
              content: "";
              display: block;
              position: absolute;
              height: 100%;
              width: 100%;
              opacity: 1;

              background-size: 300%;
              background-image: linear-gradient(
                to right,
                var(--main-secondary-alt),
                var(--main-secondary-alt),
                var(--main-secondary-alt),
                var(--action-primary),
                var(--main-secondary-alt),
                var(--main-secondary-alt),
                var(--main-secondary-alt)
              );

              animation: 5s linear infinite ${loadingBarAnim};
            }
          `}
      }

      img {
        display: none;
      }
    `}
`;

export const StyledIconBtn = styled.a`
  ${btnStyle}
  ${btnHoverStyle}
  ${(props) => !props.$enabled && btnInactiveStyle}
`;

export const StyledStaticIconBtn = styled.a`
  ${btnStyle}
  ${btnHoverStyle}
`;

import React from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import Topics from "_components/features/docs/main/Topics";

const Documentation = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Documentation</h1>
        <p>
          Learn about all features, rules, guidelines, TOS and policies of our
          site
        </p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <Topics />
    </StyledStandardEndSection>
  );
};

export default Documentation;

import React, { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledSidebar,
  StyledSidebarText,
} from "_components/molecules/displays/styles/Sidebar.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

import Revisions from "_components/features/docs/main/tos/Revisions";
import Rating from "_components/features/docs/main/tos/Rating";

const TermsOfService = () => {
  const [activeTab, setActiveTab] = useState("revisions");

  return (
    <>
      <ParamTabSet $autoReset setTab={setActiveTab} />
      <StyledStandardEndSection>
        <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
          <h1>Documentation</h1>
          <p>
            <StyledHighlightedSpan>Terms of Service</StyledHighlightedSpan>
          </p>
        </TopHeadingBanner>

        <StyledStandardSpacer $paddingTop="50px" />

        <StyledDualXStrip $only="pull">
          <StyledSbs
            $gap="50px"
            $align="start"
            $justify="start"
            style={{ padding: "50px" }}
          >
            <StyledSidebar $left $order="0" $yOrder="0" $width="300px">
              {/* <StyledSidebarText
                $isActive={activeTab == "rating"}
                onClick={() => setActiveTab("rating")}
              >
                Rating
              </StyledSidebarText> */}
              <StyledSidebarText
                $isActive={activeTab == "revisions"}
                onClick={() => setActiveTab("revisions")}
              >
                Revisions
              </StyledSidebarText>
            </StyledSidebar>
            <StyledSbs $width="100%" $align="start" $justify="start" $column>
              {/*
              // TODO: Re-enable when rating system is fully implemented
              {activeTab == "rating" && <Rating />}
              */}
              {activeTab == "revisions" && <Revisions />}
            </StyledSbs>
          </StyledSbs>
        </StyledDualXStrip>
      </StyledStandardEndSection>
    </>
  );
};

export default TermsOfService;

import React from "react";

import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";
import { StyledArrowIndentP } from "_components/atoms/inline/styles/Decorations.styled";
import { StyledXStripHeader } from "_components/themes/brand/XStrip.styled";

import FloatingInfoBlock from "_components/molecules/containers/common/FloatingInfoBlock";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

import { ScrollElement } from "_components/atoms/inline/SpanElements";

const GameProfit = () => {
  return (
    <>
      <StyledXStripHeader />

      <FloatingInfoBlock
        $width="min(80%, 850px)"
        $justify="end"
        $gap="20px"
        $col="var(--action-primary)"
      >
        <h3>
          <StyledHighlightedSpan>Benefit #2 : </StyledHighlightedSpan>Game
          Profit Earning
        </h3>

        <div className="no-flex">
          <p>
            <StyledHighlightedSpan>Summary</StyledHighlightedSpan>
          </p>
          <p>
            For every game you select to be involved in, you will be entitled to
            a percentage of ownership from each game based on the{" "}
            <ScrollElement to="factors" inline>
              Factors
            </ScrollElement>{" "}
            mentioned later and the scale at which you utilized them.
          </p>
        </div>

        <p>
          Having a percentage of ownership from a game means that when a player
          purchases a game-pass, makes an in-game purchase or purchases access
          to the game, you will then receive that percentage out of the profit
          we receive from that transaction.
        </p>

        <div className="no-flex">
          <p>
            <StyledHighlightedSpan>Functionality</StyledHighlightedSpan>
          </p>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            The percentage ownership you gained entitlement to in a particular
            month from your efforts toward the{" "}
            <ScrollElement to="factors" inline>
              Factors
            </ScrollElement>
            , return you that percentage of the game's profits, for the next
            month, not the current one. This means whatever percentage you are
            increasing or are sustaining in the month, is for the next month's
            benefit. This system just makes sense from a technical perspective.
          </StyledArrowIndentP>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            To gain a higher percentage you must satisfy the{" "}
            <ScrollElement to="factors" inline>
              Factors
            </ScrollElement>{" "}
            at a higher capacity.
          </StyledArrowIndentP>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            Your percentage ownership decreases slowly over the course of the
            month. This means you must satisfy the{" "}
            <ScrollElement to="factors" inline>
              Factors
            </ScrollElement>{" "}
            to at least a minimum capacity to sustain your percentage for the
            next month. The higher percentage you own, the higher capacity you
            must a act toward the{" "}
            <ScrollElement to="factors" inline>
              Factors
            </ScrollElement>{" "}
            to sustain it.
          </StyledArrowIndentP>
          <StyledArrowIndentP $col="var(--action-primary)" $indent="30px">
            You only keep your percentage ownership across all games if you have
            met the{" "}
            <ScrollElement to="requirements" inline>
              Requirements
            </ScrollElement>{" "}
            listed above. (Reviewed at the end of each month)
          </StyledArrowIndentP>
        </div>

        <div className="no-flex">
          <p>
            <StyledHighlightedSpan>Example Scenarios</StyledHighlightedSpan>
          </p>
          <p>
            To gain a perspective, if we turned over R$100,000 (Robux) a month
            from one game (avg. of 500 con-current players), and if you owned
            just 10% of that, you would receive R$ 10,000. All with practically
            zero effort from you, holding no risk which we do hold, and without
            having a single skill toward game development, you own as much of
            the game as a full-time developer working on it.
          </p>
        </div>

        <p>
          If that wasn't convincing enough, you can be involved in multiple
          games at once. So if you were involved 3 of our games, with just 500
          con-current players in each (which is extremely easy to obtain with
          our funded advertising etc.), holding 10% ownership of each (30% total
          ownership across all games). You could receive R$ 30,000 a month.
        </p>

        <div className="no-flex">
          <p>
            <StyledHighlightedSpan>Conclusion</StyledHighlightedSpan>
          </p>
          <p>
            If that doesn't seem to be a lot to you (even though 80% of
            full-time developers work for less than that), that was just an
            example of 3 games which didn't do as well as we wanted (500
            con-current players). Just imagine the amount you will be receiving
            when involved in successful games of ours, front-page games, and
            imagine even multiple of these! Imagine earning R$ 50,000 to R$
            150,000 a month between however many games (could cash that out into
            $525 if you wanted). All of that from simply choosing our trained
            production teams to help produce your videos instead of random
            freelancers (or by joining us through other means).
          </p>
        </div>
      </FloatingInfoBlock>
    </>
  );
};

export default GameProfit;

import { css } from "styled-components";

export const ReversedStyle = css`
  & > :first-child {
    order: 2;
  }

  & > :last-child {
    order: 1;
  }
`;

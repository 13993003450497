import React from "react";

import SettingBlock from "../SettingBlock";

import ConnectedAccounts from "../blocks/ConnectedAccounts";

const ProductionSettings = () => {
  return (
    <>
      <SettingBlock
        $height="180px"
        $padding="0"
        $flex
        title="Connected Accounts"
      >
        <ConnectedAccounts />
      </SettingBlock>
    </>
  );
};

export default ProductionSettings;

import React from "react";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import { DottedLinkText } from "_components/atoms/buttons/TextButtons";

const Topics = () => {
  return (
    <StyledDualXStrip $col="var(--action-primary)" $only="pull">
      <StyledCenteredHeader>
        <h1>Topics</h1>
        <p style={{ maxWidth: "580px" }}>
          Select the topic you want to learn about
        </p>
      </StyledCenteredHeader>

      <StyledSbs $align="center" $justify="center" $column>
        <DottedLinkText to="/docs/rules-and-guidelines">
          Rules & Guidelines
        </DottedLinkText>
        <DottedLinkText to="/docs/terms-of-service">
          Terms of Service
        </DottedLinkText>
        <DottedLinkText to="/docs/privacy-policy">
          Privacy Policy
        </DottedLinkText>
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default Topics;

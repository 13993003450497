import React from "react";

import { StyledStaticIconBtn } from "_components/features/file/styles/Buttons.styled";

import { useNavigate } from "react-router-dom";

const ChatBtn = ({ fileId, user }) => {
  const navigate = useNavigate();

  const handleSelect = (event) => {
    event.preventDefault();
    navigate(`/chat?user=${user}`);
  };

  return (
    <StyledStaticIconBtn $flagWidth="100px" onClick={handleSelect}>
      <div className="hover-flag">Chat</div>
      <img src="/images/buttons/message-icon.png" />
    </StyledStaticIconBtn>
  );
};

export default ChatBtn;

import React from "react";

import {
  StyledFlexbox,
  StyledPairDisplay,
} from "components/styles/SideBySide.styled";

import { StyledSplitSection } from "_components/organisms/SplitPage";

import { StyledBasicCard } from "_components/molecules/containers/common/styles/BasicCard.styled";

const SplitCard = ({ title, data = {}, children: buttons }) => {
  return (
    <StyledBasicCard>
      <h2>{title}</h2>

      <StyledSplitSection $noX $xOffset="-30px" $cols="2" $gap="10px">
        {Object.entries(data).map(([key, value]) => (
          <StyledPairDisplay $wrap>
            <p>{key}</p>
            <p>{value}</p>
          </StyledPairDisplay>
        ))}
      </StyledSplitSection>

      {buttons && (
        <StyledFlexbox
          $width="100%"
          $justify="space-between"
          $row
          style={{ marginTop: "30px" }}
        >
          {buttons}
        </StyledFlexbox>
      )}
    </StyledBasicCard>
  );
};

export default SplitCard;

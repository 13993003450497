import styled, { css } from "styled-components";

export const thinBtnStyle = css`
  --bg: var(--main-primary);

  color: white;
  background-color: var(--bg);
  position: relative;

  min-width: 150px;
  height: 30px;

  border-radius: 5px;
  border: none;

  opacity: ${(props) => (props.$inactive ? 0.4 : 1)};
  cursor: ${(props) => (props.$inactive ? "auto" : "pointer")};

  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const StyledThinBtn = styled.button`
  ${thinBtnStyle}

  --bg: ${(props) => props.$primary};
`;

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { LargeX } from "components/styles/brand/XElements.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledInfoStrip } from "_components/molecules/containers/common/styles/InfoStrip.styled";

import { ENDPOINT } from "js/data/constants";

const JoinVerify = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const verifyUser = () => {
    const urlParams = new URLSearchParams(search);
    const hash = urlParams.get("hash");

    // Hash verification
    if (hash) {
      axios
        .post(`${ENDPOINT}/api/auth/verify/hash`, { hash: hash })
        .then((res) => {
          console.log(res);
          navigate("/login");
        })
        .catch(console.error);
    } else {
      // Auth verification
      axios
        .post(`${ENDPOINT}/api/auth/youtube`)
        .then((res) => {
          console.log(res);
          const location = res.data.url;
          if (location) {
            window.location.href = location;
          }
        })
        .catch(console.error);
    }
  };

  return (
    <section
      style={{
        marginBottom: "300px",
        height: "1000px",
        width: "100%",
        position: "relative",
      }}
    >
      <LargeX $height="220%" src="/images/brand/large-x.png" />
      <StyledCenteredArea>
        <StyledInfoStrip
          $col="var(--main-primary)"
          $maxWidth="600px"
          $align="center"
        >
          <h1>Authorize Account</h1>
          <p>
            Press 'authorize' to verify your identity and gain access into your
            account.
          </p>
          <button onClick={verifyUser}>Authorize</button>
        </StyledInfoStrip>
      </StyledCenteredArea>
    </section>
  );
};

export default JoinVerify;

import axios from "axios";

import Reviewer from "./Reviewer";

import { ENDPOINT } from "js/data/constants";

import getAccessToken from "js/features/auth/getAccessToken";

const onConfirm = async (state, data) => {
  axios
    .patch(
      `${ENDPOINT}/api/payment/fulfillment/time`,
      {
        id: data.RequestId,
        state: state === 1 ? "Accepted" : "Declined",
      },
      {
        headers: {
          Authorization: await getAccessToken(),
        },
      }
    )
    .catch(console.error);
};

const TimeOffRequests = () => {
  return (
    <Reviewer
      itemAlias="Time Off Request"
      pendingUri={`${ENDPOINT}/api/user/data?type=column&db=time_off_requests&column=State&value='Pending'`}
      searchDatabase="time_off_requests"
      searchColumn="State"
      searchValues={["'Accepted'", "'Declined'", "'Expired'"]}
      onConfirm={onConfirm}
    />
  );
};

export default TimeOffRequests;

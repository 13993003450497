import React from "react";
import styled from "styled-components";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";

import { ArrowIndentStyle } from "_components/atoms/inline/styles/Decorations.styled";
import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";

export const StyledInfoStack = styled.div`
  h3 {
    color: ${(props) => props.$col || "var(--main-primary)"};
    /* TODO: Temp until globally scalled up */
    font-size: 26px;
    margin: 0;
  }

  .block {
    align-self: start;
  }

  .indented {
    margin-left: 20px;

    p:not(.no-arrow):not(.sub-header) {
      ${ArrowIndentStyle}
    }
  }

  .sub-header {
    color: ${(props) => props.$col};
    font-weight: 400;
  }

  p {
    color: white;
    margin: 0;
  }

  p:not(.no-arrow):not(.sub-header) {
    ${ArrowIndentStyle}
  }
`;

const InfoStack = ({ children, $title, $col, ...props }) => {
  return (
    <StyledInfoStack $col={$col}>
      <StyledDualXStrip $col={$col} {...props}>
        <StyledSbs $centered $column $gap="20px" $width="min(80%, 700px)">
          <StyledSectionH2>{$title}</StyledSectionH2>
          {children}
        </StyledSbs>
      </StyledDualXStrip>
    </StyledInfoStack>
  );
};

export default InfoStack;

import styled from "styled-components";

import { thinBtnStyle } from "_components/atoms/buttons/styles/ThinButtons.styled";

export const StyledSelectBtn = styled.button`
  ${thinBtnStyle}

  width: min(350px, 50%);
  margin-bottom: 60px;

  opacity: ${(props) => (props.$selected ? 0.4 : 1)};
  cursor: ${(props) => (props.$selected ? "auto" : "pointer")};
`;

import React from "react";

const LoadingStatus = ({ status, ...props }) => {
  if (status === true) {
    return <></>;
  }

  return (
    <p {...props}>
      <i>
        {status === 0 ? "Loading.." : status === false ? "No data." : "Error"}
      </i>
    </p>
  );
};

export default LoadingStatus;

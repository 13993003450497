const options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.01,
};

const stickiesObserverHandler = (ref, setIsStickiesEnabled) => {
  const observer = new IntersectionObserver((entries) => {
    const [entry] = entries;
    setIsStickiesEnabled(!entry.isIntersecting);
  }, options);
  if (ref.current) observer.observe(ref.current);

  return () => {
    if (ref.current) observer.unobserve(ref.current);
  };
};

export default stickiesObserverHandler;

import React, { useState } from "react";
import { styled, css } from "styled-components";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledCta } from "components/CtaElements";

const ActiveLabelPositioningStyle = css`
  /* top: -50%; */
  font-size: 1rem;
  transform: translateY(-200%);
  padding-left: 0;
`;

export const StyledFormContainer = styled.form`
  --rows: ${(props) => props.$rows};

  position: relative;
  width: 650px;
  border-radius: 50px;

  align-self: ${(props) => props.$scroll && "start"};

  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 30px;

  background-color: rgb(24, 24, 40);

  /* grid-template-rows: 1.6fr repeat(var(--rows), 50px) 1fr; */
  grid-template-columns: 1fr 1fr;

  height: auto;
  padding: max(50px, min(3.5%, 70px));

  min-width: 500px;

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    width: 100%;
    min-width: unset;
    background-color: rgb(24, 24, 40, 0.92);
  }
`;

export const StyledFormItem = styled.div`
  --input-text-size: 0.95rem;

  /* padding: 20px 10px; */
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  grid-column: 1 / -1;

  min-height: ${(props) => (props.$large ? "200px" : "50px")};

  ${(props) =>
    props.$large &&
    css`
      justify-content: start;
    `}

  & > label {
    color: #8989b1;
  }

  &::before {
    display: flex;
    align-items: center;
    justify-content: start;

    pointer-events: none;
    user-select: none;

    color: #8989b1;
    font-size: var(--input-text-size);
    padding-left: 10px;

    position: absolute;
    z-index: 1;

    transition: all 0.1s ease-in-out;

    content: ${({ $label }) => `"${$label}"`};

    ${({ $focus, $valid }) => {
      if ($focus) {
        return css`
          ${ActiveLabelPositioningStyle};
          color: #00b1ff;
        `;
      } else if ($valid) {
        return css`
          ${ActiveLabelPositioningStyle};
        `;
      }
    }};
  }
`;

export const StyledFormInput = styled.input`
  background-color: #2d2d46;
  border-radius: 10px;
  border-style: none;

  color: #e7e7f8;
  font-size: var(--input-text-size);

  height: 100%;
  width: 100%;
  position: relative;
  bottom: 0;

  padding: 0 15px;

  &::placeholder {
    color: #4d4d64;
  }
`;

export const StyledFormTitle = styled.h1`
  position: relative;
  display: block;
  color: white;
  margin: 0;
  padding-bottom: 20px;
  height: 100%;
  min-height: ${(props) => props.$noMinHeight || "80px"};
  width: 100%;
  grid-column: 1 / -1;
  font-size: 2.5rem;
  font-weight: 100;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  &::after {
    content: "";
    height: 2px;
    width: ${(props) => props.$width || "70%"};
    display: block;
    position: absolute;
    background-color: #009cff;

    ${(props) => {
      if (props.$top && !props.$bottom) {
        return css`
          top: ${props.$top};
        `;
      } else if (props.$bottom && !props.$top) {
        return css`
          bottom: ${props.$bottom};
        `;
      } else {
        // Keep default to not break code in other places
        return css`
          top: 4.5rem;
        `;
      }
    }}
  }
`;

export const StyledFormOptions = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  grid-column: 1 / -1;
`;

const StyledFormOption = styled.label`
  --radius: 10px;

  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;

  color: #8989b1;

  & > input {
    position: relative;
    cursor: pointer;
    appearance: none;
    background-color: #2d2d46;
    border-radius: var(--radius);
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      border-radius: var(--radius);
      position: absolute;
      height: 70%;
      width: 70%;
      background-color: #0184ff;

      transition: all 0.2s ease-in-out;
      opacity: 0;
    }

    &:checked::before {
      opacity: 1;
    }
  }
`;

export const StyledFormNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #8989b1;
  grid-column: 1 / -1;
  position: absolute;
  top: 0;
  transform: translateY(calc(-10px - 100%));
  text-align: center;
`;

export const FormItem = ({ $label, $large, style, placeholder, ...props }) => {
  const [isFocussed, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(false);

  return (
    <StyledFormItem
      $focus={isFocussed}
      $valid={isValid}
      $label={$label}
      $large={$large}
      style={style}
    >
      <StyledFormInput
        $large={$large}
        placeholder={isFocussed ? placeholder : ""}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => setIsValid(e.target.value != "")}
        {...props}
      />
    </StyledFormItem>
  );
};

export const FormCta = ({ children: value, note }) => {
  return (
    <StyledCenteredArea style={{ gridColumn: "1 / -1" }}>
      <StyledFormNote>{note}</StyledFormNote>
      <StyledCta>
        <input
          className="txt"
          type="submit"
          value={value}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "unset",
            borderStyle: "none",
          }}
        />
        <div className="box" />
      </StyledCta>
    </StyledCenteredArea>
  );
};

export const FormOption = ({
  children: text,
  value,
  name,
  inputType,
  setSelectedOption,
  ...props
}) => {
  return (
    <StyledFormOption>
      {text}
      <input
        onClick={() => setSelectedOption && setSelectedOption(value)}
        type={inputType || "radio"}
        value={value}
        name={name}
        {...props}
      />
    </StyledFormOption>
  );
};

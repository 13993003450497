import { useNavigate, useLocation } from "react-router-dom";

import {
  StyledExpandElement,
  StyledExpandCollection,
  StyledExpandHeading,
  StyledNavExpand,
} from "../../styles/NavbarElements.styled";

const ExpandElement = ({ title, desc, to }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <StyledExpandElement
      $isActive={pathname === to ? true : false}
      onClick={() => navigate(to)}
    >
      <h2>{title}</h2>
      <p>{desc}</p>
    </StyledExpandElement>
  );
};

export const NavExpand = ({ children: name, contents, originAt, ...props }) => {
  const expandCollections = [];

  for (const [sectionTitle, pagesData] of Object.entries(contents)) {
    const sectionEntries = [];
    for (const [pageTitle, pageData] of Object.entries(pagesData)) {
      const to = pageData[0];
      const desc = pageData[1];
      sectionEntries.push(
        <ExpandElement key={pageTitle} title={pageTitle} desc={desc} to={to} />
      );
    }

    expandCollections.push(
      <StyledExpandCollection key={sectionTitle}>
        <StyledExpandHeading>{sectionTitle}</StyledExpandHeading>
        {sectionEntries}
      </StyledExpandCollection>
    );
  }

  return (
    <StyledNavExpand $originAt={originAt} {...props}>
      {name}
      <div className="container">
        <div className="triangle"></div>
        <div className="box">{expandCollections}</div>
      </div>
    </StyledNavExpand>
  );
};

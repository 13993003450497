import React from "react";
import { useNavigate } from "react-router-dom";

import { StyledReferenceFile } from "../styles/ChatElements.styled";

const ReferenceFile = ({ $referenceFileId }) => {
  const navigate = useNavigate();

  const redirectUser = () => {
    navigate(`/file?tab=0&fileid=${$referenceFileId}`);
  };

  return (
    <StyledReferenceFile onClick={redirectUser}>
      Comment from video: <span>{$referenceFileId}</span>
    </StyledReferenceFile>
  );
};

export default ReferenceFile;

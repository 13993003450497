import React, { useContext, useEffect, useState } from "react";

import {
  StyledChatSidebar,
  StyledChatGroup,
  GroupHeading,
  GroupTab,
} from "../styles/ChatSidebar.styled";

function onUserUpdated(users, updatedUser) {
  const existUser = users.find((user) => user.name === updatedUser.name);
  if (!existUser) return;
  // Only update new data
  const nextUsers = users.map((user) =>
    user.name === existUser.name ? { ...user, ...updatedUser } : user
  );
  return nextUsers;
}

const ChatSidebar = ({
  socket,
  selectedState: [selectedUser, setSelectedUser],
  userState: [users, setUsers],
}) => {
  const [groupTabs, setGroupTabs] = useState({});

  const userUpdateObserver = () => {
    if (socket) {
      socket.on("updateUser", (updatedUser) =>
        onUserUpdated(users, updatedUser)
      );
    }

    return () => {
      if (socket) {
        socket.off("updateUser");
      }
    };
  };

  const userTabsObserver = () => {
    if (!users) return;

    const newGroupTabs = {};
    users.forEach((user, index) => {
      // Grouping of users is done on-the-go like this. Not pre-filled
      if (!newGroupTabs[user.accountType]) newGroupTabs[user.accountType] = [];

      const nextGroupTab = (
        <GroupTab
          key={index}
          $type={user.type}
          $online={user.online}
          $unread={user.unread}
          $isActive={selectedUser.name == user.name}
          onClick={() => setSelectedUser(user)}
        >
          {user.name}
        </GroupTab>
      );
      newGroupTabs[user.accountType].push(nextGroupTab);
    });

    setGroupTabs(newGroupTabs);
  };

  const userStatusObserver = () => {
    const existUser = users.find((x) => x.name === selectedUser.name);
    if (!existUser) return;
    setUsers(
      users.map((user) =>
        user.name === existUser.name ? { ...user, unread: false } : user
      )
    );
  };

  useEffect(userUpdateObserver, [socket]);
  useEffect(userTabsObserver, [users, selectedUser]);
  useEffect(userStatusObserver, [selectedUser]);

  return (
    <StyledChatSidebar>
      {Object.keys(groupTabs).map((groupName, index) => (
        <StyledChatGroup key={index}>
          <GroupHeading>{groupName}</GroupHeading>
          {groupTabs[groupName]}
        </StyledChatGroup>
      ))}
    </StyledChatSidebar>
  );
};

export default ChatSidebar;

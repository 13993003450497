import React, { useState } from "react";

import { StyledTransparentBackground } from "../styles/Background.styled";
import { StyledContentSection } from "../styles/Section.styled";
import { StyledGamesContainer } from "_components/features/game/styles/Card.styled";

import GameCard from "_components/features/game/GameCard";
import FlagHeading from "../objects/common/FlagHeading";

import { GalleryContext } from "../contexts/GalleryContext";

import gameDatas from "../../js/data/gameDatas";

const Games = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(-1);

  return (
    <StyledContentSection name="games" style={{ height: "auto" }}>
      <FlagHeading>Games</FlagHeading>
      <StyledGamesContainer>
        {gameDatas.map((data, index) => (
          <GalleryContext.Provider key={index} value={data.preview}>
            <GameCard
              data={data}
              index={index}
              activeCardIndex={activeCardIndex}
              setActiveCardIndex={setActiveCardIndex}
            />
          </GalleryContext.Provider>
        ))}
      </StyledGamesContainer>
      <StyledTransparentBackground height="100%" />
    </StyledContentSection>
  );
};

export default Games;

import axios from "axios";
import { ReactSession } from "react-client-session";

import { ENDPOINT } from "js/data/constants";

const getAccessToken = async () => {
  let refreshToken = ReactSession.get("refreshToken");
  // Guarentees value being found when logged in and switching page when racing with App.jsx to set it up again
  if (!refreshToken) {
    ReactSession.setStoreType("localStorage");
    refreshToken = ReactSession.get("refreshToken");
  }
  try {
    const response = await axios.post(`${ENDPOINT}/api/auth/token`, {
      token: refreshToken,
    });
    const accessToken = response.data.accessToken;
    return `Bearer ${accessToken}`;
  } catch (error) {
    console.error("Failed to get refreshed access token!", error);
  }
};

export default getAccessToken;

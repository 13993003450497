import React from "react";

import { StyledIncomingFile } from "../styles/FileElements.styled";
import ManageRequestBtn from "../buttons/ManageRequestBtn";

import { dateFormattingOptions } from "js/data/constants";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const Entry = ({ title, style, children: text }) => {
  if (!text) return;

  return (
    <p style={style}>
      {title && <span className="title">{title}: </span>}
      {text}
    </p>
  );
};

const PendingFile = ({ data, Button = ManageRequestBtn, ...props }) => {
  const { title, type, isRevision, requestId, staffName } = data;
  const date = new Intl.DateTimeFormat("en-US", dateFormattingOptions).format(
    new Date(data.createdTime)
  );

  // Expires upon reaching time of original 'date' + displacement. So must be received 'before' the next day
  let dueDate;
  if (data.dueTime) {
    dueDate = new Date(data.dueTime);
    dueDate.setDate(dueDate.getDate() + 1);
    dueDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(dueDate);
  }

  return (
    <StyledIncomingFile {...props}>
      <div>
        <Entry style={{ color: "var(--action-primary)" }}>
          ID #{isRevision ? "RE" : "OG"}-{requestId}
        </Entry>
        <Entry title="Title">{title}</Entry>
        <Entry title="Type">{type}</Entry>
        <Entry title="Requested On">{date}</Entry>
        <Entry title="Due Before">{dueDate}</Entry>
        <Entry title="Assigned Staff User">
          {staffName != undefined ? (
            <LinkSpan to={`/chat?user=${staffName}`}>{staffName}</LinkSpan>
          ) : (
            <span style={{ fontStyle: "italic", color: "var(--sub-info-alt)" }}>
              Pending
            </span>
          )}
        </Entry>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "13px" }}>
        <Button {...data} />
      </div>
    </StyledIncomingFile>
  );
};

export default PendingFile;

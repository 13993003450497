import { LinkSpan } from "_components/atoms/inline/SpanElements";
import { StyledDocSection } from "_components/features/docs/main/Documentation.styled";

const Revisions = () => {
  return (
    <>
      <StyledDocSection>
        <p className="title">Overview</p>
        <p>
          You can <i>currently</i> only request <b>1 revision</b> per{" "}
          <b>requested edit</b>. The revision can be requested via the{" "}
          <b>revision button</b> found on the fulfilled video request incoming
          file in <LinkSpan to="/file?tab=0">File Manager → Incoming</LinkSpan>.
          Revisions are <i>currently</i> only available on <b>video edits</b>,
          not <b>video thumbnails</b>. You must request the revision within{" "}
          <b>1 day</b> of receiving the fulfilled product, otherwise the{" "}
          <i>request window</i> will expire and you will no longer see the
          button available on the incoming file.
        </p>
      </StyledDocSection>
    </>
  );
};

export default Revisions;

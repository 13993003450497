import { styled } from "styled-components";

const StyledFlagHeading = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;

  position: relative;
  width: 100%;
  grid-area: 1 / 4 / 2 / 5;

  & > div {
    background-color: #12121d;
    height: 85%;
    width: 60%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1370px) {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const FlagHeading = ({ children: text, ...props }) => {
  return (
    <StyledFlagHeading {...props}>
      <div>
        <h1>{text}</h1>
      </div>
    </StyledFlagHeading>
  );
};

export default FlagHeading;

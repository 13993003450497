import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  StyledSidebarredBlock,
  StyledDisplayArea,
} from "../../molecules/containers/common/styles/SidebarredBlock.styled";
import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";

import InputItem from "_components/features/form/InputItem";

import fetchClientTransactions from "js/functions/fetchClientTransactions";
import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";
import FormSelect from "../form/elements/FormSelect";

const videoLengths = ["5", "10", "15", "20", "25", "60"];

const ServiceCheckout = () => {
  const navigate = useNavigate();

  const [unusedThumbnails, setUnusedThumbnails] = useState("Loading..");
  const [unusedEdits, setUnusedEdits] = useState("Loading..");

  const [thumbnailQuantity, setThumbnailQuantity] = useState(0);
  const [editQuantity, setEditQuantity] = useState(0);
  const [videoLength, setVideoLength] = useState(10);

  useEffect(() => {
    fetchClientTransactions().then(({ edits, thumbnails }) => {
      setUnusedEdits(edits);
      setUnusedThumbnails(thumbnails);
    });
  }, []);

  return (
    <StyledSidebarredBlock>
      <StyledDisplayArea $grow="1" $dark $top="left">
        <p style={{ position: "relative", paddingRight: "8px" }}>
          Paid & Unused:{" "}
          <StyledInfoHover
            $inline="end"
            $length="x"
            $text="Your purchased products can be used in the File Manager"
            onClick={() => navigate("/file?tab_outgoing=0&form=true")}
          />
        </p>
        <div style={{ paddingRight: "25px" }}>
          <p>
            Thumbnails:{" "}
            <span style={{ color: "var(--action-primary)" }}>
              {unusedThumbnails}
            </span>
          </p>
          <p>
            Edits:{" "}
            <span style={{ color: "var(--action-primary)" }}>
              {unusedEdits}
            </span>
          </p>
        </div>
      </StyledDisplayArea>
      <StyledDisplayArea $grow="5" $top="right">
        <p>Purchase Services for Your Upcoming Videos:</p>
        <div>
          <InputItem
            $label="Video Thumbnail"
            placeholder="0-60"
            type="number"
            name="tq"
            max={60}
            min={0}
            input={thumbnailQuantity}
            setInput={setThumbnailQuantity}
          />
          <InputItem
            $label="Video Edit"
            placeholder="0-60"
            type="number"
            name="eq"
            max={60}
            min={0}
            input={editQuantity}
            setInput={setEditQuantity}
          />
          {editQuantity > 0 && (
            <FormSelect
              setSelection={setVideoLength}
              optionsList={videoLengths}
              placeholder="-- VIDEO DURATION (MINS) --"
            />
          )}
        </div>
        <ThinBtn
          $primary="var(--action-primary)"
          onClick={() =>
            editQuantity + thumbnailQuantity > 0 &&
            navigate(
              `/checkout?eq=${editQuantity}&tq=${thumbnailQuantity}&len=${videoLength}`
            )
          }
        >
          Proceed to Checkout
        </ThinBtn>
      </StyledDisplayArea>
    </StyledSidebarredBlock>
  );
};

export default ServiceCheckout;

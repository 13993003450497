import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_51N2KiMKX8IZg6QdlQQd56jv5lxN58tJs9DTlloASt7XLP9qv035Clte6BvpqiPmYaLcypsXeQaynw9uHyHKfEGlc0027d0Nxki"
    : "pk_test_51N2KiMKX8IZg6QdlN0H6yPapKkOKRglN4x7fGEaPF1bjexXqPnJu42WXcy33tpdLFlOsUcujueMsTWhRw07YZiu500zwEzHzPi"
);

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState("");

  const navigate = useNavigate();
  const { search } = useLocation();

  const createCheckoutSession = async () => {
    const queryParams = new URLSearchParams(search);
    const editQuantity = queryParams.get("eq");
    const thumbnailQuantity = queryParams.get("tq");
    const videoLength = queryParams.get("len");

    // Create a Checkout Session as soon as the page loads
    axios
      .post(
        `${ENDPOINT}/api/payment/create-checkout-session?eq=${editQuantity}&tq=${thumbnailQuantity}&len=${videoLength}`,
        {},
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then((res) => setClientSecret(res.data.clientSecret))
      .catch((error) => {
        console.log(error);
        const message = error?.response?.data?.error;
        const status = error?.response?.status;
        if (message === "invalid_grant" || status === 403 || status === 401) {
          navigate("/join/verify");
        }
      });
  };

  useEffect(() => {
    createCheckoutSession();
  }, []);

  return (
    <>
      <StyledStandardSpacer />
      <div id="checkout" style={{ width: "100%", height: "100%" }}>
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <StyledStandardSpacer $paddingTop="400px" />
    </>
  );
};

export default CheckoutForm;

import React from "react";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import {
  StyledRoadmapContainer,
  StyledRoadmapBlock,
} from "_components/organisms/sections/RoadmapElements.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

import PriceCalculator from "./PriceCalculator";

const Roadmap = () => {
  return (
    <StyledDualXStrip $only="pull">
      <StyledCenteredArea>
        <StyledSectionH2>Pricing</StyledSectionH2>
      </StyledCenteredArea>

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledRoadmapContainer>
        <StyledSbs
          $gap="0"
          $yGap="0"
          $manualWrap
          $width="min(700px, 80%)"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <StyledRoadmapBlock $infoActive>
            <h3>Unbeatable Rates</h3>
            <h2>Fixed Pricing</h2>
            <p>
              You <b>always</b> pay these prices per video.
            </p>
          </StyledRoadmapBlock>
          <StyledRoadmapBlock $info>
            <PriceCalculator $noHeader lowerBracket={0} />
          </StyledRoadmapBlock>
        </StyledSbs>
      </StyledRoadmapContainer>
    </StyledDualXStrip>
  );
};

export default Roadmap;

import styled, { css } from "styled-components";

import { thinBtnStyle } from "_components/atoms/buttons/styles/ThinButtons.styled";

export const StyledTabToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.$width || "95%"};
  margin-left: auto;
  margin-right: auto;
`;

export const StyledIconTab = styled.div`
  ${thinBtnStyle}

  min-width: 30px;
  width: 40px;
  height: 40px;
  transform: translateY(-5px);
  position: relative;

  border-radius: 10px;

  background-color: ${(props) =>
    props.$index === props.$currentTab
      ? "var(--main-primary)"
      : "var(--main-background)"};

  &::after {
    content: "";
    background: no-repeat center/50% ${(props) => `url(${props.src})`};
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export const StyledSpacedTabsContainer = styled.div`
  --container-back: var(--main-background);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 14px;

  background-color: var(--container-back);
  padding: 8px;
  border-radius: 5px;

  ${(props) => {
    if (props.$darkBack) {
      return css`
        --container-back: var(--main-secondary);
      `;
    }
  }}
`;

export const StyledTab = styled.button`
  ${thinBtnStyle}

  background-color: ${(props) =>
    props.$index === props.$currentTab
      ? "var(--main-primary)"
      : props.$bgLight
      ? "var(--main-background)"
      : "var(--main-secondary)"}
`;

export const StyledSmushedTabContainer = styled.div`
  background-color: var(--main-background);
  border-radius: 5px;
  width: 300px;
  height: 30px;
  /* margin-left: 40px; */
`;

import styled, { css } from "styled-components";

import { ArrowIndentStyle } from "_components/atoms/inline/styles/Decorations.styled";

export const StyledChatForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 15px;

  height: 600px;
  min-width: min(500px, 90%);
  flex-grow: 99999;
`;

export const StyledChatBar = styled.div`
  background-color: var(--main-background);

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  height: 3rem;
  width: 100%;
  padding: 10px 20px;
  gap: 2%;

  border-radius: 10px;
`;

export const StyledChatTextInput = styled.input`
  height: 100%;
  width: 100%;

  color: white;
  background: none;
  border: none;

  &::placeholder {
    color: var(--sub-info-alt);
  }

  &:focus {
    outline: none;
  }
`;

export const StyledChatBtn = styled.button`
  display: block;
  position: relative;
  height: 1.2rem;
  width: 1.2rem;
  border: none;
  background: none;

  cursor: pointer;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: var(--sub-info-alt);
    clip-path: polygon(0 0, 100% 50%, 0 100%, 0 60%, 80% 50%, 0 40%);

    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      background-color: white;
    }
  }
`;

export const StyledChatMessageContainer = styled.div`
  width: 100%;
`;

export const StyledChatMessageHeader = styled.h3`
  --icon-radius: 30px;

  position: relative;
  padding-left: calc(var(--icon-radius) + 10px);

  margin: 0;
  margin-bottom: 8px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: var(--icon-radius);
    width: var(--icon-radius);

    background: url("/images/profile-icon.png") no-repeat center/100%;
  }

  &::after {
    ${(props) => {
      return css`
        content: "- ${props.$date}";
      `;
    }}

    color: var(--sub-info-light);
    font-size: 0.75rem;
    padding-left: 5px;
  }

  /* Put last to override everything else */
  ${(props) => {
    let col = "var(--action-primary)";
    let img = "/images/profile-icon.png";

    switch (props.$rank) {
      case "admin":
        col = "var(--main-primary)";
        img = "/images/profile-icon-alt.png";
        break;
    }

    return css`
      color: ${col};
      &::before {
        background-image: url(${img});
      }
    `;
  }}
`;

export const StyledReferenceFile = styled.p`
  ${ArrowIndentStyle}

  color: var(--action-primary);
  font-style: italic;
  cursor: pointer;

  margin: 0;
  margin-left: 50px;

  & > span {
    text-decoration: underline;
  }

  &::before {
    left: -10px;
    background-color: var(--action-primary);
  }
`;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	:root {
		color-scheme: dark;
	}

	html {
		--action-primary: #00b1ff;
		--action-primary-alt: #066AFF;
		--action-primary-dimmed: #12235A;
		--action-secondary: #6C38FE;
		--main-primary: #3232B4;
		--main-primary-alt: #0A339E;
		--main-secondary: #11111D;
		--main-secondary-dark: #0D0D16;
		--main-secondary-alt: #28283B;
		--sub-info-light: #C9C9C9;
		--sub-info-dark: #A2A2A2;
		--sub-info-alt: #8989B1;
		--main-background: #181828;
		--contrast-one: #FF00AA;
		--contrast-two: #AB00FF;
		--contrast-important: #FF0000;
		--exit-main: #FB5E5E;

		--active-default: #00FFA6;
		--inactive-default: #D6D6D6;
		/* --wrap-maxwidth: 1370px; */
	}

	* {
		box-sizing: border-box;
		/* margin: 0; */
	}

	body {
		background-color: var(--main-background);
		margin: 0;
		font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
		overflow-x: hidden;
	}

	h1 {
		color: white;
		font-size: 54px;
    font-weight: 400;
	}

	h2 {
		color: white;
		font-size: 26px;
		font-weight: 400;
	}

	h3 {
		color: white;
		font-size: 1.2rem;
		font-weight: 300;

		/* TODO: Add to rest gradually, then replace all with *margin:0 */
		margin: 0;
	}

	h4 {
		color: white;
    font-size: 18px;
		font-weight: 400;
	}

	/* h1 {
		color: white;
		font-size: 54px;
    font-weight: 400;
	}

	h2 {
		color: white;
		font-size: 35px;
		font-weight: 400;
	}

	h3 {
		color: white;
		font-size: 26px;
		font-weight: 300;
	}

	h4 {
		color: white;
    font-size: 19.2px;
		font-weight: 300;
	}

	h5 {
		color: white;
    font-size: 18px;
		font-weight: 300;
	} */

	p {
    line-height: 30px;
		color: var(--sub-info-light);
	}

	@media only screen and (max-width: 500px) {
		h1 {
			font-size: 30px;
		}

		h2 {
			font-size: 17px;
		}

		h3 {
			font-size: 15px;
		}

		h4 {
			font-size: 13px;
		}

		p {
			font-size: 0.8rem;
			line-height: 1.5rem;
		}
	}

	@media only screen and (min-width: 500px) and (max-width: 700px) {
		h1 {
			font-size: 45px;
		}

		h2 {
			font-size: 20px;
		}

		h4 {
			font-size: 14px;
		}

		p {
			font-size: 0.9rem;
		}
	}
`;

export default GlobalStyle;

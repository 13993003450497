import React, { useState } from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ManyTab from "_components/atoms/navigation/ManyTab";

import Account from "_components/features/admin/analytics/Account";
import ProductFulfillment from "_components/features/admin/analytics/ProductFulfillment";

const panels = ["Account", "Product"];

const Analytics = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <StyledStandardEndSection>
        <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
          <h1>Admin Panel</h1>
          <p style={{ color: "var(--action-primary)" }}>Analytics</p>
        </TopHeadingBanner>

        <ManyTab
          panels={panels}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />

        {currentTab === 0 && <Account />}
        {currentTab === 1 && <ProductFulfillment />}
      </StyledStandardEndSection>
    </>
  );
};

export default Analytics;

import styled from "styled-components";

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  .info {
    margin: 0 0 20px 0;
    color: white;
  }

  .heading {
    text-align: ${(props) => props.$headed && "center"};
    font-size: 1.3rem;
    margin: 0;
    color: var(--main-primary);
  }
`;

export const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  max-width: ${(props) => props.$maxWidth};
  min-width: ${(props) => props.$minWidth};

  background-color: var(--main-background);
`;

export const StyledInfoGrid = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  height: 100%;
  width: 100%;
`;

export const StyledInfoSection = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;

  position: relative;
`;

export const StyledStatBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 30px;

  & > h1 {
    margin: 0;
  }
`;

export const StyledStat = styled.div`
  background-color: var(--main-background);
  border-radius: 15px;
  width: 400px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  .heading {
    font-weight: bold;
    color: white;
  }

  .info {
    font-weight: bold;
    color: ${(props) =>
      props.$typeIndex === 0 ? "var(--main-primary)" : "var(--action-primary)"};
  }
`;

import React from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import { StyledHoverFlagBtn } from "_components/atoms/buttons/styles/IconButtons.styled";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const ResumeBtn = ({ setIsPaused, progressUri }) => {
  const resumeUpload = async () => {
    const uploadID = getUploadID(progressUri);
    axios
      .get(`${ENDPOINT}/api/drive/resume/${uploadID}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then(() => setIsPaused(false))
      .catch(console.log);
  };

  return (
    <StyledHoverFlagBtn $enabled onClick={resumeUpload}>
      <div className="hover-flag">Resume</div>
      <img src="/images/buttons/play-icon.png" />
    </StyledHoverFlagBtn>
  );
};

export default ResumeBtn;

import React, { useContext } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import { StyledHoverFlagBtn } from "_components/atoms/buttons/styles/IconButtons.styled";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";

import { UploadContext } from "components/contexts/UploadContext";
import getAccessToken from "js/features/auth/getAccessToken";

const CancelBtn = ({ setUploadProgress, setIsUploading, progressUri }) => {
  const [[progressUris, setProgressUris], [fileDatas, setFileDatas]] =
    useContext(UploadContext);

  const cancelUpload = async () => {
    const uploadID = getUploadID(progressUri);
    axios
      .get(`${ENDPOINT}/api/drive/cancel/${uploadID}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .catch((err) => {
        console.warn(`Upload cancellation failed!`, err);
      });
    setUploadProgress(0);
    setIsUploading(false);

    const updatedProgressUris = progressUris.filter(
      (uri) => uri !== progressUri
    );
    setProgressUris(updatedProgressUris);

    const newVideoNames = Object.fromEntries(
      Object.entries(fileDatas).filter(([uri]) => uri !== progressUri)
    );
    setFileDatas(newVideoNames);
  };

  return (
    <StyledHoverFlagBtn $enabled onClick={cancelUpload}>
      <div className="hover-flag">Cancel</div>
      <img src="/images/buttons/cancel-icon.png" />
    </StyledHoverFlagBtn>
  );
};

export default CancelBtn;

import styled from "styled-components";

import { StyledArrowIndentSpan } from "_components/atoms/inline/styles/Decorations.styled";

export const StyledDocSection = styled.section`
  padding-left: min(25px, 5%);
  width: min(900px, 100%);

  & > p {
    color: white;
  }

  & > p:not(.title) {
    padding-left: 30px;
  }

  .title {
    background-color: var(--main-secondary-alt);
    border-radius: 5px;
    padding: 5px 10px;
    width: min(200px, 100%);
  }
`;

const StyledIndentedP = styled.p`
  & > span > div {
    padding-bottom: 15px;
  }

  .section-header {
    font-style: italic;
  }

  .sub-header {
    color: ${(props) => props.$col || "var(--sub-info-alt)"};
    font-weight: 600;
  }

  p {
    color: white;
    margin: 0;
  }
`;

export const IndentedP = ({ children, style, ...props }) => {
  return (
    <StyledIndentedP style={style}>
      <StyledArrowIndentSpan {...props} $col="var(--sub-info-alt)">
        {children}
      </StyledArrowIndentSpan>
    </StyledIndentedP>
  );
};

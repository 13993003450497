import axios from "axios";

import Reviewer from "./Reviewer";

import { ENDPOINT, dateFormattingOptions } from "js/data/constants";
import gameDatas from "js/data/gameDatas";

import getAccessToken from "js/features/auth/getAccessToken";

async function onConfirm(state, data) {
  axios
    .post(
      `${ENDPOINT}/api/booster/requests/fulfill/${data.RequestId}`,
      {
        userId: data.UserId,
        gameId: gameDatas.find((gameData) => gameData.game == data.GameName)
          ?.id,
        gameName: data.GameName,
        fulfillment: state === 1 ? 1 : -1,
        date: new Intl.DateTimeFormat("en-US", dateFormattingOptions).format(
          new Date(data.Timestamp)
        ),
      },
      {
        headers: {
          Authorization: await getAccessToken(),
        },
      }
    )
    .catch(console.error);
}

const BoostRequests = () => {
  return (
    <Reviewer
      itemAlias="Boost Request"
      pendingUri={`${ENDPOINT}/api/user/data?type=column&db=boostrequests&column=IsFulfilled&value=0`}
      searchDatabase="boostrequests"
      searchColumn="IsFulfilled"
      searchValues={[1, -1]}
      onConfirm={onConfirm}
    />
  );
};

export default BoostRequests;

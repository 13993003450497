import React from "react";

import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import InfoStack from "_components/molecules/containers/common/InfoStack";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const KeyInformation = () => {
  return (
    <InfoStack $title="Key Information" $col="var(--contrast-one)">
      <div>
        <h3>Intro</h3>
        <br />
        <p>
          When we say 'our Game Studios' we are referring to our Roblox Game
          Studios which we constantly build and actively maintain.
        </p>
        <p>
          When we say 'our Games' we are referring to our Roblox Games which our
          Roblox Game Studios made or are making, while constantly coming up
          with new game ideas to build.
        </p>
        <p>
          This half of our brand's identity can be described as a constantly
          growing 'Empire of Roblox Game Studios'. You can view and understand
          the other half of our brand identity in '
          <LinkSpan $col="var(--contrast-one)" to="/benefits/production">
            Production Benefits
          </LinkSpan>
          '.
        </p>
      </div>
      <div>
        <h3>Your responsabilities</h3>
        <br />
        <div name="requirements">
          <p className="no-arrow">
            <StyledHighlightedSpan $col="var(--contrast-one)">
              Requirements
            </StyledHighlightedSpan>{" "}
            for being eligible to receiving benefits listed on this page:
          </p>
          <p>
            You must have uploaded the minimum amount of monthly videos, from
            which you paid us to help produce.
          </p>
          <p>
            You must have selected to be involved in the game via the 'Game
            Manager'.
          </p>
        </div>
        <br />
        <div name="factors">
          <p className="no-arrow">
            <StyledHighlightedSpan $col="var(--contrast-one)">
              Factors
            </StyledHighlightedSpan>{" "}
            dictating the scale of the advertising received from us:
          </p>
          <p>
            Your number of produced videos over the minimum upload requirement
            in the month, which you paid us to help produce.
          </p>
          <p>
            The amount of <LinkSpan to="/game/booster">advertising</LinkSpan>{" "}
            you have done for the game(s).
          </p>
        </div>
      </div>
    </InfoStack>
  );
};

export default KeyInformation;

import React from "react";
import styled from "styled-components";

const StyledMemberList = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;

  width: 100%;
  height: 80px;
  padding: 0 20px;
  gap: 20px;

  background-color: var(--main-background);
  border-radius: 10px;

  overflow-x: auto;

  &::before {
    content: "Group Members";

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    min-width: 130px;

    color: white;
    font-weight: bold;
  }
`;

const StyledMemberItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
  height: 30px;

  border: solid
    ${(props) =>
      props.$online ? "var(--active-default)" : "var(--inactive-default)"};
  border-radius: 5px;
  border-width: 0 0 1px 0;

  gap: 7px;

  & > img {
    height: 1.3rem;
  }

  & > p {
    font-size: 1rem;
    font-weight: bold;
    color: var(--main-primary);
    transition: all 0.3s ease-in-out;
  }
`;

const MemberItem = ({ username }) => {
  return (
    <StyledMemberItem>
      <img src="/images/profile-icon-alt.png" />
      <p>{username}</p>
    </StyledMemberItem>
  );
};

const MemberList = ({ members }) => {
  return (
    <StyledMemberList>
      {members.map((username) => (
        <MemberItem username={username} />
      ))}
    </StyledMemberList>
  );
};

export default MemberList;

import React from "react";

import { StyledProfileItem } from "../styles/ProfileCardElements.styled";

const ProfileItem = ({ title, value, children, ...props }) => {
  return (
    <StyledProfileItem {...props}>
      <h4>{title}</h4>
      <h4>{value}</h4>
      {children}
    </StyledProfileItem>
  );
};

export default ProfileItem;

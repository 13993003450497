import { StyledBlendContainer } from "_components/molecules/structure/styles/BlendImg.styled";
import { StyledNote } from "components/styles/Note.styled";
import { StyledAspectRatioWrapper } from "components/styles/AspectRatioContainer.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

import {
  WaveBackgroundTop,
  WaveBackgroundBottom,
} from "_components/molecules/structure/WaveBackgrounds";
import Tram, { StyledTramImg } from "components/objects/gallery/Tram";
import { CtaAlt } from "components/CtaElements.jsx";

function GamesPortal() {
  return (
    <section style={{ position: "relative" }}>
      <StyledBlendContainer>
        <WaveBackgroundTop>
          <StyledCenteredHeader $position="absolute" $bottom="15%">
            <h1>Enter the Games Portal</h1>
            <p style={{ maxWidth: "450px" }}>
              View the games our many development studios have released, are
              developing and have planned for the future.
            </p>
          </StyledCenteredHeader>
        </WaveBackgroundTop>

        <StyledAspectRatioWrapper $maxHeight="600px">
          <Tram>
            <StyledTramImg src="/content/games/listen/duo.jpg" />
            <StyledTramImg src="/content/games/astral/stairs-scene.jpg" />
            <StyledTramImg src="/content/games/betterCallTim/forest-scene.jpg" />
            <StyledTramImg src="/content/games/labyrinth/boss-fight.jpg" />
            <StyledTramImg src="/content/games/barRumble/3-chill.jpg" />
          </Tram>
        </StyledAspectRatioWrapper>

        <WaveBackgroundBottom>
          <StyledCenteredArea $absolute="100px">
            <CtaAlt to="/content#games">View Games</CtaAlt>
          </StyledCenteredArea>
        </WaveBackgroundBottom>
      </StyledBlendContainer>

      <StyledNote $bottom="0" $right="0" $inset="20px">
        Note:
        <br />
        These are some of the games you could take percentage profits from and
        be advertised in by joining us. You can be involved in many games at
        once.
      </StyledNote>
    </section>
  );
}

export default GamesPortal;

export const ENDPOINT =
  process.env.NODE_ENV === "production"
    ? // : window.location.host
      "https://www.pushxpull.co"
    : "http://localhost:3001";

export const SITE_URL =
  process.env.NODE_ENV === "production"
    ? // : window.location.host
      "https://www.pushxpull.co"
    : "http://localhost:3000";

export const companyEmail = "admin@pushxpull.co";
export const contactEmail = "contact@pushxpull.co";

export const dateFormattingOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  month: "short",
  day: "numeric",
};

import React from "react";

import {
  StyledProIndentP,
  StyledConIndentP,
} from "_components/atoms/inline/styles/Decorations.styled";
import {
  StyledThinInfoPanel,
  StyledPanelHeading,
  StyledPanelContainer,
} from "components/styles/common/ThinInfoPanel.styled";

import { LinkSpan } from "_components/atoms/inline/SpanElements";

export const ProdComparisonPrimary = () => {
  return (
    <StyledThinInfoPanel>
      <StyledPanelHeading $highlighted>
        <h1>Our Production Teams</h1>
        <p>Teams of Editors & Artists</p>
      </StyledPanelHeading>
      <StyledPanelContainer $highlighted>
        <StyledProIndentP>
          All individuals of a team work for the same price
        </StyledProIndentP>
        <StyledProIndentP>
          You are a first priority, with the team dedicated to you giving you
          their full attention and focus
        </StyledProIndentP>
        <StyledProIndentP>
          All team members adapt to your video style and keep their styles all
          consistent with one another through regular practice and training
          working with one another
        </StyledProIndentP>
        <StyledProIndentP>
          Full co-ordination between members of each team as they are trained
          together regularly to all be consistent with one another
        </StyledProIndentP>
        <StyledProIndentP>
          Individuals of a team organise workload between themselves to match
          your upload schedule exactly
        </StyledProIndentP>
        <StyledProIndentP>
          Production Teams are pre-designed for you and you don't need you to
          make them up yourself
        </StyledProIndentP>
        <StyledProIndentP>
          We are the <LinkSpan to="/pricing">lowest prices</LinkSpan> in the
          industry
        </StyledProIndentP>
      </StyledPanelContainer>
    </StyledThinInfoPanel>
  );
};

export const ProdComparisonSecondary = () => {
  return (
    <StyledThinInfoPanel>
      <StyledPanelHeading>
        <h1>Individual Editors & Artists</h1>
        <p>Freelancers found on other sites</p>
      </StyledPanelHeading>
      <StyledPanelContainer>
        <StyledConIndentP>
          Can charge whatever price they want, charging differently from any
          others you hire
        </StyledConIndentP>
        <StyledConIndentP>
          Can have many other clients, making you not a priority & very
          replaceable
        </StyledConIndentP>
        <StyledConIndentP>
          Don't produce work in the same style or speed as any others you hire
        </StyledConIndentP>
        <StyledConIndentP>
          No co-ordination with others you hire, partly due to them being used
          to an "every man for themselves" mindset
        </StyledConIndentP>
        <StyledConIndentP>
          Their working schedule doesn't match up well with others you hire,
          hurting your upload schedule massively
        </StyledConIndentP>
        <StyledConIndentP>
          You must create a whole team of editors and artists all by yourself
          with all of the issues mentioned
        </StyledConIndentP>
        <StyledConIndentP>
          Prices of all other freelance sites cannot beat our rates
        </StyledConIndentP>
      </StyledPanelContainer>
    </StyledThinInfoPanel>
  );
};

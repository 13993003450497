import React from "react";

import { StyledProfileHeader } from "../styles/ProfileCardElements.styled";

const ProfileHeader = ({ username }) => {
  return (
    <StyledProfileHeader>
      <img src="/images/profile-icon.png" />
      <h3>{username}</h3>
    </StyledProfileHeader>
  );
};

export default ProfileHeader;

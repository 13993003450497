import styled, { css } from "styled-components";

import { DashedUnderlineVerticleStyle } from "_components/atoms/inline/styles/Decorations.styled";

const StyledBaseInfoBlockH1 = styled.h1`
  margin: 0;
  max-width: ${(props) => props.$maxWidth};
`;

export const StyledInfoBlockH1 = styled(StyledBaseInfoBlockH1)`
  @media screen and (max-width: 500px) {
    text-align: center;
    width: 90%;
  }

  @media screen and (min-width: 500px) and (max-width: 1370px) {
    text-align: center;
    width: 80%;
  }
`;

export const StyledLineInfoBlockH1 = styled(StyledBaseInfoBlockH1)`
  position: relative;

  &::before {
    content: "";
    display: ${(props) => (props.$noBack ? "none" : "block")};
    position: absolute;
    top: 0;
    left: -20%;
    height: 100px;
    width: 100%;
    opacity: 0.6;
    border-radius: 10px;
    z-index: -1;
    transform: translateY(-20%);

    ${(props) => {
      if (props.$push) {
        return css`
          background-color: var(--main-secondary);
        `;
      } else if (props.$pull) {
        return css`
          background-color: var(--main-primary);
        `;
      }
    }}
  }
`;

const StyledBaseInfoBlockP = styled.p`
  color: ${(props) => (props.$white ? "#fff" : "var(--sub-info-light)")};
  max-width: ${(props) => props.$maxWidth};
`;

export const StyledInfoBlockP = styled(StyledBaseInfoBlockP)`
  @media screen and (max-width: 500px) {
    text-align: center;
    width: 80%;
  }

  @media screen and (min-width: 500px) and (max-width: 1370px) {
    text-align: center;
    width: 60%;
  }
`;

export const StyledLineInfoBlockP = styled(StyledBaseInfoBlockP)``;

const StyledBaseInfoBlock = styled.div`
  display: flex;
  position: relative;
  align-items: start;
  flex-direction: column;

  gap: ${(props) => props.$gap};

  & > h4 {
    margin: 35px 0;
    color: var(--sub-info-light);
  }

  & > div:not(.no-flex) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 35px;
  }
`;

export const StyledInfoBlock = styled(StyledBaseInfoBlock)`
  @media screen and (max-width: 1370px) {
    width: 100%;
    align-items: center;

    & > div:not(.no-flex) {
      justify-content: center;
    }
  }
`;

export const StyledLineInfoBlock = styled(StyledBaseInfoBlock)`
  --line-offset: 30px;

  max-width: ${(props) => props.$maxWidth || "450px"};
  margin-left: calc(var(--line-offset) * 2);
  /* Halfing allows line & text to be centered together */
  padding-right: var(--line-offset);

  &::after {
    ${DashedUnderlineVerticleStyle};
    --invis-col: ${(props) => props.$colBack};
    --main-col: ${(props) => props.$col};
    left: calc(var(--line-offset) * -1);
    height: ${(props) =>
      props.$addedHeight ? `calc(100% + ${props.$addedHeight})` : "100%"};
    top: ${(props) =>
      /* Half of anything above 100%, to center with text vertically */
      props.$addedHeight && `calc(-${props.$addedHeight} / 2)`};
  }
`;

import axios from "axios";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

export const getFormattedVideoNames = (videoDatas) => {
  const videoNames = [];

  videoDatas.forEach((data) => {
    const prefix = data.isRevision ? "RE-" : "OG-";
    videoNames.push(
      "#" +
        prefix +
        data.requestId +
        ' | "' +
        data.title +
        '"' +
        (data.isRevision ? ` -> [Revision of ${data.ofRequestId}]` : "")
    );
  });

  return videoNames;
};

const fetchUnfulfilledVideos = async (selectedUser) => {
  if (selectedUser == "") {
    // Doesn't error chain, but just with empty values
    return Promise.resolve({ videoDatas: [], videoNames: [] });
  }

  return axios
    .get(
      `${ENDPOINT}/api/payment/fulfillment/requests?type=unfulfilled&uid=${selectedUser}`,
      {
        headers: {
          Authorization: await getAccessToken(),
        },
      }
    )
    .then((res) => {
      const videoDatas = res.data;
      const videoNames = getFormattedVideoNames(videoDatas);

      if (videoDatas.length === 0) {
        console.warn(`No files found for: '${selectedUser}'`);
        return { videoDatas, videoNames };
      }

      return { videoDatas, videoNames };
    })
    .catch((error) => {
      console.error("Failed to get unfulfilled videos list!", error);
    });
};

export default fetchUnfulfilledVideos;

import React from "react";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledCta } from "components/CtaElements";

import { StyledFormNote } from "./styles/FormStructs.styled";

const FormCta = ({ children: value, note }) => {
  return (
    <StyledCenteredArea style={{ gridColumn: "1 / -1" }}>
      <StyledFormNote>{note}</StyledFormNote>
      <StyledCta>
        <input
          className="txt"
          type="submit"
          value={value}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "unset",
            borderStyle: "none",
          }}
        />
        <div className="box" />
      </StyledCta>
    </StyledCenteredArea>
  );
};

export default FormCta;

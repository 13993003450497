import React from "react";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import { StyledFeatureOverviewBlock } from "_components/molecules/displays/styles/FeatureOverview.styled.js";

const SystemsOverview = () => {
  return (
    <StyledDualXStrip $col="var(--action-primary)">
      <StyledCenteredHeader>
        <h1>Features Overview</h1>
        <p style={{ maxWidth: "580px" }}>
          Get a glimpse of the many features our website supports, allowing you
          to handle your every possible need, straight from our site
        </p>
      </StyledCenteredHeader>

      <StyledSbs
        $gap="50px"
        $yGap="50px"
        $align="center"
        $justify="center"
        $defaultWrap
        $shadowed
        style={{
          height: "100%",
          width: "100%",
          padding: "50px 10%",
        }}
      >
        <StyledFeatureOverviewBlock>
          <div>
            <h2>Client Assigning</h2>
            <p>
              You are assigned higher paying clients as you make a better
              impression across our site
            </p>
          </div>
          <img src="/images/buttons/client-icon.png" />
        </StyledFeatureOverviewBlock>

        {/* <StyledFeatureOverviewBlock>
          <div>
            <h2>Work Selection</h2>
            <p>You select which work you want to handle and when you want</p>
          </div>
          <img src="/images/buttons/select-work-overview-icon.png" />
        </StyledFeatureOverviewBlock> */}

        <StyledFeatureOverviewBlock>
          <div>
            <h2>Request More Time</h2>
            <p>
              If you can't fulfill work in time, you can ask for more time from
              your client
            </p>
          </div>
          <img src="/images/buttons/request-time-overview-icon.svg" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>Time Off</h2>
            <p>
              Request time off from all work for a period of time, and no one
              will bother you
            </p>
          </div>
          <img src="/images/buttons/sun-icon.png" />
        </StyledFeatureOverviewBlock>

        <StyledFeatureOverviewBlock>
          <div>
            <h2>Direct Payment</h2>
            <p>
              Upon completion of work, we send your earnings straight to your
              PayPal or other wallet
            </p>
          </div>
          <img src="/images/buttons/cart-icon.png" />
        </StyledFeatureOverviewBlock>

        {/* <StyledFeatureOverviewBlock>
          <div>
            <h2>Pass Off Work</h2>
            <p>
              If you no longer wish to fulfill work, you can pass it off to
              allow other staff to handle it
            </p>
          </div>
          <img src="/images/buttons/pass-off-overview-icon.png" />
        </StyledFeatureOverviewBlock> */}
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default SystemsOverview;

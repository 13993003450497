import React from "react";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledFaqList } from "_components/themes/faq/FaqElements";

import {
  WaveBackgroundBottom,
  WaveBackgroundTop,
} from "_components/molecules/structure/WaveBackgrounds";
import FaqItem from "_components/themes/faq/FaqItem";

const FaqWaveContainer = ({ children: faqItems }) => {
  return (
    <>
      <WaveBackgroundTop />
      <StyledCenteredArea style={{ backgroundColor: "#11111A" }}>
        <div>
          <h1>Frequently Asked Questions</h1>
          <StyledFaqList>{faqItems}</StyledFaqList>
        </div>
      </StyledCenteredArea>
      <WaveBackgroundBottom />
    </>
  );
};

export default FaqWaveContainer;

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ParamTabSet = ({
  $autoReset,
  setTab,
  query = "tab",
  parser = (str) => str,
  dependancies = [],
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const tabJumpObserver = () => {
    const queryParams = new URLSearchParams(search);
    let tab = parser(queryParams.get(query));
    if (tab != undefined && !isNaN(tab)) {
      console.log(tab);
      setTab(tab);
      if ($autoReset) {
        navigate(pathname);
      }
    }
  };

  useEffect(tabJumpObserver, dependancies);

  return <></>;
};

export default ParamTabSet;

import React from "react";

import { StyledStaticIconBtn } from "_components/features/file/styles/Buttons.styled";

import { useNavigate } from "react-router-dom";

const ViewFilesBtn = ({ fileId, client }) => {
  const navigate = useNavigate();

  const handleSelect = (event) => {
    event.preventDefault();
    navigate(`/file?tab=0&user=${client}&fileid=${fileId}`);
  };

  return (
    <StyledStaticIconBtn $flagWidth="100px" onClick={handleSelect}>
      <div className="hover-flag">View Files</div>
      <img src="/images/buttons/folder-icon.png" />
    </StyledStaticIconBtn>
  );
};

export default ViewFilesBtn;

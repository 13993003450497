import { useRef, useState, useEffect } from "react";

import { StyledFaqItemContainer, StyledFaqItem } from "./FaqElements";

const FaqItem = ({ children }) => {
  const minHeight = 50;

  const [currentHeight, setCurrentHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const faqItemRef = useRef(null);
  const contentRef = useRef(null);

  const handleClick = (event) => {
    if (isExpanded == true) {
      faqItemRef.current.style.height = minHeight + "px";
      setIsExpanded(false);
    } else {
      faqItemRef.current.style.height = currentHeight + "px";
      setIsExpanded(true);
    }
  };

  const calculateHeight = () => {
    var style =
      contentRef.current.currentStyle ||
      window.getComputedStyle(contentRef.current);
    var height =
      parseInt(style.marginTop) +
      parseInt(style.marginBottom) +
      minHeight +
      contentRef.current.offsetHeight;
    setCurrentHeight(height);
  };

  useEffect(() => {
    const recalculate = () => {
      const [_, content] = faqItemRef.current.children;
      contentRef.current = content;
      calculateHeight();
    };
    recalculate();
    window.addEventListener("resize", recalculate, false);
    return () => {
      window.removeEventListener("resize", recalculate, false);
    };
  }, [currentHeight]);

  return (
    <StyledFaqItemContainer>
      <StyledFaqItem
        style={{
          height: isExpanded && currentHeight + "px",
        }}
        $expanded={isExpanded}
        ref={faqItemRef}
        onClick={handleClick}
      >
        {children}
      </StyledFaqItem>
    </StyledFaqItemContainer>
  );
};

export default FaqItem;

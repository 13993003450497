import React, { useRef, useContext } from "react";

import { StyledSbs } from "../styles/SideBySide.styled";

import { StickyCtaContext } from "../contexts/StickyCtaContext";
import ResponsiveStickiesHandler from "../handlers/ResponsiveStickiesHandler";

const HomeHeaderSbs = ({ children, ...props }) => {
  const homeHeaderRef = useRef(null);
  const [_isStickiesEnabled, setIsStickiesEnabled] =
    useContext(StickyCtaContext);

  let mobileQuery = window.matchMedia("(max-width: 800px)");

  return (
    <StyledSbs {...props} ref={homeHeaderRef}>
      <ResponsiveStickiesHandler
        windowQuery={mobileQuery}
        setIsStickiesEnabled={setIsStickiesEnabled}
        refForObserver={homeHeaderRef}
      />
      {children}
    </StyledSbs>
  );
};

export default HomeHeaderSbs;

import React from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ComparisonContainer from "_components/molecules/containers/common/ComparisonContainer";
import {
  ProdComparisonPrimary,
  ProdComparisonSecondary,
} from "_components/features/docs/production/blocks/ProdComparisonPrimary";
import KeyInformation from "_components/features/docs/production/blocks/KeyInformation";

const ProductionBenefits = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Production Benefits</h1>
        <p>
          Everything about the benefits from working with our production teams
        </p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <KeyInformation />

      <StyledStandardSpacer $paddingTop="400px" />

      <ComparisonContainer title="Brand Comparison">
        <ProdComparisonPrimary />
        <ProdComparisonSecondary />
      </ComparisonContainer>
    </StyledStandardEndSection>
  );
};

export default ProductionBenefits;

import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: var(--main-background);
  position: relative;

  td,
  th {
    border: 2px solid var(--main-secondary-alt);
    padding: 15px;
    text-align: left;
    position: relative;
    /* border: 1px solid white; */
  }
`;

export const StyleTRH = styled.th`
  width: 30%;
`;

export const Table = ({ title, children }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <h3>{title}</h3>
      <StyledTable>{children}</StyledTable>
    </div>
  );
};

import React from "react";

import GameCardOverlay from "./GameCardOverlay";
import { StyledGameCard } from "./styles/Card.styled";

const GameCard = ({ activeCardIndex, setActiveCardIndex, data, index }) => {
  const isActive = activeCardIndex === index;

  return (
    <>
      {isActive ? (
        <GameCardOverlay setActiveCardIndex={setActiveCardIndex} data={data} />
      ) : null}
      <StyledGameCard onClick={() => setActiveCardIndex(index)}>
        <img src={data.preview[0].original} />
        <div>
          <h1>{data.game}</h1>
          <h4>by: {data.studio}</h4>
        </div>
      </StyledGameCard>
    </>
  );
};

export default GameCard;

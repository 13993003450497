import React, { useEffect, useState } from "react";
import axios from "axios";

import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledSelectBtn } from "../styles/Buttons.styled";

import ConfirmSelectScreen from "_components/molecules/containers/overlays/ConfirmSelectScreen";

import { ENDPOINT } from "js/data/constants";
import gameDatas from "js/data/gameDatas";
import getAccessToken from "js/features/auth/getAccessToken";
import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";

const confirmSelectScreenInfo = {
  confirmed: {
    title: "Game Unselected!",
    description: (
      <p>
        You are no longer <b>involved</b> in this game, and can now select a
        different game.
      </p>
    ),
  },
  prompt: {
    title: "Unselect Game?",
    description: (
      <p>
        Are you sure you want to unselect this game? This means you will stop
        receiving benefits from this game.
      </p>
    ),
  },
};

const UnselectBtn = ({ gameTitle, setActiveCardIndex }) => {
  const [isActive, setIsActive] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const handleSelect = (event) => {
    if (!enabled) return;
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setIsActive(true);
      setWasOpened(true);
    }
  };

  const onConfirmed = async () => {
    const gameId = gameDatas.find((data) => data.game === gameTitle)?.id;
    if (!gameId) {
      console.error(`No game id found for game title: ${gameTitle}`);
      return;
    }
    axios
      .post(
        `${ENDPOINT}/api/game/remove-user/${gameId}`,
        {},
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .catch(console.error);
  };

  useEffect(() => {
    if (wasOpened && !isActive && setActiveCardIndex) setActiveCardIndex(-1);
  }, [wasOpened, isActive]);

  const observeBtnEnabled = async () => {
    const gameId = gameDatas.find((data) => data.game === gameTitle)?.id;
    axios
      .get(`${ENDPOINT}/api/game/status/cooldown?gameid=${gameId}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const { status } = res.data;
        setEnabled(!status);
      })
      .catch(console.error);
  };

  useEffect(() => {
    observeBtnEnabled();
  }, []);

  return (
    <>
      {isActive ? (
        <ConfirmSelectScreen
          setIsActive={setIsActive}
          info={confirmSelectScreenInfo}
          onConfirmed={onConfirmed}
        ></ConfirmSelectScreen>
      ) : null}
      <StyledSbs>
        <StyledSelectBtn $selected={!enabled} onClick={handleSelect}>
          <StyledInfoHover
            $noPointer
            $text="You can only unselect this game within 7 days, a month after the day you selected it"
          />
          Unselect Game
        </StyledSelectBtn>
      </StyledSbs>
    </>
  );
};

export default UnselectBtn;

import { styled } from "styled-components";

export const StyledNote = styled.aside`
  display: block;
  position: absolute;
  max-width: 25%;
  font-size: 0.9rem;
  color: grey;
  padding: 8px;
  background-color: #0d0d14;

  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  left: ${(props) => props.$left};

  margin: ${(props) => props.$inset + " " + props.$inset};

  @media screen and (max-width: 700px) {
    max-width: 100%;
  }

  @media screen and (min-width: 700px) and (max-width: 1370px) {
    max-width: 80%;
  }
`;

import React from "react";

import { StyledInputItem } from "./styles/InputElements.styled";

const InputItem = ({
  $label,
  $width,
  $column,
  min,
  max,
  input,
  setInput,
  type,
  ...props
}) => {
  return (
    <StyledInputItem $width={$width} $column={$column}>
      <p>{$label}</p>
      <input
        type={type}
        value={input}
        onChange={(e) => {
          const isNumber =
            (type === "number" || !type) &&
            min != undefined &&
            max != undefined;

          let canSet = true;
          let enteredValue = e.target.value;

          if (isNumber) {
            canSet = enteredValue >= min && enteredValue <= max;
          } else if (max) {
            canSet = enteredValue.length <= max;
          }

          if (enteredValue == "" && isNumber) enteredValue = min;

          canSet && setInput(enteredValue);
        }}
        min={min}
        max={max}
        minLength={min}
        maxLength={max}
        {...props}
      />
    </StyledInputItem>
  );
};

export default InputItem;

import React, { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";

import SettingItem from "../SettingItem";

export function getProfession(isEditor, isArtist) {
  let professions = "";
  if (isEditor) {
    professions += "Video Editor";
  }
  if (isEditor && isArtist) {
    professions += " & ";
  }
  if (isArtist) {
    professions += "Thumbnail Artist";
  }

  return professions;
}

const ProductionAccountInfo = () => {
  const [professions, setProfessions] = useState("");

  useEffect(() => {
    const isEditor = parseInt(ReactSession.get("iseditor")) === 1;
    const isArtist = parseInt(ReactSession.get("isartist")) === 1;
    setProfessions(getProfession(isEditor, isArtist));
  });

  return (
    <>
      <SettingItem title="Profession" value={professions} />
      <SettingItem
        title="Avg. Edit Fulfillment Speed"
        value={ReactSession.get("edit_speed") + " days"}
        dbName="productioninformation"
        columnName="EditSpeed"
        type="number"
        min={1}
        max={5}
      />
      <SettingItem
        title="Avg. Thumbnail Fulfillment Speed"
        value={ReactSession.get("thumbnail_speed") + " days"}
        dbName="productioninformation"
        columnName="ThumbnailSpeed"
        type="number"
        min={1}
        max={5}
      />
    </>
  );
};

export default ProductionAccountInfo;

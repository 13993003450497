import React from "react";
import styled from "styled-components";

import { StyledOverlay } from "../styles/Overlay.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";

import { LinkSpan } from "_components/atoms/inline/SpanElements";

const StyledFooter = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const StyledFooterGroup = styled.div`
  position: relative;
  width: 100%;

  padding: 100px 50px 80px min(7%, 50px);

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
`;

const StyledFooterCard = styled.div`
  display: flex;

  padding-bottom: 10px;
  gap: 22px;

  & > a {
    background-image: linear-gradient(
      to right,
      var(--action-primary-alt),
      var(--action-primary)
    );
    background-size: 100%;
    filter: drop-shadow(0 0 3px var(--action-primary));

    border-radius: 10px;

    height: 55px;
    width: 55px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform 0.2s ease-in-out;
  }

  & > a:hover {
    transform: scale(1.02);
  }

  & > a img {
    height: 70%;
    width: 70%;
  }
`;

const StyledFooterText = styled.p`
  margin: 0;
  text-align: left;
`;

const StyledFooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 100px;

  & > img {
    height: 90px;
  }
`;

const Footer = () => {
  return (
    <StyledOverlay
      $clipPath="polygon(0 0, 10% 10%, 50% 0, 80% 20%, 100% 0, 100% 100%, 0 100%)"
      $from="var(--action-primary-dimmed)"
      $to="var(--main-primary-alt)"
      $yOffset="150px"
    >
      <StyledFooter>
        <StyledFooterGroup>
          <StyledFooterCard>
            <a href="https://twitter.com/pushxpull" target="_blank">
              <img src="/images/twitter-logo.png" />
            </a>
            <a href="https://www.instagram.com/pushxpullcorp/" target="_blank">
              <img src="/images/instagram-logo.png" />
            </a>
            <a href="https://www.tiktok.com/@pushxpull" target="_blank">
              <img src="/images/tiktok-logo.png" />
            </a>
          </StyledFooterCard>
          <StyledFooterText>
            <span style={{ color: "white" }}>Contact:</span>{" "}
            <LinkSpan $inverted to="/contact">
              contact@pushxpull.co
            </LinkSpan>
          </StyledFooterText>
          <StyledFooterText>
            <span style={{ color: "white" }}>© 2023-2024</span>{" "}
            <span style={{ color: "var(--action-primary)" }}>PUSHXPULL</span>
            <span style={{ color: "white" }}>. All Right Reserved</span>
          </StyledFooterText>
        </StyledFooterGroup>

        <StyledFooterLogoContainer>
          <img src="/images/brand/logo.png" />
        </StyledFooterLogoContainer>
      </StyledFooter>
    </StyledOverlay>
  );
};

export default Footer;

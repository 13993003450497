export const hexToRgba = (variable, alpha) => {
  const hex = getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

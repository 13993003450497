import React, { useState } from "react";

import Calculator from "_components/themes/calculator/Calculator";
import EarningsCalculator from "./EarningsCalculator";

const ProductionCalculator = () => {
  const [priceBracket, setPriceBracket] = useState("0-0k");
  const [lowerBracket, setLowerBracket] = useState(0);
  const [bracketSubCount, setBracketSubCount] = useState(0);

  const getLowerBracket = (subCount) => {
    subCount = subCount / 100000;
    let lowerDiff = subCount % 2.5;
    let higherDiff = subCount % 5;
    return lowerDiff < higherDiff
      ? Math.floor(subCount / 2.5) * 2.5
      : Math.floor(subCount / 5) * 5;
  };

  const getHigherBracket = (lowerBracket) => {
    const isMillion = lowerBracket >= 10;
    const quarterHundredThousand = isMillion ? 0.25 : 2.5;
    return (
      (isMillion ? lowerBracket / 10 : lowerBracket) + quarterHundredThousand
    );
  };

  return (
    <Calculator
      title="Calculate the amount you could receive from clients at different subscriber counts"
      placeholder="Client's subscriber count"
      getLowerBracket={getLowerBracket}
      getHigherBracket={getHigherBracket}
      setLowerBracket={setLowerBracket}
      setBracketSubCount={setBracketSubCount}
      setPriceBracket={setPriceBracket}
    >
      <h4 className="heading">
        Showing <b>{priceBracket}</b> sub-bracket
      </h4>
      <EarningsCalculator bracketSubCount={bracketSubCount} />
    </Calculator>
  );
};

export default ProductionCalculator;

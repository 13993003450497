class FriendFilterer {
  constructor(friends) {
    this.friends = friends;
    // Users which are always showing regardless of some filters
    this.constantFriends = this.getUsersByAccountType("Admin");
  }

  getUsersByAccountType(accountType) {
    const users = this.friends.filter(
      (user) => user.accountType === accountType
    );
    return users;
  }

  getNamesByAccountType(accountType) {
    const names = this.getUsersByAccountType(accountType).map(
      (user) => user.name
    );
    return names;
  }

  getUsersInRelationTo(username) {
    const selectedUser = this.friends.filter((user) => user.name === username);
    const groups = this.friends
      .filter((user) => user.members != undefined)
      .filter(
        (group) => group.members.find((name) => name === username) != undefined
      );
    const users = [...selectedUser, ...groups, ...this.constantFriends];
    return users;
  }

  swapOutIn(outName, inName) {
    const newData = {
      name: inName,
      accountType: "Partner", // * This may change when affiliates are added
    };
    const users = this.friends.map((user) =>
      user.name === outName ? { ...user, ...newData } : user
    );
    return users;
  }
}

export default FriendFilterer;

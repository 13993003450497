import styled, { css } from "styled-components";

export const StyledCenteredArea = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$absolute &&
    css`
      position: absolute;
      height: auto;
      top: ${(props) => props.$absolute};
    `}
`;

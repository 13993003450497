import { styled } from "styled-components";

import { DashedUnderlineVerticleStyle } from "_components/atoms/inline/styles/Decorations.styled";

export const StyledSidebar = styled.div`
  height: 400px;
  position: relative;
  width: ${(props) => props.$width};

  min-width: ${(props) => props.$minWidth};
  flex: ${(props) => props.$minWidth && `0 0 ${props.$minWidth}`};

  display: flex;
  flex-direction: column;
  gap: 10px;
  order: ${(props) => props.$order || "3"};

  &::after {
    ${DashedUnderlineVerticleStyle};
    --invis-col: #181828;
    transform: ${(props) =>
      props.$left ? "translateX(20px)" : "translateX(-20px)"};
    right: ${(props) => (props.$left ? "0" : null)};
  }

  & > h2 {
    margin: 0 0 10px 0;
    text-align: center;
  }

  @media only screen and (max-width: 1200px) {
    order: ${(props) => props.$yOrder || "1"};
    min-width: ${(props) => !props.$minWidth && "350px"};
  }
`;

export const StyledSidebarTab = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  font-size: 1.2rem;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border: none;

  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(1.1);
  }

  /* TODO: Change to onBlur? use the same as I used for form to keep it active when clicking away */
  background-color: ${(props) =>
    props.$isActive ? "var(--main-primary)" : "var(--main-background)"};
`;

export const StyledSidebarText = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  font-size: 1.2rem;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: unset;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  color: ${(props) => (props.$isActive ? "var(--action-primary)" : "white")};
`;

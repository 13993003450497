import {
  StyledBlendContainer,
  StyledBlendImg,
  StyledImgCover,
  StyledCoveredImg,
} from "../_components/molecules/structure/styles/BlendImg.styled";

import { StyledAspectRatioContainer } from "./styles/AspectRatioContainer.styled";

export const BlendContainer = ({
  $aspectRatio,
  $childScale,
  style,
  children,
  ...props
}) => {
  return (
    <StyledAspectRatioContainer
      $aspectRatio={$aspectRatio || "1:2"}
      $childScale={$childScale || "100%"}
      style={style}
    >
      <StyledBlendContainer className="ratio-child" {...props}>
        {children}
      </StyledBlendContainer>
    </StyledAspectRatioContainer>
  );
};

export const BlendImg = ({ $aspectRatio, $childScale, style, ...props }) => {
  return (
    <StyledAspectRatioContainer
      $aspectRatio={$aspectRatio || "6:1"}
      $childScale={$childScale || "100%"}
      style={style}
    >
      <StyledBlendImg className="ratio-child" {...props} />
    </StyledAspectRatioContainer>
  );
};

export const ImgCover = ({ $aspectRatio, $childScale, style, ...props }) => {
  return (
    <StyledAspectRatioContainer
      $aspectRatio={$aspectRatio || "4:1"}
      $childScale={$childScale || "100%"}
      style={style}
    >
      <StyledImgCover className="ratio-child" {...props} />
    </StyledAspectRatioContainer>
  );
};

export const CoveredImg = ({ $aspectRatio, $childScale, style, ...props }) => {
  return (
    <StyledAspectRatioContainer
      $aspectRatio={$aspectRatio || "4:1"}
      $childScale={$childScale || "100%"}
      style={style}
    >
      <StyledCoveredImg className="ratio-child" {...props} />
    </StyledAspectRatioContainer>
  );
};

import styled, { css } from "styled-components";

export const StyledXStripHeader = styled.div`
  --radius-added: 125px;
  --slant-height: 100px;

  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--radius-added) * -1.15);
    height: calc(100% + var(--radius-added) * 3.15);
    width: 100%;
    z-index: -10;
  }

  &::before {
    background-color: ${(props) => props.$col || "var(--main-primary)"};
    clip-path: polygon(
      0 var(--slant-height),
      100% 0,
      100% calc(100% - var(--slant-height)),
      0 100%
    );
  }

  ${(props) =>
    props.$pull &&
    css`
      &::before {
        background-color: var(--main-secondary);
        clip-path: polygon(
          0 0,
          100% var(--slant-height),
          100% 100%,
          0 calc(100% - var(--slant-height))
        );
      }
    `}
`;

export const StyledXStrip = styled.div`
  padding: ${(props) => props.$push == 3 && "40px 0"};
  padding: ${(props) => `${props.$paddingY} 0`};

  width: 100%;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 115%;
    width: 100%;
    z-index: -1;
    transform: ${(props) => !props.$noOffset && "translateY(-10%)"};

    ${(props) => {
      if (props.$push) {
        let clipPath;
        let bgCol = "var(--main-primary)";
        if (props.$push == 1) {
          clipPath = "polygon(0 0, max(90%, 800px) 0, 30% 100%, 0 100%)";
        } else if (props.$push == 2) {
          clipPath = "polygon(0 0, 100% 0, 100% 60%, 0 100%)";
        } else if (props.$push == 3) {
          clipPath = "polygon(0 60px, 100% 0, 100% calc(100% - 60px), 0 100%)";
        } else if (props.$push == 4) {
          clipPath = "polygon(0 30%, 100% 0, 100% 70%, 0 100%)";
          bgCol = "var(--action-primary)";
        }

        return css`
          background-color: ${bgCol};
          clip-path: ${clipPath};
        `;
      } else if (props.$pull) {
        let clipPath;
        let bgCol = "var(--main-secondary)";
        if (props.$pull == 1) {
          clipPath = "polygon(0 0, 100% 30%, 100% 100%, 0 100%)";
        } else if (props.$pull == 2) {
          clipPath = "polygon(0 0, 100% 15%, 100% 100%, 0 85%)";
        } else if (props.$pull == 3) {
          clipPath = "polygon()";
        } else if (props.$pull == 4) {
          clipPath = "polygon(0 0, 100% 30%, 100% 100%, 0 70%)";
        }

        return css`
          background-color: ${bgCol};
          clip-path: ${clipPath};
        `;
      }
    }}
  }
`;

import React, { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import UserDataOverview from "_components/molecules/displays/DataOverview/UserDataOverview";
import UserSelection from "_components/organisms/UserSelection";

const Account = () => {
  const [selectedUser, setSelectedUser] = useState("");

  return (
    <>
      <StyledStandardSpacer $paddingTop="100px" />
      <StyledDualXStrip>
        <StyledStandardSpacer $paddingTop="50px" />
        <StyledSbs
          $align="center"
          $justify="start"
          $column
          // Helps make conditional rendering of children smoother when transitioning between their different heights
          style={{ minHeight: "700px" }}
        >
          <StyledCenteredHeader>
            <h1>Account Inspector</h1>
            <p style={{ maxWidth: "580px" }}>
              View account information for each user
            </p>
          </StyledCenteredHeader>
          <StyledStandardSpacer $paddingTop="50px" />

          {selectedUser == "" ? (
            <UserSelection setSelectedUser={setSelectedUser} />
          ) : (
            <UserDataOverview
              username={selectedUser}
              goBackHandler={() => setSelectedUser("")}
            />
          )}
        </StyledSbs>
      </StyledDualXStrip>

      <StyledStandardSpacer $paddingTop="200px" />
    </>
  );
};

export default Account;

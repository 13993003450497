import styled from "styled-components";

export const StyledHeaderContainer = styled.div`
  max-height: 1000px;
  min-height: 500px;
  position: relative;
  overflow: hidden;
`;

export const StyledOverlayText = styled.div`
  padding-top: 100px;
  width: 100%;
  height: 200px;
  display: block;
  position: absolute;
  text-align: center;
  z-index: 1;

  & > h1 {
    margin: 0;
    margin-bottom: 15px;
  }
  & > p {
    margin: 0;
    color: white;
    font-weight: bold;
  }
`;

export const StyledSoldierImg = styled.img`
  position: absolute !important;
  height: 65% !important;
  bottom: 30% !important;
  opacity: 0.9;
`;

export const StyledCircleBackground = styled.div`
  position: absolute !important;
  height: 200% !important;
  min-width: 1000px !important;
  max-height: 1700px !important;
  max-width: 1900px !important;
  width: 105% !important;
  box-shadow: 0 0 0 0 #fff !important;
  background-image: linear-gradient(
    to bottom right,
    #1d1d4d,
    #0e0e23
  ) !important;
`;

import React from "react";

import { StyledStaticIconBtn } from "../styles/Buttons.styled";

const OpenFileViewerBtn = ({ setVisible, setCurrentData, data }) => {
  return (
    <StyledStaticIconBtn
      $flagWidth="90px"
      onClick={() => {
        setCurrentData(data);
        setVisible(true);
      }}
    >
      <div className="hover-flag">File Viewer</div>
      <img src="/images/buttons/folder-icon.png" />
    </StyledStaticIconBtn>
  );
};

export default OpenFileViewerBtn;

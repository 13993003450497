import React from "react";

import SettingBlock from "../SettingBlock";

import ServiceCheckout from "_components/features/checkout/ServiceCheckout";

const PartnerSettings = () => {
  return (
    <>
      <SettingBlock $height="250px" $padding="0" $flex title="Manage Payments">
        <ServiceCheckout />
      </SettingBlock>
    </>
  );
};

export default PartnerSettings;

import { StyledImgStrip, StyledStripBanner } from "./styles/StripBanner.styled";

const StripBanner = ({ children, ...props }) => {
  return (
    <StyledStripBanner>
      {children}
      <StyledImgStrip {...props} />
    </StyledStripBanner>
  );
};

export default StripBanner;

import React from "react";

import {
  StyledIncomingFile,
  StyledExpiredOverlay,
} from "_components/features/file/styles/FileElements.styled";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

import SelectBtn from "./SelectBtn";
import ViewMessageBtn from "./ViewMessageBtn";
import RequestTimeBtn from "./RequestTimeBtn";
import PassOffBtn from "./PassOffBtn";
import ViewFilesBtn from "./ViewFilesBtn";

import { dateFormattingOptions } from "js/data/constants";

const WorkItem = ({ $showClient, data, children }) => {
  const { client, type, title, duration, isRevision, ofRequestId } = data;

  const received = new Intl.DateTimeFormat(
    "en-US",
    dateFormattingOptions
  ).format(new Date(data.received));

  const due = new Intl.DateTimeFormat("en-US", dateFormattingOptions).format(
    new Date(data.due)
  );

  const isExpired =
    new Date(data.due).getTime() - new Date(data.received).getTime() < 0;

  return (
    <StyledIncomingFile $isRevision={isRevision} $requestId={ofRequestId}>
      {isExpired && <StyledExpiredOverlay />}
      <div>
        {$showClient ? (
          <p>
            <span className="title">Client: </span>
            <LinkSpan to={`/chat?user=${client}`}>{client}</LinkSpan>
          </p>
        ) : (
          <>
            <p>
              <span className="title">Title: </span>
              {title}
            </p>
            {type === "Edit" && (
              <p>
                <span className="title">Running Time: </span>
                {duration} minutes
              </p>
            )}
          </>
        )}
        <p>
          <span className="title">Type: </span>
          {type}
        </p>
        <p>
          <span className="title">Received: </span>
          {received}
        </p>
        <p>
          <span className="title">Due: </span>
          {due}
        </p>
      </div>
      {!isExpired && children}
    </StyledIncomingFile>
  );
};

export const AvailableWorkItem = ({ data, errorMessagesState }) => {
  const { takenBy } = data;

  return (
    <WorkItem data={data}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "13px",
          alignSelf: takenBy == undefined ? null : "flex-end",
        }}
      >
        {takenBy == undefined ? (
          <>
            <SelectBtn errorMessagesState={errorMessagesState} {...data} />
            <ViewMessageBtn {...data} />
          </>
        ) : (
          <p>
            <span className="title">Taken By: </span>
            <LinkSpan to={`/chat?user=${takenBy}`}>{takenBy}</LinkSpan>
          </p>
        )}
      </div>
    </WorkItem>
  );
};

export const YourWorkItem = ({ data }) => {
  return (
    <WorkItem $showClient data={data}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "13px",
        }}
      >
        {!data.isRevision && <RequestTimeBtn {...data} />}
        {/* //* Disabled until 'Rating System' is done */}
        {/* <PassOffBtn {...data} /> */}
        <ViewFilesBtn client={data.client} fileId={data.fileId} />
      </div>
    </WorkItem>
  );
};

export default WorkItem;

import type { ErrorMessage } from "js/data/formErrorMessages";

export const mappedErrorMessages = (
  message: ErrorMessage,
  ErrorComponent: (props: any) => JSX.Element
) => {
  let messages: string[];
  let links: null | string[] | (() => string)[] = [];
  if (typeof message === "object") {
    messages = message[0].split(" | ");
    links = message[1];
  } else {
    messages = [message];
  }

  return messages.map((msg, index) => {
    if (messages.length == 1 || links[index] == null) {
      return msg;
    }
    return <ErrorComponent link={links[index]}>{msg}</ErrorComponent>;
  });
};

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

export const centeredComponent = (Component) => {
  const CenteredComponent = (props) => {
    return (
      <StyledCenteredArea>
        <Component {...props}>{props.children}</Component>
      </StyledCenteredArea>
    );
  };

  const componentName = Component.displayName || Component.name || "Component";

  CenteredComponent.displayName = `centeredComponent(${componentName})`;
  return CenteredComponent;
};

/*
  Applies inline style directly to component with flexbox properties to center all contents.
  Default 'flex-direction' is 'column'
*/
export const centeredPropsComponent = (Component) => {
  const CenteredComponent = ({ style, ...props }) => {
    const centeredStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    };
    return (
      <Component
        style={{
          ...style,
          ...centeredStyle,
        }}
        {...props}
      >
        {props.children}
      </Component>
    );
  };

  const componentName = Component.displayName || Component.name || "Component";

  CenteredComponent.displayName = `centeredComponent(${componentName})`;
  return CenteredComponent;
};

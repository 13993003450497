import React from "react";

import { dateFormattingOptions } from "js/data/constants";

import { StyledIncomingFile } from "../styles/FileElements.styled";
import DownloadBtn from "../buttons/DownloadBtn";
import RevisionBtn from "../buttons/RevisionBtn";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const IncomingFile = ({ data, $highlighted }) => {
  const {
    revisionDisabled,
    requestId,
    ofRequestId,
    id,
    title,
    author,
    isRevision,
    active,
    revisions,
  } = data;

  const createdTime = new Intl.DateTimeFormat(
    "en-US",
    dateFormattingOptions
  ).format(new Date(data.createdTime));

  return (
    <StyledIncomingFile
      $isRevision={isRevision}
      $requestId={ofRequestId}
      $highlighted={$highlighted}
    >
      <div>
        <p style={{ color: "var(--action-primary)" }}>
          ID #{isRevision ? "RE" : "OG"}-{requestId}
        </p>
        <p>
          <span className="title">Title: </span>
          {title}
        </p>
        <p>
          <span className="title">Produced by: </span>
          <LinkSpan to={`/chat?user=${author}&fileid=${id}`}>{author}</LinkSpan>
        </p>
        <p>
          <span className="title">Received date: </span>
          {createdTime}
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "13px" }}>
        {/* Disabled revisions for thumbnails for now! */}
        {!revisionDisabled && data.type === "Edit" && (
          <RevisionBtn
            $revisions={revisions}
            $revisionPending={active}
            fileId={id}
          />
        )}
        <DownloadBtn fileId={id} title={title} />
      </div>
    </StyledIncomingFile>
  );
};

export default IncomingFile;

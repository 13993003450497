import { styled, css } from "styled-components";

import AspectRatioModifier from "./css/modifiers/AspectRatioModifier";

// Used to contain an aspect ratio as
// max-height & max-width don't work with padding in StyledAspectRatioContainer alone
export const StyledAspectRatioWrapper = styled.div`
  position: relative;

  max-height: ${(props) => props.$maxHeight};
  min-height: ${(props) => props.$minHeight};

  max-width: ${(props) => props.$maxWidth};
  min-width: ${(props) => props.$minWidth};

  height: 100%;
  width: ${(props) => props.$width || "100%"};

  & > div {
    position: ${(props) => !props.$maintainChildPos && "static"};
  }

  ${(props) =>
    props.$centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const StyledAspectRatioContainer = styled.div`
  position: relative;
  width: ${(props) => props.$width || "100%"};
  height: ${(props) => props.$aspectRatio == null && props.$height};

  ${(props) => AspectRatioModifier(props.$aspectRatio)};

  .ratio-child {
    height: ${(props) => props.$childScale};
    width: ${(props) => props.$childScale};
  }

  ${(props) =>
    props.$childCentered &&
    css`
      .ratio-child {
        margin-left: auto;
        margin-right: auto;
      }
    `};

  ${(props) =>
    props.$autoSized
      ? css`
          .ratio-child {
            position: relative;
            height: 100%;
            width: auto;
          }
        `
      : css`
          .ratio-child {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        `};
`;

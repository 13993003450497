import React, { useContext } from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import ContactForm from "_components/features/form/main/ContactForm";
import RedirectBlockSection from "_components/molecules/containers/common/RedirectBlockSection";

import { AccountContext } from "components/contexts/AccountContext";

const ContactUs = () => {
  const [loggedIn] = useContext(AccountContext);

  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Contact Us</h1>
        <p>Contact us about a specific issue</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <ContactForm />

      <StyledStandardSpacer $paddingTop="400px" />
      <RedirectBlockSection
        $primary="var(--action-primary)"
        $maxWidth="450px"
        heading="Chat with us live!"
        subHeading="If you have an account with us, then you can chat with us live by
        using our in-built chat system"
        to={loggedIn ? "/chat?user=admin" : "/join"}
        ctaText={loggedIn ? "Live Chat" : "Join Now"}
      />
    </StyledStandardEndSection>
  );
};

export default ContactUs;

import styled, { css } from "styled-components";

const topFlagStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  transform: translateY(-50%);
  top: 0;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;

  width: 100px;
  background-color: var(--main-secondary-alt);
  color: var(--action-primary);
  padding: 5px;
  border-radius: 5px;
`;

export const StyledOtherOption = styled.div`
  padding: 15px;
  max-width: 250px;
  border-radius: 10px;
  background-color: var(--main-secondary);

  & > p {
    margin: 0;
    margin-top: 5px;
    line-height: 1.3rem;
    color: white;
  }

  .top-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 15px;

    & > img {
      height: 40px;
    }
  }
`;

export const StyledUploadingItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--main-secondary);
  width: 100%;
  position: relative;

  border-radius: 5px;

  padding: 10px;

  & > div > h3 {
    margin: 0;
  }

  & > div > p {
    line-height: 1rem;
    margin: 0;
  }

  .progress-indicator {
    color: var(--action-primary);
  }
`;

export const StyledProgressBar = styled.div`
  height: 2px;
  top: 0px;
  left: 0px;
  right: 0px;

  background-color: var(--main-secondary-alt);
  position: absolute;

  overflow: hidden;

  &::before {
    content: "";
    background-color: var(--action-primary);
    height: 100%;
    width: ${(props) => props.$progress + "%"};
    position: absolute;
    display: block;
    left: 0;
    top: 0;
  }

  p {
    color: white;
    z-index: 1;
    user-select: none;
  }
`;

export const StyledIncomingFile = styled.div`
  --padding: 15px;

  position: relative;

  padding: var(--padding);
  width: min(700px, 90%);
  background-color: ${(props) =>
    props.$light ? "var(--main-background)" : "var(--main-secondary)"};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 10px;

  & > div > p {
    color: white;
    margin: 0;
    line-height: 1.3rem;
  }

  .title {
    color: var(--sub-info-light);
  }

  ${(props) =>
    props.$isRevision &&
    css`
      &::before {
        ${topFlagStyle}
        width: min(180px, 100%);
        text-align: center;
        content: "Revision of #${props.$requestId}";
        transform: ${(props) => props.$highlighted && "translate(-50%, -50%)"};
      }
    `}

  ${(props) =>
    props.$highlighted &&
    css`
      border: solid 1px var(--action-primary);

      &::after {
        ${topFlagStyle}
        content: "Highlighted";
        background-color: var(--action-primary);
        color: var(--main-secondary);
        transform: ${(props) => props.$isRevision && "translate(50%, -50%)"};
      }
    `}
`;

export const StyledPendingFile = styled.div`
  --padding: 15px;

  position: relative;

  padding: var(--padding);
  width: min(700px, 90%);
  background-color: var(--main-secondary);
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 10px;

  & > p {
    color: var(--sub-info-light);
    margin: 0;
    line-height: 1.3rem;

    & > span {
      color: white;
    }
  }
`;

export const StyledExpiredOverlay = styled.div`
  user-select: none;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--main-secondary-dark);
  z-index: 3;

  opacity: 0.9;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;

  &::after {
    content: "Request Expired"; // TODO: May need future text options like 'Request Cancelled'
    color: var(--exit-main);
    font-weight: 100;
    font-size: 1.5rem;
  }
`;

import React, { useState } from "react";

import { StyledIconBtn } from "_components/features/file/styles/Buttons.styled";
import ConfirmSelectScreen from "_components/molecules/containers/overlays/ConfirmSelectScreen";

const IconPopupBtn = ({
  $width,
  $flagWidth,
  enabled,
  setEnabled,
  confirmSelectScreenInfo,
  onConfirmed,
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleSelect = (event) => {
    event.preventDefault();
    if (!enabled) {
      setIsActive(false);
      return;
    }
    setIsActive(true);
  };

  const confirmed = async () => {
    onConfirmed();
    setEnabled(false);
  };

  return (
    <>
      {isActive && (
        <ConfirmSelectScreen
          $width={$width}
          noScrollLock={true}
          setIsActive={setIsActive}
          info={confirmSelectScreenInfo}
          onConfirmed={confirmed}
        />
      )}
      <StyledIconBtn
        $enabled={enabled}
        $flagWidth={$flagWidth || (enabled ? "auto" : "150px")}
        onClick={handleSelect}
      >
        {children}
      </StyledIconBtn>
    </>
  );
};

export default IconPopupBtn;

import React, { useContext, useState } from "react";

import HomeHeaderSbs from "components/bundles/HomeHeaderSbs";

import { Cta, CtaAlt } from "components/CtaElements";
import CircleGfx from "components/objects/CircleGfx";

import {
  StyledInfoBlock,
  StyledInfoBlockH1,
  StyledInfoBlockP,
} from "_components/molecules/containers/common/styles/StyledInfoBlock.styled";
import { LargeX, StyledLargeX } from "components/styles/brand/XElements.styled";

import { QueryContext } from "components/contexts/QueryContext";
import { AccountContext } from "components/contexts/AccountContext";

function HomeHeader({ name }) {
  const [isHovering, setIsHovering] = useState(false);
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  const { queryWrap } = useContext(QueryContext);

  return (
    <section
      name={name}
      style={{
        padding: "25px 0 5% 0",
        maxHeight: !queryWrap.matches ? "1300px" : null,
        minHeight: queryWrap.matches ? "800px" : null,
        position: "relative",
        overflow: "hidden",
        height: "1000px",
      }}
    >
      <div style={{ backgroundColor: "unset" }}>
        <LargeX style={{ zIndex: 1 }} $originAt="-25%" />
        {!queryWrap.matches && (
          <StyledLargeX $originAt="40%" $height="120%">
            <img
              style={{
                zIndex: 0,
                clipPath: `polygon(
                    1% 50%,
                    30% 0, 70% 0,
                    100% 50%,
                    70% 100%, 30% 100%
                  )`,
              }}
              src="/images/brand/game-collage.jpg"
            />
          </StyledLargeX>
        )}
      </div>

      <HomeHeaderSbs
        style={{
          display: queryWrap.matches ? "block" : null,
        }}
      >
        <StyledInfoBlock
          style={{
            zIndex: 2,
            paddingTop: queryWrap.matches ? "max(17%, 100px)" : "0",
            position: queryWrap.matches ? "absolute" : null,
            transform: !queryWrap.matches ? "translateX(-40%)" : undefined,
          }}
        >
          <StyledInfoBlockH1 $maxWidth="720px">
            Channel growth for Roblox Youtubers
          </StyledInfoBlockH1>
          <StyledInfoBlockP
            $maxWidth="520px"
            style={{
              margin: "35px 0",
            }}
          >
            Connecting Roblox Game Studios with Roblox Youtubers for the benefit
            of one another.
          </StyledInfoBlockP>
          <div>
            <Cta
              to={loggedIn ? "/profile" : "/join"}
              onMouseOver={() => setIsHovering(true)}
              onMouseOut={() => setIsHovering(false)}
            >
              {loggedIn ? "View Profile" : "Get Started"}
            </Cta>
            <CtaAlt to="/content">View Content</CtaAlt>
          </div>
        </StyledInfoBlock>
      </HomeHeaderSbs>
    </section>
  );
}

export default HomeHeader;

import React, { useState, useEffect } from "react";
import axios from "axios";

import { StyledFlexbox } from "components/styles/SideBySide.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import {
  firstColumnIndent,
  StyledMilestoneTracker,
  StyledLineContainer as Container,
  StyledLineConnector as Connector,
} from "_components/themes/data/styles/MilestoneTracker.styled";
import Milestone from "_components/themes/data/MilestoneElement";

import DirectionalBtn from "_components/atoms/buttons/DirectionalBtn";
import StaffPayment from "./StaffPayment";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";
import { formatDate } from "js/util/formatters";

const firstMilestone = "requestOpened"; // Not included in response, but will always exist as initial in this UI
const alignedWithLineStyle = {
  marginLeft: `calc(${firstColumnIndent} - 30px)`,
};

const milestonesByColumn = [
  [firstMilestone, "requestActivated", "requestClosed"],
  ["moreTimeSent", "passOffSent"],
];

const ProductTracker = ({ data, goBackHandler }) => {
  const [statusData, setStatusData] = useState({});
  const [currentMilestone, setCurrentMilestone] = useState("");

  const isVideo = data.isRevision !== true;

  function isHighlighted(milestone) {
    return currentMilestone === milestone;
  }

  /*
    Used on a 'Milestone' with $line="full" to highlight the line even
    when the milestone isn't the current but one on the same column is.

    Chains down columns to highlight columns before the current indent
  */
  function isLineHighlighted(column) {
    if (true) return false; // * Disabled as it's ugly

    let currentCol = column;
    while (currentCol > -1) {
      const rows = milestonesByColumn[currentCol];
      for (const milestone of rows) {
        if (isHighlighted(milestone)) return true;
      }
      currentCol--;
    }
    return false;
  }

  async function loadData() {
    try {
      const response = await axios.get(`${ENDPOINT}/api/payment/product`, {
        params: {
          type: isVideo ? "status" : "status-revision",
          id: data.requestId,
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      });
      setStatusData(response.data);

      const ranks = response.data.ranks;

      let currentMilestone = firstMilestone;
      for (const milestone of ranks) {
        const value = response.data[milestone];
        // Values are either (true and false) or (any and undefined)
        if (value === true || (value !== false && value !== undefined)) {
          currentMilestone = milestone;
        }
      }

      setCurrentMilestone(currentMilestone);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <StyledMilestoneTracker>
        <DirectionalBtn
          style={alignedWithLineStyle}
          text="Return to Request Log"
          onClick={goBackHandler}
        />
        <StyledFlexbox style={alignedWithLineStyle}>
          <h1>Product Tracker</h1>
          <p>Displays progress of product</p>
        </StyledFlexbox>

        <Container $first>
          <Milestone
            $line="full"
            $first
            $active
            $highlighted={isHighlighted(firstMilestone)}
            $lineHighlighted={isLineHighlighted(0)}
          >
            <p>Request Opened</p>
            <p># {data.requestId}</p>
          </Milestone>

          {isVideo && (
            <>
              <Milestone
                $gap="connect"
                $active={statusData.requestActivated === true}
                $highlighted={isHighlighted("requestActivated")}
              >
                Request Activated (by Staff)
              </Milestone>

              <Connector $lineHighlighted={isLineHighlighted(1)}>
                <Container>
                  <Milestone
                    $offset="270px"
                    $line="full"
                    $first
                    $gap="connect"
                    $active={statusData.moreTimeSent === true}
                    $highlighted={isHighlighted("moreTimeSent")}
                    $lineHighlighted={isLineHighlighted(1)}
                  >
                    <p>More Time Requested (by Staff)</p>
                    {statusData.moreTimeDuration != undefined && (
                      <p>Duration: {statusData.moreTimeDuration} days</p>
                    )}
                  </Milestone>

                  <Connector $lineHighlighted={isHighlighted("moreTimeStatus")}>
                    <Container>
                      <Milestone
                        $line
                        $first
                        $last="cut"
                        $active={statusData.moreTimeStatus != undefined}
                        $highlighted={isHighlighted("moreTimeStatus")}
                        $lineHighlighted={isHighlighted("moreTimeStatus")}
                      >
                        <p>Response received (from Partner)</p>
                        {statusData.moreTimeStatus != undefined && (
                          <p>{statusData.moreTimeStatus}</p>
                        )}
                      </Milestone>
                    </Container>
                  </Connector>

                  <Milestone
                    $gap="connect"
                    $active={statusData.passOffSent === true}
                    $highlighted={isHighlighted("passOffSent")}
                  >
                    <p>Pass Off Requested (by Staff)</p>
                  </Milestone>

                  <Connector $lineHighlighted={isHighlighted("passOffStatus")}>
                    <Container>
                      <Milestone
                        $line
                        $first
                        $last="cut"
                        $active={statusData.passOffStatus != undefined}
                        $highlighted={isHighlighted("passOffStatus")}
                        $lineHighlighted={isHighlighted("passOffStatus")}
                      >
                        <p>Response received (from Admin & New Staff)</p>
                        {statusData.passOffStatus != undefined && (
                          <p>{statusData.passOffStatus}</p>
                        )}
                        {statusData.newStaff != undefined && (
                          <p>New staff user: @{statusData.newStaff}</p>
                        )}
                      </Milestone>
                    </Container>
                  </Connector>
                </Container>
              </Connector>
            </>
          )}

          <Milestone
            $col="var(--main-primary)"
            $active={statusData.closeReason != undefined}
            $highlighted={isHighlighted("requestClosed")}
          >
            <p>Request Closed</p>
            {statusData.closedOn != undefined && (
              <p>@ {formatDate(statusData.closedOn)}</p>
            )}
            {statusData.closeReason != undefined && (
              <p>via {statusData.closeReason}</p>
            )}
          </Milestone>
        </Container>

        {isVideo &&
          isHighlighted("requestClosed") &&
          statusData.wasFulfilled && (
            <StaffPayment
              requestId={data.requestId}
              style={alignedWithLineStyle}
            />
          )}

        <StyledStandardSpacer $paddingTop="100px" />
      </StyledMilestoneTracker>
    </>
  );
};

export default ProductTracker;

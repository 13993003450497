import React, { useEffect, useState } from "react";
import axios from "axios";

import { StyledStatBlock, StyledStat } from "../styles/Stats.styled";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import gameDatas from "js/data/gameDatas";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const UserStats = ({ gameTitle }) => {
  const [userStats, setUserStats] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const loadUserStats = async () => {
    const gameId = gameDatas.find((data) => data.game === gameTitle)?.id;
    if (!gameId) {
      console.error(`No game id found for game title: ${gameTitle}`);
      return;
    }

    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/game/list-user`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const { selectedGames, gamePercentages } = res.data;
        const index = selectedGames.indexOf(gameId.toString());
        if (index !== -1) {
          const gamePercentage = gamePercentages[index];
          setUserStats({ ...userStats, ownership: gamePercentage });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    loadUserStats();
  }, []);

  return (
    <StyledStatBlock>
      <h1 style={{ color: "var(--action-primary)" }}>Your Stats</h1>

      <LoadingStatus
        status={isLoading ? 0 : Object.entries(userStats).length > 0}
        style={{ textAlign: "center" }}
      />

      <StyledStat $typeIndex={1}>
        <p className="heading">Percentage Ownership</p>
        <p className="info">{userStats.ownership}%</p>
      </StyledStat>
    </StyledStatBlock>
  );
};

export default UserStats;

import React, { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredHeader } from "components/styles/common/Headings.styled";

import UserSelection from "_components/organisms/UserSelection";
import TeamBuilder from "_components/molecules/displays/TeamBuilder";

const Team = () => {
  const [selectedUser, setSelectedUser] = useState("");

  return (
    <>
      <StyledStandardSpacer $paddingTop="100px" />
      <StyledDualXStrip>
        <StyledStandardSpacer $paddingTop="50px" />
        <StyledSbs $align="center" $justify="center" $column>
          <StyledCenteredHeader>
            <h1>Team Builder</h1>
            <p style={{ maxWidth: "580px" }}>
              Add and remove staff from each Partner's team, or edit friends in
              general for each user
            </p>
          </StyledCenteredHeader>
          <StyledStandardSpacer $paddingTop="50px" />

          {selectedUser == "" ? (
            <UserSelection setSelectedUser={setSelectedUser} />
          ) : (
            <TeamBuilder
              username={selectedUser}
              goBackHandler={() => setSelectedUser("")}
            />
          )}
        </StyledSbs>
      </StyledDualXStrip>

      <StyledStandardSpacer $paddingTop="200px" />
    </>
  );
};

export default Team;

import React from "react";
import styled from "styled-components";

const StyledDirectionalBtn = styled.button`
  --size: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: var(--size);

  margin-bottom: 10px;

  border: none;
  background-color: unset;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(1.2);
  }

  & > img {
    height: var(--size);
  }

  & > p {
    font-size: var(--size);
    color: var(--action-primary);
  }
`;

const DirectionalBtn = ({
  text,
  onClick,
  src = "/images/buttons/directional-arrow-icon.png",
  ...props
}) => {
  return (
    <StyledDirectionalBtn onClick={onClick} {...props}>
      <img src={src} />
      <p>{text}</p>
    </StyledDirectionalBtn>
  );
};

export default DirectionalBtn;

import { css } from "styled-components";

const AspectRatioModifier = (aspectRatio) => {
  if (!aspectRatio) return;
  const [x, y] = aspectRatio.split(":");
  const paddingTop = `${(y / x) * 100}%`;

  return css`
    padding-top: ${paddingTop};
  `;
};

export default AspectRatioModifier;

import styled from "styled-components";

export const StyledFeatureOverviewBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  padding: 40px;
  border-radius: 20px;
  width: 506px;
  height: 374px;

  background-color: var(--main-background);

  & > div {
    & > h2 {
      color: var(--action-primary);
      text-align: center;
      margin: 0;
    }

    & > p {
      color: var(--sub-info-alt);
      text-align: center;
      max-width: 350px;
      margin: 0;
    }
  }

  img {
    height: 50%;
  }
`;

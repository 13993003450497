import React from "react";

import {
  StyledChatMessageContainer,
  StyledChatMessageHeader,
} from "../styles/ChatElements.styled";

import ReferenceFile from "./ReferenceFile";

import { dateFormattingOptions } from "js/data/constants";

function isValidDate(date) {
  // Check if the provided date is a valid Date object
  return date instanceof Date && !isNaN(date);
}

const ChatMessage = ({
  children: msg,
  username,
  $date,
  $rank,
  $referenceFileId,
}) => {
  let date = new Date($date);
  let formattedDate;

  if (isValidDate(date)) {
    formattedDate = new Intl.DateTimeFormat(
      "en-US",
      dateFormattingOptions
    ).format(date);
  } else {
    // Don't display a date, as it's invalid
    formattedDate = "";
  }

  return (
    <StyledChatMessageContainer>
      {$referenceFileId && (
        <ReferenceFile $referenceFileId={$referenceFileId} />
      )}
      <StyledChatMessageHeader $date={formattedDate} $rank={$rank}>
        {username}
      </StyledChatMessageHeader>
      <p style={{ color: "white", margin: "0" }}>{msg}</p>
    </StyledChatMessageContainer>
  );
};

export default ChatMessage;

import { styled } from "styled-components";

import { StyledBackgroundImg } from "./BackgroundImg.styled";

export const StyledCharacterBackgroundImg = styled(StyledBackgroundImg)`
  left: ${(props) => props.$left};
  right: ${(props) => props.$right};

  @media screen and (max-width: 1000px) {
    left: -300px;
    opacity: ${(props) => (props.$right ? 0 : 1)};
  }
`;

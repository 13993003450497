import styled from "styled-components";

import { thinBtnStyle } from "_components/atoms/buttons/styles/ThinButtons.styled";

export const StyledInfoStrip = styled.div`
  --col: ${(props) => props.$col || "var(--action-primary)"};

  background-color: var(--main-secondary);
  width: 100%;
  padding: 50px 0;

  border: ${(props) => !props.$noBorder && "3px solid var(--col)"};
  border-left-style: none;
  border-right-style: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 7px;

  filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 35%));

  & > * {
    max-width: ${(props) => props.$maxWidth || "500px"};
  }

  & > h1,
  & > p {
    margin: 0;
    text-align: ${(props) => props.$align || "center"};
  }

  & > h1 {
    margin-bottom: 10px;
  }

  & > button {
    margin-top: 20px;
  }

  & > p {
    line-height: 1.8rem;
    width: 100%;
  }

  & > button {
    ${thinBtnStyle}
    background-color: var(--col);
  }
`;

import styled from "styled-components";

export const StyledLargeX = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* top: 0; */
  /* overflow: hidden; */

  & > img {
    /* width: 100%; */

    height: ${(props) => props.$height || "180%"};
    transform: ${(props) =>
      props.$originAt ? `translate(${props.$originAt}, 0)` : "translate(0, 0)"};
  }

  /* Centered */
  @media screen and (max-width: 1370px) {
    & > img {
      transform: translate(0, 0);
    }
  }
`;

export const LargeX = ({ src, ...props }) => {
  return (
    <StyledLargeX {...props}>
      <img src={src || "/images/brand/large-x.png"} />
    </StyledLargeX>
  );
};

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ReactSession } from "react-client-session";

import { StyledSbs } from "components/styles/SideBySide.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";

import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import TeamProfile from "../elements/TeamProfile";
import FormSelect from "_components/features/form/elements/FormSelect";

import { FriendsContext } from "_components/function/contexts/FriendsContext";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";
import FriendFilterer from "js/util/FriendFilterer";

const TeamView = () => {
  const friends = useContext(FriendsContext);

  const accountType = ReactSession.get("useraccounttype");
  const friendFilterer = new FriendFilterer(friends);

  const [selectedClient, setSelectedClient] = useState("");
  const [clientUsernames, setClientUsernames] = useState([]);
  const [staffData, setStaffData] = useState([]);

  const fetchStaffData = async (username) => {
    axios
      .get(`${ENDPOINT}/api/comm/team?uid=${username}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => setStaffData(res.data))
      .catch(console.error);
  };

  useEffect(
    () => setClientUsernames(friendFilterer.getNamesByAccountType("Partner")),
    []
  );
  useEffect(() => {
    if (accountType === "Production") {
      fetchStaffData(selectedClient);
      return;
    }
    fetchStaffData(ReactSession.get("useruid"));
  }, [selectedClient]);

  return (
    <StyledDualXStrip>
      <StyledSbs $gap="15px" $column>
        <StyledSectionH2>
          {accountType === "Production" ? "The" : "Your"} Team
        </StyledSectionH2>
        {accountType === "Production" && (
          <FormSelect
            setSelection={setSelectedClient}
            optionsList={clientUsernames}
            placeholder="-- SELECT A CLIENT --"
          />
        )}
      </StyledSbs>
      <StyledStandardSpacer $paddingTop="50px" />
      <StyledSbs $gap="40px" $shadowed $defaultWrap $paddingX="5%">
        {staffData.map((data) => (
          <TeamProfile {...data} />
        ))}
      </StyledSbs>
      <StyledStandardSpacer $paddingTop="50px" />
    </StyledDualXStrip>
  );
};

export default TeamView;

import React from "react";

import {
  StyledChatBar,
  StyledChatTextInput,
  StyledChatBtn,
} from "../styles/ChatElements.styled";

const ChatBar = ({ messageBody, setMessageBody, submitHandler }) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };

  return (
    <StyledChatBar>
      <StyledChatTextInput
        type="text"
        placeholder="Your message here.."
        value={messageBody}
        onChange={(e) => setMessageBody(e.target.value)}
        maxLength={200}
        onKeyPress={handleKeyPress}
      />
      <StyledChatBtn onClick={submitHandler} />
    </StyledChatBar>
  );
};

export default ChatBar;

import { styled } from "styled-components";

export const StyledBackground = styled.div`
  display: block;
  position: ${({ $position }) => $position || "absolute"};
  /* position: absolute; */
  top: 0;

  z-index: ${(props) => props.$layer || -10};

  /* pointer-events: none; */
  /* user-select: none; */

  width: 100%;
  height: ${(props) => props.height};

  background-color: ${(props) => props.$color};
  background-image: ${(props) => `url(${props.src})`};
  background-repeat: repeat-y;
  background-size: ${(props) => props.$bgSize || "contain"};
`;

export const StyledTransparentBackground = styled(StyledBackground)`
  position: absolute;
  top: 0;
  background-color: #181828;
  z-index: -9;
  opacity: 0.93;
`;

import React from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import Workload from "_components/features/scheduler/main/Workload";
import TeamView from "_components/features/scheduler/main/TeamView";
import TimeOff from "_components/features/scheduler/main/TimeOff";

const Scheduler = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
        <h1>Scheduler</h1>
        <p>Co-ordinate your workflow with your teams</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <Workload />

      <StyledStandardSpacer $paddingTop="400px" />
      <TeamView />

      <StyledStandardSpacer $paddingTop="400px" />
      <TimeOff />
    </StyledStandardEndSection>
  );
};

export default Scheduler;

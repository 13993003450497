import React from "react";
import OurServicesSbs from "components/bundles/OurServicesSbs";
import { BlendImg } from "components/BlendElements";

import { StyledXStrip } from "../XStrip.styled";

function OurServices() {
  return (
    <>
      <BlendImg style={{ zIndex: 2 }} src="/images/layered-peaks-haikei.png" />
      <StyledXStrip $push={1} $paddingY="400px">
        <OurServicesSbs $gap="15%" />
      </StyledXStrip>
    </>
  );
}

export default OurServices;

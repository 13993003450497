import React, { useState } from "react";

import {
  StyledOverlayBackground,
  GlobalStyleOverride,
} from "./styles/Overlay.styled";
import { StyledSelectScreen } from "_components/molecules/containers/overlays/styles/SelectScreen.styled";

import { StyledFormTitle } from "components/Form";
import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

const EnterValueScreen = ({
  setIsActive,
  setConfirmed,
  info,
  children: inputItems,
}) => {
  const handleExitClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) setIsActive(false);
  };

  const handleConfirmClick = (event) => {
    event.preventDefault();
    setConfirmed(true);
  };

  return (
    <StyledOverlayBackground $centered onClick={handleExitClick}>
      <GlobalStyleOverride />
      <StyledSelectScreen>
        {info.title && (
          <StyledFormTitle
            $noMinHeight
            $width="70%"
            $bottom="0"
            style={{ color: "var(--action-primary)", fontSize: "2rem" }}
          >
            {info.title}
          </StyledFormTitle>
        )}
        {info.description}

        {inputItems}

        <div>
          <ThinBtn
            onClick={handleConfirmClick}
            $primary="var(--action-primary)"
          >
            Confirm
          </ThinBtn>
          <ThinBtn onClick={handleExitClick} $primary="var(--main-secondary)">
            Cancel
          </ThinBtn>
        </div>
      </StyledSelectScreen>
    </StyledOverlayBackground>
  );
};

export default EnterValueScreen;

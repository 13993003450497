import React, { useEffect, useState } from "react";
import axios from "axios";

import DataOverview from "./index";
import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { getProfession } from "_components/features/profile/settings/collections/ProductionAccountInfo";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const UserDataOverview = ({ username, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  const configs = {
    users: (data) => {
      return {
        heading: "General",
        entries: [
          {
            title: "#",
            value: data.UserId,
          },
          {
            title: "Email",
            value: data.Email,
          },
          {
            title: "Account Type",
            value: data.AccountType,
          },
          {
            title: "Rating",
            value: data.Rating,
          },
        ],
      };
    },

    productionInformation: (data) => {
      return {
        heading: "Production Information",
        entries: [
          {
            title: "Profession",
            value: getProfession(data.IsEditor, data.IsArtist),
          },
          {
            title: "On Time Off",
            value: data.OnTimeOff == 1 ? "Yes" : "No",
          },
          {
            title: "PayPal",
            value: data.PayPal,
          },
          {
            title: "Edit Speed",
            value: `${data.EditSpeed} days`,
          },
          {
            title: "Thumbnail Speed",
            value: `${data.ThumbnailSpeed} days`,
          },
        ],
      };
    },
  };

  const fetchUserData = async (db, mapper) => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/user/data`, {
        params: {
          method: "row",
          db: db,
          uid: username,
          first: true,
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      });

      const addedData = mapper(response.data);

      function isDataNonExisting() {
        return (
          userData.find((data) => data.heading === addedData.heading) ==
          undefined
        );
      }

      setIsLoading(false);
      if (isDataNonExisting()) setUserData([...userData, addedData]);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  function loadDataConfigsOfUser() {
    function getData(heading) {
      return userData.find((data) => data.heading === heading);
    }

    function getValue(data, key) {
      if (!data) return;
      return data.entries.find((x) => x.title === key)?.value;
    }

    const general = getData("General");

    fetchUserData("users", configs.users);

    if (getValue(general, "Account Type") === "Production") {
      fetchUserData("productioninformation", configs.productionInformation);
    }
  }

  useEffect(() => {
    loadDataConfigsOfUser();
  }, [userData]);

  if (userData.length == 0) {
    return <LoadingStatus status={isLoading ? 0 : userData.length > 0} />;
  }

  return (
    <DataOverview username={username} data={userData} {...props}></DataOverview>
  );
};

export default UserDataOverview;

import React, { useState } from "react";

import { StyledIconBtn } from "_components/features/file/styles/Buttons.styled";
import PopupScreenView from "_components/molecules/containers/overlays/PopupScreenView";

const IconOpenViewBtn = ({
  $width,
  $flagWidth,
  $important,
  enabled,
  info,
  onChange,
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleSelect = (event) => {
    event.preventDefault();
    if (!enabled) {
      setIsActive(false);
      onChange && onChange(false);
      return;
    }
    setIsActive(true);
    onChange && onChange(true);
  };

  return (
    <>
      {isActive && (
        <PopupScreenView
          $width={$width}
          noScrollLock={true}
          setIsActive={(value) => {
            setIsActive(value);
            onChange && onChange(value);
          }}
          info={info}
        >
          {/* Fragment safe-checking. As they don't have props */}
          {children.filter((element) => element.props?.name === "button")}
        </PopupScreenView>
      )}
      <StyledIconBtn
        $enabled={enabled}
        $flagWidth={$flagWidth || (enabled ? "auto" : "150px")}
        $important={$important}
        onClick={handleSelect}
      >
        {children.filter((element) => element.props?.name !== "button")}
      </StyledIconBtn>
    </>
  );
};

export default IconOpenViewBtn;

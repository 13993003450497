import styled from "styled-components";

export const StyledSelectScreen = styled.div`
  background-color: var(--main-background);
  padding: 30px;
  border-radius: 15px;
  width: min(
    90%,
    ${(props) => (props.$width === "large" ? "700px" : props.$width) || "400px"}
  );

  & > p {
    margin: 25px 0;
    color: white;
    text-align: left;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .space-between {
    justify-content: space-between;
  }

  & > .centered-container {
    justify-content: center;
  }
`;

export const StyledSelectP = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.$last && "10px"};
  width: ${(props) => props.$width};
  text-align: ${(props) => props.$centered && "center"};
`;

export const StyledSelectFlexbox = styled.div`
  width: 100%;
  margin-top: 20px;
  gap: 10px;

  display: flex;
  flex-direction: ${(props) => (props.$column ? "column" : "row")};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

import styled from "styled-components";

export const StyledGamesContainer = styled.div`
  width: 100%;
  position: relative;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 60px;
  margin-left: auto;
  margin-right: auto;

  padding: 200px 5% 50px 5%;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledGameCard = styled.div`
  --border-radius: 15px;

  border-radius: var(--border-radius);

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: #12121d;

  transition: 0.3s all ease-in-out;
  cursor: pointer;

  & > img {
    width: 100%;
    object-fit: cover;

    border-radius: var(--border-radius) var(--border-radius) 0 0;
    transition: 0.3s all ease-in-out;
  }

  & > div {
    --padding: 25px;
    --padding-top: 10px;
    height: auto;
    padding: var(--padding-top) var(--padding) var(--padding) var(--padding);
    margin: 0;
  }

  & > div > h1 {
    color: #9278ff;
    font-weight: 100;
    margin: 0;
  }

  & > div > h4 {
    color: #6b6b6b;
    margin: 0;
  }

  box-shadow: 0 0 0px black;

  &:hover {
    background-color: #9278ff;

    & > div > h1,
    & > div > h4 {
      color: black;
    }

    & > img {
      filter: brightness(1.3);
    }
  }

  /* &:hover {
    filter: brightness(1.2);
    transform: translate(-10px, -10px);
    box-shadow: 10px 10px 0px #00b1ff;
  }

  &:active {
    filter: brightness(1.2);
    transform: translate(-5px, -5px);
    box-shadow: 5px 5px 0px lime;
  } */
`;

import React from "react";

import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

import FulFillmentForm from "_components/features/form/main/FulfillmentForm";

const ProductionUploadSystem = () => {
  return (
    <>
      <StyledSbs
        $gap="20px"
        $align="start"
        $justify="start"
        $column
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "var(--main-background)",
          padding: "50px",
          borderRadius: "10px",
        }}
      >
        <StyledCenteredArea>
          <FulFillmentForm />
        </StyledCenteredArea>
      </StyledSbs>
    </>
  );
};

export default ProductionUploadSystem;

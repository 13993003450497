import React from "react";

import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledXStrip } from "_components/themes/brand/XStrip.styled";

const ComparisonContainer = ({ title, children }) => {
  return (
    <StyledXStrip $noOffset $push={3} style={{ height: "auto" }}>
      <StyledSbs $column style={{ height: "auto" }}>
        <h1>{title}</h1>
        <StyledSbs $align="start">{children}</StyledSbs>
      </StyledSbs>
    </StyledXStrip>
  );
};

export default ComparisonContainer;

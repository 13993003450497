import { useEffect, useState } from "react";
import axios from "axios";

import { BlendContainer } from "../BlendElements";
import {
  StyledImgCover,
  StyledCoveredImg,
} from "../../_components/molecules/structure/styles/BlendImg.styled";
import { StyledAspectRatioWrapper } from "../styles/AspectRatioContainer.styled";
import {
  StyledStripWrapper,
  StyledHeadingStrip,
  StyledSubStrip,
} from "../styles/StripBanner.styled";

import {
  CompanyTrackedStat,
  StyledCompanyStatsContainer,
} from "../objects/CompanyStats";

import { ENDPOINT } from "js/data/constants";

const CompanyStatView = () => {
  const [companyStats, setCompanyStats] = useState([]);

  async function loadCompanyStats() {
    axios
      .get(`${ENDPOINT}/api/global/drive/company-stats`, {
        responseType: "blob", // Set the response type to 'blob'
      })
      .then(async ({ data: blob }) =>
        setCompanyStats(JSON.parse(await blob.text()))
      )
      .catch((error) => {
        console.error("Download Failed! Error", error);
      });
  }

  useEffect(() => {
    loadCompanyStats();
  }, []);

  return (
    <section style={{ position: "relative" }}>
      <StyledAspectRatioWrapper $maxHeight="1000px" $minHeight="600px">
        <BlendContainer $aspectRatio="1:1">
          <StyledStripWrapper>
            <StyledHeadingStrip>Company Statistics</StyledHeadingStrip>
            <StyledSubStrip $actionCol>July 2024</StyledSubStrip>
          </StyledStripWrapper>
          <StyledImgCover $height="40%" src="/images/wave-haikei.png" />
          <StyledCoveredImg src="/images/bettercalltim-gfx.jpg" />
          <StyledImgCover
            $height="40%"
            src="/images/wave-haikei.png"
            style={{ rotate: "180deg", bottom: 0 }}
          />
        </BlendContainer>
      </StyledAspectRatioWrapper>
      <StyledCompanyStatsContainer>
        {companyStats.map(({ value, desc }, index) => (
          <CompanyTrackedStat
            key={index}
            value={value}
            desc={desc}
            index={index}
          ></CompanyTrackedStat>
        ))}
      </StyledCompanyStatsContainer>
    </section>
  );
};

export default CompanyStatView;

import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";

import { StyledDownloadBtn } from "_components/features/file/styles/Buttons.styled";

import { DownloadContext } from "components/contexts/DownloadContext";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const DownloadBtn = ({ fileId, title, ...props }) => {
  const hasLoaded = useRef(false);
  const downloadBtnRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [
    [pendingDownloads, setPendingDownloads],
    [activeDownloads, setActiveDownloads],
  ] = useContext(DownloadContext);

  const downloadUrl = `${ENDPOINT}/api/drive/download/${fileId}`;

  const downloadByUrl = (url) => {
    downloadBtnRef.current.href = url;
    downloadBtnRef.current.download = title;
    downloadBtnRef.current.click();

    URL.revokeObjectURL(downloadBtnRef.current.href);
    downloadBtnRef.current.href = "";
    downloadBtnRef.current.download = "";
  };

  const resetDownloadState = () => {
    setIsDownloading(false);
    setDownloadProgress(0);
    setActiveDownloads(
      activeDownloads.filter((thisFileId) => thisFileId !== fileId)
    );
  };

  const handleFileDownload = async () => {
    const pendingDownload = pendingDownloads.find(
      ([thisFileId, _blob]) => thisFileId === fileId
    );
    if (isDownloading || pendingDownload) {
      return;
    }
    setIsDownloading(true);
    setActiveDownloads([...activeDownloads, fileId]);
    setDownloadProgress(-1);

    axios
      .head(downloadUrl, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then(async (response) => {
        const fileSize = parseInt(response.headers["content-length"], 10);

        return axios
          .get(downloadUrl, {
            headers: {
              Authorization: await getAccessToken(),
            },

            responseType: "blob", // Set the response type to 'blob'
            onDownloadProgress: function (progressEvent) {
              const loaded = progressEvent.loaded;
              const total = progressEvent.lengthComputable
                ? progressEvent.total
                : fileSize;

              // Calculate and display download progress based on loaded and total
              const progressPercentage = Math.ceil((loaded / total) * 100);
              setDownloadProgress(progressPercentage);

              if (progressPercentage === 100) {
                setDownloadProgress(0);
              }
            },
          })
          .catch((error) => {
            console.error("Download Failed! Error", error);
            resetDownloadState();
          });
      })
      .then(({ data: blob }) => {
        const downloadUrl = URL.createObjectURL(blob);
        if (!downloadBtnRef.current) {
          hasLoaded.current = false;
          setPendingDownloads([...pendingDownloads, [fileId, blob]]);
          setIsDownloading(false);
          return;
        }

        downloadBtnRef.current.href = downloadUrl;
        downloadBtnRef.current.download = title;

        resetDownloadState();

        downloadBtnRef.current.setAttribute("target", "_blank");
        downloadBtnRef.current.click();
        URL.revokeObjectURL(downloadBtnRef.current.href);
        downloadBtnRef.current.href = "";
        downloadBtnRef.current.download = "";
      })
      .catch((error) => {
        console.error("Download Failed! Error", error);
        resetDownloadState();
      });
  };

  const completePendingDownloads = () => {
    if (pendingDownloads.length === 0 || hasLoaded.current === true) {
      return;
    }

    const pendingDownload = pendingDownloads.find(
      ([thisFileId, _blob]) => thisFileId === fileId
    );
    if (!pendingDownload) {
      return;
    }

    const downloadUrl = URL.createObjectURL(pendingDownload[1]);
    // Only run once
    hasLoaded.current = true;
    setDownloadProgress(-1);
    setIsDownloading(true);
    downloadByUrl(downloadUrl);

    setTimeout(() => {
      resetDownloadState();
      setPendingDownloads(
        pendingDownloads.filter(([thisFileId]) => thisFileId !== fileId)
      );
    }, 1000);
  };

  const loadUnfinishedDownloadProgress = () => {
    const activeDownload = activeDownloads.find(
      (thisFileId) => thisFileId === fileId
    );
    if (!activeDownload) return;

    // Indicator for style to change to spinning circle, as we can't get progress
    setIsDownloading(true);
    setDownloadProgress(-1);
  };

  useEffect(loadUnfinishedDownloadProgress, [activeDownloads]);
  useEffect(completePendingDownloads, [pendingDownloads, isDownloading]);

  return (
    <StyledDownloadBtn
      {...props}
      $isDownloading={isDownloading}
      $downloadProgress={downloadProgress}
      ref={downloadBtnRef}
      onClick={handleFileDownload}
    >
      <div className="progress-bar" />
      <div className="hover-flag">Download</div>
      <img src="/images/download-icon.png" rel="noopener" target="_blank" />
    </StyledDownloadBtn>
  );
};

export default DownloadBtn;

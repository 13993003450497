import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "../js/features/session/userSession";

import { AccountContext } from "components/contexts/AccountContext";

const Logout = (props) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  const handleLogout = () => {
    logoutUser(navigate);
    setLoggedIn(false);
  };

  handleLogout();

  return (
    <section
      style={{
        height: "1000px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "3rem",
        color: "white",
      }}
    >
      Logging out..
    </section>
  );
};

export default Logout;

import styled from "styled-components";

export const StyledUploadContainer = styled.div`
  max-width: var(--height);
  height: 100%;
  border-radius: 10px;

  padding: ${(props) => (props.$padSize === "large" ? "80px" : "50px")};

  background-color: var(--main-secondary);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 30px;

  h2 {
    margin: 0;
  }

  & > div {
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    flex-direction: inherit;

    gap: 10px;

    & > img {
      height: 200px;
    }

    & > p {
      color: white;
    }
  }
`;

// TODO: Change this to a general component? It's just centered area
export const StyledUploadSystem = styled.div`
  --height: 600px;

  position: relative;
  /* height: var(--height); */
  width: 100%;
  border-radius: 10px 10px 0 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledUploadOptions = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
`;

export const StyledOtherOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  gap: 20px;
  min-width: 250px;

  & > h2 {
    margin: 0;
  }
`;

export const StyledBottomNote = styled.div`
  position: relative;
  padding: 30px 10px;
  width: 100%;
  background-color: var(--main-background);
  border-radius: 0 0 10px 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p {
    color: white;
    margin: 0;
  }
`;

import React, { useState } from "react";

import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import ManyTab from "_components/atoms/navigation/ManyTab";

import BoostRequests from "_components/features/admin/review/BoostRequests";
import TimeOffRequests from "_components/features/admin/review/TimeOffRequests";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

const panels = ["Boosts", "Time Off"];

const Review = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
        <h1>Admin Panel</h1>
        <p style={{ color: "var(--action-primary)" }}>Review</p>
      </TopHeadingBanner>

      <ManyTab
        panels={panels}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <StyledStandardSpacer $paddingTop="50px" />
      {currentTab === 0 && <BoostRequests />}
      {currentTab === 1 && <TimeOffRequests />}
    </StyledStandardEndSection>
  );
};

export default Review;

import React from "react";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";

import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";
import ProfileItem from "../ProfileItem";

const AffiliateProfileItems = () => {
  const navigate = useNavigate();

  return (
    <>
      <ProfileItem
        title="Total Percentage Ownership"
        value={ReactSession.get("userownership") + "%"}
      >
        <StyledInfoHover
          $text="Increase your percentage ownership on our games"
          onClick={() => navigate("/game/booster")}
        />
      </ProfileItem>
    </>
  );
};

export default AffiliateProfileItems;

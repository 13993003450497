import React, { useRef, useContext } from "react";
import axios from "axios";
import { ReactSession } from "react-client-session";

import { ENDPOINT } from "js/data/constants";

import { UploadContext } from "components/contexts/UploadContext";
import getAccessToken from "js/features/auth/getAccessToken";
import { StyledThinBtn } from "_components/atoms/buttons/styles/ThinButtons.styled";

const UploadBtn = ({ directory, acceptTypes, setCanUpload, method }) => {
  const [[progressUris, setProgressUris], [fileDatas, setFileDatas]] =
    useContext(UploadContext);
  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const userId = ReactSession.get("userid");
    if (!userId) {
      console.warn("no userid");
      return;
    }

    const file = e.target.files[0]; // Assuming a single file is selected
    if (!file) {
      console.warn("no file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    // Assign file name, as default file name if not custom name is given in directory
    if (!directory.split("/")[3]) {
      directory = directory.split("/");
      directory.push(file.name);
      directory = directory.join("/");
    }

    // Debounce for controlled uploading
    setCanUpload && setCanUpload(false);

    axios
      .post(
        `${ENDPOINT}/api/drive/upload/${directory}?type=${file.type}&method=${method}`,
        formData,
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then((res) => {
        const uri = res.data.uri;
        setProgressUris([...progressUris, uri]);
        setFileDatas({ ...fileDatas, [uri]: file });
      });

    e.target.value = null;
  };

  return (
    <>
      <StyledThinBtn
        // Makes sure to not 'submit' when in a form
        type="button"
        onClick={() => inputRef.current && inputRef.current.click()}
        style={{ backgroundColor: "var(--action-primary)" }}
      >
        Upload
      </StyledThinBtn>
      <input
        ref={inputRef}
        type="file"
        accept={acceptTypes}
        name="file"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadBtn;

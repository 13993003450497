import styled, { css } from "styled-components";

import {
  DashedUnderlineStyle,
  DashedUnderlineVerticleStyle,
} from "_components/atoms/inline/styles/Decorations.styled";

// Use to match other elements with the indent of the MilestoneTracker
export const firstColumnIndent = "min(300px, 20%)";
const defaultColumnIndent = "180px";
const defaultRowGap = "100px";

function isActiveColor(props) {
  return props.$highlighted == undefined
    ? "var(--main-primary)"
    : props.$highlighted === true
    ? "var(--main-primary)"
    : "var(--sub-info-alt)";
}

export const StyledMilestoneTracker = styled.section`
  --col-back: ${(props) => props.$colBack || "var(--main-secondary)"};

  display: block;
  position: relative;
  width: 100%;
  overflow-x: auto;
`;

export const StyledLineConnector = styled.div`
  display: block;
  position: relative;
  width: ${defaultColumnIndent};

  // Pushes next milestone away down like a milestone would
  margin-bottom: ${defaultRowGap};

  &::before {
    ${DashedUnderlineStyle}

    --invis-col: ${(props) => props.$colBack || "var(--main-secondary)"};
    --main-col: ${(props) =>
      props.$lineHighlighted ? "var(--main-primary)" : "var(--sub-info-alt)"};

    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const StyledLineContainer = styled.div`
  /* Space between last line container and this next one on the x-axis */
  --column-indent: ${(props) =>
    props.$first ? firstColumnIndent : defaultColumnIndent};

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-left: var(--column-indent);
`;

export const StyledMilestoneElement = styled.div`
  // Space left at top and bottom before and after first and last milestones
  --ends-margin: 130px;
  // Space between this element and what's below it
  --gap: ${(props) =>
    props.$gap === "connect" // Preset for elements right above a 'LineConnector'
      ? "50px"
      : props.$gap != undefined
      ? props.$gap
      : defaultRowGap};

  --circle-diameter: 1.5rem;
  // Helps center circle exactly as there is small mis-margin without it
  --center-circle-offset: 3px;

  // Can provide just text with no <p> children, and this will color it properly
  color: ${isActiveColor};
  font-weight: ${(props) => props.$highlighted && "bold"};

  /*
    Allows line to climb all the way to the parent's height, instead of just this element's height
    Note: Put it only on the first/one Milestone of container. Otherwise they'll overlap.
  */
  position: ${(props) => (props.$line === "full" ? "static" : "relative")};
  display: flex;
  align-items: start;
  justify-content: start;
  width: 400px; // TODO: make this scale with the text

  opacity: ${(props) =>
    props.$active == undefined ? 1 : props.$active === true ? 1 : 0.5};

  // Margin from top to allow connectors to choose how close to be the last milestone
  padding-top: ${(props) => props.$first && "var(--ends-margin)"};
  padding-bottom: ${(props) =>
    props.$last
      ? props.$last == "cut" // Stops line suddenly at the milestone's point
        ? "0"
        : "var(--ends-margin)"
      : "var(--gap)"};

  ${(props) =>
    props.$line &&
    css`
      &::before {
        ${DashedUnderlineVerticleStyle}

        --invis-col: var(--col-back);
        /* --main-col: ${(props) => props.$col || "var(--sub-info-alt)"}; */
        --main-col: ${(props) =>
          props.$lineHighlighted
            ? "var(--main-primary)"
            : "var(--sub-info-alt)"};

        top: 0;
        left: 0;
        // Offsets line length from bottom (useful on $full when branches take line too far down)
        height: calc(100% - ${(props) => props.$offset});
      }
    `}

  .circle {
    // Makes appears above line
    z-index: 1;

    display: block;
    height: var(--circle-diameter);
    width: var(--circle-diameter);

    background-color: ${isActiveColor};
    border-radius: 50%;

    transform: translateX(
      calc(var(--circle-diameter) / -2 + var(--center-circle-offset))
    );
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    & > p {
      margin: 0;
      line-height: 1.3rem;
      color: ${isActiveColor};
      font-weight: ${(props) => props.$highlighted && "bold"};
    }

    & > p:not(:first-child) {
      opacity: 0.6;
    }
  }
`;

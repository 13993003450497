import React from "react";

import { StyledImgCover } from "_components/molecules/structure/styles/BlendImg.styled";

export const WaveBackgroundTop = ({ children }) => {
  return (
    <div
      style={{
        display: "block",
        position: "relative",
      }}
    >
      <StyledImgCover
        style={{
          backgroundColor: "#11111A",
          rotate: "180deg",
          height: "700px",
          position: "relative",
          minWidth: "900px",
        }}
        src="/images/wave-haikei-bottom.png"
      />
      {children}
    </div>
  );
};

export const WaveBackgroundBottom = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <StyledImgCover
        style={{
          backgroundColor: "#11111A",
          rotate: "180deg",
          height: "700px",
          position: "relative",
          minWidth: "900px",
        }}
        src="/images/wave-haikei-top.png"
      />
      {children}
    </div>
  );
};

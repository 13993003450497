import React, { useState, useEffect } from "react";
import axios from "axios";

import { CenteredHeader } from "components/styles/common/Headings.styled";
import { StyledFlexbox } from "components/styles/SideBySide.styled";

import { StyledSmushedTabContainer } from "components/styles/objects/common/TabElements.styled";
import { Tab } from "components/objects/common/Tab";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import SplitPage from "_components/organisms/SplitPage";
import UserSelection from "_components/organisms/UserSelection";
import { StyledThinBtn } from "_components/atoms/buttons/styles/ThinButtons.styled";

import reviewCard from "_components/function/modifiers/reviewCard";
import LoadEmailJs from "_components/function/handlers/LoadEmailJs";

import { ENDPOINT } from "js/data/constants";

import getAccessToken from "js/features/auth/getAccessToken";

// Maps the possible status values of columns in db which could be on request item to the current tab by index of the array
const statusMap = [
  ["Accepted", 1],
  ["Declined", -1],
];

const UserSelectBtn = ({ selectedUser, setSelectedUser }) => {
  return (
    <StyledFlexbox>
      <StyledThinBtn
        // Makes sure to not 'submit' when in a form
        type="button"
        // Gets picked up as 'selection active'
        onClick={() => setSelectedUser("")}
        style={{ backgroundColor: "var(--action-primary)" }}
      >
        {selectedUser ? "Change User" : "Select User"}
      </StyledThinBtn>
      {selectedUser && <p>Current: {selectedUser}</p>}
    </StyledFlexbox>
  );
};

const FilterTabs = ({ currentTab, setCurrentTab }) => {
  return (
    <StyledSmushedTabContainer>
      <Tab $index={0} $currentTab={currentTab} setCurrentTab={setCurrentTab}>
        Accepted
      </Tab>
      <Tab $index={1} $currentTab={currentTab} setCurrentTab={setCurrentTab}>
        Declined
      </Tab>
    </StyledSmushedTabContainer>
  );
};

function reviewedRequestsTabs(
  selectedUser,
  setSelectedUser,
  currentTab,
  setCurrentTab
) {
  const ReviewedRequestsTabs = () => {
    return (
      <StyledFlexbox
        $row
        $justify="space-between"
        style={{ padding: "0 50px" }}
      >
        <UserSelectBtn
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
        <FilterTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </StyledFlexbox>
    );
  };
  return ReviewedRequestsTabs;
}

const Reviewer = ({
  itemAlias: alias,
  pendingUri,
  searchDatabase: database,
  searchColumn: column,
  searchValues: values,
  onConfirm,
}) => {
  const [emailJs, setEmailJs] = useState(null);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [reviewedRequests, setReviewedRequests] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);

  const PendingReviewCard = reviewCard(
    pendingRequests,
    setPendingRequests,
    emailJs,
    onConfirm
  );

  const ReviewedReviewCard = reviewCard(
    reviewedRequests,
    setReviewedRequests,
    emailJs,
    onConfirm
  );

  const ReviewedRequestsTabs = reviewedRequestsTabs(
    selectedUser,
    setSelectedUser,
    currentTab,
    setCurrentTab
  );

  // If value is either an index or value in the status map, then it is valid to tab index
  function filterByTab(requests) {
    return requests.filter((request) => {
      const currentStatus = statusMap[currentTab];
      const value = request[column];
      const matchValue =
        currentStatus.find((status) => status === value) != undefined;
      return matchValue;
    });
  }

  async function loadPendingRequests() {
    axios
      .get(pendingUri, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => setPendingRequests(res.data))
      .catch(console.error);
  }

  // Uses 'search' method to find all 'reviewed requests' of a particular user
  async function loadReviewedRequests() {
    async function getWhiteLists() {
      try {
        const response = await axios.get(`${ENDPOINT}/api/user/data`, {
          params: {
            uid: selectedUser,
          },
          headers: {
            Authorization: await getAccessToken(),
          },
        });

        const userId = response.data?.UserId;
        if (userId == undefined) return;

        const onlyUserWhitelist = {
          column: "UserId",
          values: [userId],
        };
        const stateWhitelist = {
          column: column,
          values: values,
        };
        const whitelists = [onlyUserWhitelist, stateWhitelist];

        return whitelists;
      } catch (error) {
        console.error(error);
      }
    }

    const whitelists = await getWhiteLists();

    axios
      .get(`${ENDPOINT}/api/user/data`, {
        params: {
          method: "search",
          db: database,
          // Also doesn't matter, we just need a column, so use the given one we know exists in db
          column: column,
          // This doesn't matter, we just want all results to then filter by 'list'
          value: "",
          // Only allows for one user's requests
          whitelists: whitelists && JSON.stringify(whitelists),
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => setReviewedRequests(res.data))
      .catch(console.error);
  }

  useEffect(() => {
    loadPendingRequests();
  }, []);

  useEffect(() => {
    if (selectedUser == "" || selectedUser == undefined) return;
    loadReviewedRequests();
  }, [selectedUser, pendingRequests]);

  return (
    <StyledDualXStrip $only="pull">
      <LoadEmailJs setEmailJs={setEmailJs} />

      <CenteredHeader title="Reviewer">
        Accept, decline and compare requests from users
      </CenteredHeader>

      {selectedUser === "" ? (
        <UserSelection setSelectedUser={setSelectedUser} />
      ) : (
        <SplitPage
          alias={alias}
          idKey="RequestId"
          data={[
            {
              heading: "Pending Requests",
              items: pendingRequests,
              Item: PendingReviewCard,
            },
            {
              heading: "Reviewed Requests",
              items: filterByTab(reviewedRequests),
              children: <ReviewedRequestsTabs />,
              Item: ReviewedReviewCard,
            },
          ]}
        />
      )}
    </StyledDualXStrip>
  );
};

export default Reviewer;

import React from "react";
import { styled, keyframes } from "styled-components";

import { StyledAspectRatioContainer } from "../../styles/AspectRatioContainer.styled";

const scrollAnim = keyframes`
  0%, 2% {
    transform: translateX(0);
  }

  98%, 100% {
    transform: translateX(-50%);
  }
`;

const StyledTram = styled.div`
  gap: 1.5%;
  padding: 3% 10%;

  height: 100%;
  width: auto;
  background-color: #0d0d14;

  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: ${scrollAnim};
`;

export const StyledTramImg = styled.img`
  background-color: #0d0d14;

  height: 100%;
`;

const Tram = ({ children }) => {
  return (
    <StyledAspectRatioContainer $aspectRatio={"2.5:1"}>
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#0d0d14",
        }}
      >
        <StyledTram>{children}</StyledTram>
      </div>
    </StyledAspectRatioContainer>
  );
};

export default Tram;

import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { CtaElement, CtaStyle } from "../../../components/CtaElements";

import { NavExpand } from "../../../components/objects/navigation/NavbarElements";

import { AccountContext } from "../../../components/contexts/AccountContext";

export const NavProfileCta = styled(CtaElement)`
  ${CtaStyle};
  --height: 45px;
  --width: 120px;

  margin: 0 30px;

  .box,
  .box::before {
    animation: unset;
  }
`;

const ProfileExpand = styled(NavExpand)`
  height: 100%;
  width: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      height: 45%;
    }
  }
`;

const NavProfileIcon = ({ ...props }) => {
  return (
    <ProfileExpand {...props}>
      <div className="icon">
        <img src="/images/profile-icon.png" />
      </div>
    </ProfileExpand>
  );
};

export const NavProfile = ({ ...props }) => {
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  const location = useLocation();
  const [path, setPath] = useState(null);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  if (loggedIn) {
    return <NavProfileIcon {...props} />;
    // Display login option when already in join page
  } else if (path == "/login") {
    return <NavProfileCta to="/join">Join</NavProfileCta>;
  } else {
    return <NavProfileCta to="/login">Login</NavProfileCta>;
  }
};

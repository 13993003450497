import { styled, css } from "styled-components";

const ActiveLabelPositioningStyle = css`
  font-size: 1rem;
  transform: translateY(calc(-100% - 10px));
  padding-left: 0;
`;

const InputStyle = css`
  background-color: #2d2d46;
  border-radius: 10px;
  border-style: none;

  color: #e7e7f8;
  font-size: var(--input-text-size);

  height: 100%;
  width: 100%;
  position: relative;
  bottom: 0;

  padding: 10px;

  &::placeholder {
    color: #4d4d64;
  }
`;

export const StyledFormItem = styled.div`
  --input-text-size: 0.95rem;

  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  grid-column: 1 / -1;

  justify-content: start;
  height: ${(props) => (props.$large ? "200px" : "40px")};

  & > label {
    color: #8989b1;
  }

  &::before {
    display: flex;
    align-items: center;
    justify-content: start;

    pointer-events: none;
    user-select: none;

    color: #8989b1;
    font-size: var(--input-text-size);
    padding-left: 10px;

    position: absolute;
    z-index: 1;

    transition: all 0.1s ease-in-out;

    content: ${({ $label }) => `"${$label}"`};
    transform: translateY(50%);

    ${({ $focus, $valid }) => {
      if ($focus) {
        return css`
          ${ActiveLabelPositioningStyle};
          color: #00b1ff;
        `;
      } else if ($valid) {
        return css`
          ${ActiveLabelPositioningStyle};
        `;
      }
    }};
  }
`;

export const StyledFormInput = styled.input`
  ${InputStyle}
`;

export const StyledFormTextArea = styled.textarea`
  ${InputStyle}

  resize: none;
`;

export const StyledInputItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => props.$column && "column"};
  gap: ${(props) => !props.$column && "30px"};
  padding-bottom: ${(props) => props.$column && "20px"};
  width: ${(props) => props.$width || "100%"};

  & > input {
    background-color: #2d2d46;
    border-radius: 5px;
    border-style: none;
    text-align: center;

    color: #e7e7f8;
    font-size: var(--input-text-size);

    height: 100%;
    width: 70px;
    position: relative;
    bottom: 0;

    padding: 5px;

    &::placeholder {
      color: #4d4d64;
    }
  }
`;

export const StyledFormOption = styled.label`
  --radius: 10px;

  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;

  text-align: right;
  justify-self: end;

  color: #8989b1;

  & > input {
    position: relative;
    cursor: pointer;
    appearance: none;
    background-color: #2d2d46;
    border-radius: var(--radius);
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      border-radius: var(--radius);
      position: absolute;
      height: 70%;
      width: 70%;
      background-color: #0184ff;

      transition: all 0.2s ease-in-out;
      opacity: 0;
    }

    &:checked::before {
      opacity: 1;
    }
  }
`;

export const StyledFormSelect = styled.select`
  color: var(--action-primary);
  text-align: center;

  padding: 5px;
  border: 1px solid var(--action-primary);
  border-radius: 10px;
  background-color: var(--main-secondary);
`;

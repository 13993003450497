import React, { useState } from "react";
import axios from "axios";

import ConfirmSelectScreen from "_components/molecules/containers/overlays/ConfirmSelectScreen";
import EnterValueScreen from "_components/molecules/containers/overlays/EnterValueScreen";
import InputItem from "_components/features/form/InputItem";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const confirmSelectScreenInfo = {
  confirmed: {
    title: "Setting Successfully Changed!",
    description: <p>You setting was successfully changed to your new value!</p>,
  },
  prompt: {
    title: "Confirm Setting Change",
    description: <p>Are you sure you want to change this setting?</p>,
  },
};

const SettingChangeScreen = ({
  type = "text",
  min = 1,
  max = 16,
  dbName,
  columnName,
  placeholder,
  newValue,
  setNewValue,
  setIsConnected,
  isChangingSetting,
  setIsChangingSetting,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const resetState = () => {
    setIsChangingSetting(false);
    setIsConfirmed(false);
  };

  const changeSetting = async () => {
    axios
      .post(
        `${ENDPOINT}/api/user/data?method=id&db=${dbName}`,
        {
          values: `${columnName} = '${newValue}'`,
          columnName,
          newValue,
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then(() => setIsConnected && setIsConnected(newValue != ""))
      .catch(console.error);
  };

  if (dbName == undefined || columnName == undefined) return <></>;

  return (
    <>
      {isChangingSetting && !isConfirmed && (
        <EnterValueScreen
          info={{}}
          setConfirmed={setIsConfirmed}
          setIsActive={resetState}
        >
          <InputItem
            $label="New Value"
            placeholder={placeholder}
            type={type}
            name="setting"
            min={min}
            max={max}
            input={newValue}
            setInput={setNewValue}
            style={{ width: "200px" }}
          />
        </EnterValueScreen>
      )}

      {isChangingSetting && isConfirmed && (
        <ConfirmSelectScreen
          setIsActive={resetState}
          info={confirmSelectScreenInfo}
          onConfirmed={changeSetting}
        ></ConfirmSelectScreen>
      )}
    </>
  );
};

export default SettingChangeScreen;

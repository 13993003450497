import React, { useState } from "react";
import styled from "styled-components";

import {
  StyledScrollContainer,
  StyledContentDisplay,
} from "components/styles/objects/containers/ScrollContainer.styled";

import {
  StyledSmushedTabContainer,
  StyledTabToolbar,
} from "components/styles/objects/common/TabElements.styled";
import { Tab } from "components/objects/common/Tab";

export const StyledTabbedScroller = styled.div`
  --height: 600px;

  position: relative;
  height: ${(props) => props.$height || "var(--height)"};
  width: 100%;
  background-color: var(--main-background);
  border-radius: 10px;

  padding: 50px;
`;

const TabbedScroller = ({ children, $tab1, $tab2 }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <StyledTabbedScroller>
      <StyledTabToolbar>
        <StyledSmushedTabContainer>
          <Tab
            $index={0}
            $currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          >
            {$tab1}
          </Tab>
          <Tab
            $index={1}
            $currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          >
            {$tab2}
          </Tab>
        </StyledSmushedTabContainer>
      </StyledTabToolbar>

      <StyledScrollContainer>
        <StyledContentDisplay $align="center">
          {children[currentTab]}
        </StyledContentDisplay>
      </StyledScrollContainer>
    </StyledTabbedScroller>
  );
};

export default TabbedScroller;

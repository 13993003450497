import React, { useState, useRef, useEffect } from "react";
import { styled, css, keyframes } from "styled-components";

const itterateDuration = 3000;
const itterationAnimationDuration = itterateDuration / 1000 + "s";

const imgRadius = "18%";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const antiRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
`;

const StyledRotCircle = styled.div`
  --rot-duration: 45s;
  --img-radius: ${imgRadius};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 450px;
  width: 450px;

  .circle {
    height: 100%;
    width: 100%;
    border: 5px solid var(--action-secondary);
    border-radius: 50%;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: ${rotate} var(--rot-duration) linear infinite;

    animation-play-state: ${({ $isActive }) => {
      switch ($isActive) {
        case true:
          return "running";
        case false:
          return "paused";
      }
    }};
  }

  .circle img {
    display: block;
    position: absolute;
    height: ${imgRadius};
    width: ${imgRadius};
    border-radius: 50%;
    background-image: linear-gradient(
      to bottom right,
      var(--action-secondary),
      var(--main-primary-alt)
    );

    padding: 3%;

    transition: box-shadow 0.25s linear;

    animation: ${antiRotate} var(--rot-duration) linear infinite;
    animation-play-state: ${({ $isActive }) => {
      switch ($isActive) {
        case true:
          return "running";
        case false:
          return "paused";
      }
    }};
  }

  ${(props) => {
    switch (props.$numIcons) {
      case 4:
        return css`
          .circle img:nth-child(1) {
            // Replacement for translate(0, -50%), which conflicts with animation
            top: calc(${imgRadius} / -2);
            transform: translate(10px, 10px);
          }

          .circle img:nth-child(2) {
            right: calc(${imgRadius} / -2);
          }

          .circle img:nth-child(3) {
            bottom: calc(${imgRadius} / -2);
          }

          .circle img:nth-child(4) {
            left: calc(${imgRadius} / -2);
          }
        `;
      case 6:
        return css`
          .circle img:nth-child(1) {
            top: calc(${imgRadius} / -2);
          }

          .circle img:nth-child(2) {
            top: calc(${imgRadius} / 2 * 0.866);
            right: calc(${imgRadius} / 2 * 0.5);
          }

          .circle img:nth-child(3) {
            bottom: calc(${imgRadius} / 2 * 0.866);
            right: calc(${imgRadius} / 2 * 0.5);
          }

          .circle img:nth-child(4) {
            bottom: calc(${imgRadius} / -2);
          }

          .circle img:nth-child(5) {
            bottom: calc(${imgRadius} / 2 * 0.866);
            left: calc(${imgRadius} / 2 * 0.5);
          }

          .circle img:nth-child(6) {
            top: calc(${imgRadius} / 2 * 0.866);
            left: calc(${imgRadius} / 2 * 0.5);
          }
        `;
    }
  }}
`;

const StyledInfoCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  position: absolute;
  height: 60%;
  width: 60%;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom right,
    var(--action-secondary),
    var(--main-primary-alt)
  );

  & > img {
    height: 30%;

    ${(props) => {
      switch (props.mode) {
        case "fadeIn":
          return css`
            animation: ${itterationAnimationDuration} ease-out ${fadeIn}
              infinite;
          `;
        default:
          return css`
            animation: none;
          `;
      }
    }};
  }

  & > p {
    width: 75%;
    text-align: center;
    color: white;
    font-size: 1.25rem;
    line-height: 1.4rem;
    margin: 0;

    ${(props) => {
      switch (props.mode) {
        case "fadeIn":
          return css`
            animation: ${itterationAnimationDuration} ease-out ${fadeIn}
              infinite;
          `;
        default:
          return css`
            animation: none;
          `;
      }
    }};
  }

  @media screen and (max-width: 500px) {
    & > p {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    & > p {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

const HoverableIcon = ({
  setIsIconHovered,
  activeIconIndex,
  setActiveIconIndex,
  index,
  data,
}) => {
  const isActive = activeIconIndex === index;
  return (
    <img
      onMouseOver={() => {
        setIsIconHovered(true);
        setActiveIconIndex(index);
      }}
      onMouseOut={() => {
        setIsIconHovered(false);
      }}
      src={data[0]}
      style={{
        boxShadow: isActive
          ? "0 0 9px 3px var(--action-secondary)"
          : "0 0 0 0 var(--action-secondary)",
      }}
    />
  );
};

const RotCircle = ({ infoList, ...props }) => {
  const numIcons = infoList.length;

  // Allows correct icon to be highlighted and display it's info
  const [activeIconIndex, setActiveIconIndex] = useState(0);
  // Determines whether the auto traversal is active or not based on take over from human
  const [isIconHovered, setIsIconHovered] = useState(false);

  // Track so we can activate it's animations in and out of transitions
  const [infoCenterMode, setInfoCenterMode] = useState("none");

  // Log this separate, to continue from last 'checkpoint' after done hovering
  const intervalIndexRef = useRef(0);
  // Log so we can cancel auto traversal of icons when hovering
  const intervalRef = useRef(null);

  const itterateIcons = (increment) => {
    increment = increment == null ? 1 : increment;
    const nextIndex = (intervalIndexRef.current + increment) % numIcons;
    intervalIndexRef.current = nextIndex;
    setActiveIconIndex(nextIndex);
  };

  useEffect(() => {
    if (isIconHovered === true) {
      if (intervalRef.current) {
        setInfoCenterMode("none");
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } else {
      if (intervalRef.current == null) {
        setInfoCenterMode("fadeIn");
        itterateIcons(0);
        const id = setInterval(itterateIcons, itterateDuration);
        intervalRef.current = id;
      }
    }
  }, [isIconHovered]);

  return (
    <StyledRotCircle {...props} $numIcons={numIcons} $isActive={!isIconHovered}>
      <StyledInfoCenter mode={infoCenterMode}>
        <img src={infoList[activeIconIndex][0]} />
        <p>{infoList[activeIconIndex][1]}</p>
      </StyledInfoCenter>
      <div className="circle">
        {infoList.map((data, index) => (
          <HoverableIcon
            key={index}
            setIsIconHovered={setIsIconHovered}
            activeIconIndex={activeIconIndex}
            setActiveIconIndex={setActiveIconIndex}
            index={index}
            data={data}
          />
        ))}
      </div>
    </StyledRotCircle>
  );
};

export default RotCircle;

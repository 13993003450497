import React, { useState } from "react";

import { StyledSbs } from "components/styles/SideBySide.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledSpacedTabsContainer } from "components/styles/objects/common/TabElements.styled";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledSectionH2 } from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import { Tab } from "components/objects/common/Tab";

import YourWork from "../YourWork";
import AvailableWork from "../AvailableWork";

const Workload = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <StyledDualXStrip>
      <StyledCenteredArea>
        <StyledSectionH2>Workload</StyledSectionH2>
      </StyledCenteredArea>

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledSbs
        $gap="20px"
        $align="center"
        $justify="start"
        $centered="min(1200px, 90%)"
        $shadowed
        $column
      >
        <StyledSpacedTabsContainer>
          <Tab
            $index={0}
            $currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          >
            Your Work
          </Tab>
          {/* <Tab
            $index={1}
            $currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          >
            Available Work
          </Tab> */}
        </StyledSpacedTabsContainer>

        {currentTab === 0 && <YourWork />}
        {/* //* Disabled for launch */}
        {/* {currentTab === 1 && <AvailableWork />} */}
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default Workload;

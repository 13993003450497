import React from "react";

import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";
import {
  StyledDocSection,
  IndentedP,
} from "_components/features/docs/main/Documentation.styled";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const OwnershipBoostingDocs = () => {
  return (
    <>
      <StyledDocSection>
        <p className="title">Overview</p>
        <p>
          You can <i>boost</i> your percentage ownership of each game you have
          selected to be involved in by using the{" "}
          <LinkSpan to="/game/booster">Ownership Booster</LinkSpan>.
        </p>
      </StyledDocSection>
      <StyledDocSection>
        <p className="title">Rules</p>
        <IndentedP>
          If you submit the same content again but with just a different URL or
          same URL as previously submitted, you will permanently lose all access
          to all game involvement and benefits as well as the ability to use the{" "}
          <b>booster</b>
        </IndentedP>
        <IndentedP>
          <h3 className="section-header">Per Advertising Method</h3>

          <div>
            <p className="sub-header">Gameplay footage</p>
            <p>Must be at least 8 minutes</p>
            <p>
              Gameplay footage must be a completely new video recorded
              separately from other session
            </p>
          </div>

          <div>
            <p className="sub-header">Video Plugs</p>
            <p>Only apply once per video</p>
          </div>

          <div>
            <p className="sub-header">Posts</p>
            <p>Are only be valid once per day</p>
          </div>

          <div>
            <p className="sub-header">Description/Comment Plugs</p>
            <p>Are valid 3 videos per day</p>
          </div>
        </IndentedP>
      </StyledDocSection>

      <StyledDocSection>
        <p className="title">Guidelines</p>
        <IndentedP>
          All content submitted must be submitted within the same 48 hours of
          the content/URL being published. Any content submitted after 48 hours
          of the content/URL being published will not be accepted and the
          request will be declined.
          <br />
          <StyledHighlightedSpan $sty="italic" $col="var(--sub-info-alt)">
            Example: If you published an advertising social media post on 1st,
            and you submitted the URL on 4th, the request will be rejected.
          </StyledHighlightedSpan>
        </IndentedP>

        {/* <IndentedP>
          If you choose to submit the content with the{" "}
          <StyledHighlightedSpan $col="var(--contrast-important)">
            method of advertising
          </StyledHighlightedSpan>{" "}
          as '
          <StyledHighlightedSpan $col="var(--contrast-important)">
            other
          </StyledHighlightedSpan>
          ', it is possible that the request will be rejected if the{" "}
          <StyledHighlightedSpan $col="var(--contrast-important)">
            other method of advertising
          </StyledHighlightedSpan>{" "}
          named does not seem like an optimal site/place to advertise our brand
          or content. Due to either lack of audience reach of site/place or
          other reasons.
        </IndentedP> */}

        <IndentedP>
          <p>You can only make 3 boost requests every 3 hours</p>
        </IndentedP>
      </StyledDocSection>
    </>
  );
};

export default OwnershipBoostingDocs;

import styled from "styled-components";

export const StyledBookmarkDisplay = styled.div`
  --padding-top: 5px;

  background-color: var(--main-background);
  border-radius: 10px 10px 0 0;

  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  position: absolute;
  top: 10px;
  z-index: 0;
  left: 0;
  transform: translateY(-100%);

  padding: var(--padding-top) 10px calc(var(--padding-top) + 10px) 10px;
  min-height: 2rem;
  min-width: 100px;

  @media only screen and (max-width: 1000px) {
    flex-direction: row;
    gap: 40px;
    transform: translateY(-40%);
    right: 0;
  }
`;

export const StyledBookmarkElement = styled.p`
  position: relative;
  margin: 0;
  color: white;
  line-height: 30px;
  margin-left: ${(props) => props.$hasInfoHover && "25px"};
`;

import React from "react";

import { StyledSidebarTab } from "_components/molecules/displays/styles/Sidebar.styled";

const PartnerTabs = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <StyledSidebarTab
        $isActive={activeTab == "payment"}
        onClick={() => setActiveTab("payment")}
      >
        Payment
      </StyledSidebarTab>
    </>
  );
};

export default PartnerTabs;

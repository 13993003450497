import React from "react";
import styled from "styled-components";

const StyledUserWithIcon = styled.div`
  --size: 1.4rem;

  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;

  & > img {
    height: 140%;
  }

  & > h3 {
    font-size: var(--size);
    color: var(--action-primary);
    margin: 0;
  }
`;

const UserWithIcon = ({ username, src = "/images/profile-icon.png" }) => {
  return (
    <StyledUserWithIcon>
      <img src={src} />
      <h3>{username}</h3>
    </StyledUserWithIcon>
  );
};

export default UserWithIcon;

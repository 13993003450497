/*
  Allows adding of pre-defined children like buttons to a component if you don't have
  it already included in the base item's design.

  It allows adding children from one place to a component created in another place
  and isn't just like adding children normally. It has it's own use cases when
  the component isn't as available or hasn't had pre-defined children already.

  Usage: Provide a fragment containing initialized children e.g. <><button></>
*/
export function withChildrenComponent(Component, children) {
  const WithChildrenComponent = (props) => {
    return <Component {...props}>{children}</Component>;
  };

  const componentName = Component.displayName || Component.name || "Component";
  WithChildrenComponent.displayName = `withChildren(${componentName})`;
  return WithChildrenComponent;
}
